import * as THREE from "three";

function findClosestPointOnPath(
  waypoint: THREE.Vector3,
  path: THREE.Vector3[]
): THREE.Vector3 {
  if (path.length < 2) {
    throw new Error("Path must have at least two points.");
  }

  const closestPoint = new THREE.Vector3();
  let minDistance = Infinity;

  for (let i = 0; i < path.length - 1; i++) {
    const start = path[i];
    const end = path[i + 1];

    const pointOnSegment = closestPointOnLineSegment(waypoint, start, end);

    const distance = waypoint.distanceTo(pointOnSegment);

    if (distance < minDistance) {
      minDistance = distance;
      closestPoint.copy(pointOnSegment);
    }
  }

  return closestPoint;
}

function closestPointOnLineSegment(
  point: THREE.Vector3,
  start: THREE.Vector3,
  end: THREE.Vector3
): THREE.Vector3 {
  const startToEnd = new THREE.Vector3().subVectors(end, start);
  const startToPoint = new THREE.Vector3().subVectors(point, start);

  const t = THREE.MathUtils.clamp(
    startToPoint.dot(startToEnd) / startToEnd.lengthSq(),
    0,
    1
  );

  return new THREE.Vector3().addVectors(start, startToEnd.multiplyScalar(t));
}

export { findClosestPointOnPath };