import * as THREE from "three";
import { TPSS, TPSSHuman } from "../../../store/pss/types";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const clock = new THREE.Clock();

// async function createHumans(data: TPSS, scene: THREE.Scene): Promise<THREE.Group> {
// 	const group = new THREE.Group();
// 	group.name = "HUMAN";

// 	console.log("Creating humans from data:", data.humans);
// 	const humanPromises = data.humans
// 		.filter((item) => {
// 			const valid = item.path && item.path.length > 1;
// 			if (!valid) console.warn(`Invalid human path:`, item);
// 			return valid;
// 		}) // Ensure valid paths with more than 1 point
// 		.map((item) => createHuman(item, scene));

// 	const humanModels = await Promise.all(humanPromises);

// 	for (const model of humanModels) {
// 		group.add(model);
// 		console.log(`Added human ${model.name} to group.`);
// 	}
// 	group.userData = { animate };
// 	return group;
// }

async function createHumans(data, scene) {
	// Remove old humans if they exist
	const oldGroup = scene.getObjectByName("HUMAN");
	if (oldGroup) {
		console.log("Removing old human group...");
		scene.remove(oldGroup);
	}

	const group = new THREE.Group();
	group.name = "HUMAN";

	console.log("Creating humans from data:", data.humans);

	const humanPromises = data.humans
		.filter((item) => {
			const hasValidPath = item.path && item.path.length > 1; // Ensure path is valid
			if (!hasValidPath) {
				console.warn(`Skipping human with invalid path:`, item);
			}
			return hasValidPath; // Only include humans with valid paths
		})
		.map((item) => createHuman(item, scene));

	const humanModels = await Promise.all(humanPromises);
	humanModels.forEach((model) => group.add(model));

	console.log("All valid humans created and added to the group.");
	group.userData = { animate }; // Attach animation handler
	return group;
}

// function createHuman(data: TPSSHuman, scene: THREE.Scene): Promise<THREE.Group> {
// 	console.log("Creating human with data:", data);
// 	const { id, speed, path } = data;

// 	if (!path || path.length < 1) {
// 		console.error(`Invalid path for human ${id}:`, path);
// 		return Promise.reject(new Error(`Invalid path for human ${id}`));
// 	}

// 	const { x, y, z } = path[0];

// 	return new Promise((resolve, reject) => {
// 		const loader = new GLTFLoader();
// 		loader.load(
// 			"./models/worker.glb",
// 			(gltf) => {
// 				const human = gltf.scene;
// 				human.scale.set(1, 1, 1);
// 				human.name = `Human${id}`;
// 				human.position.set(x, y, z);

// 				const animations = gltf.animations;
// 				const mixer = new THREE.AnimationMixer(human);

// 				const walkClip = animations.find((clip) => clip.name === "Walk");
// 				const idleClip = animations.find((clip) => clip.name === "Idle");

// 				const walkAction = walkClip ? mixer.clipAction(walkClip) : null;
// 				const idleAction = idleClip ? mixer.clipAction(idleClip) : null;

// 				if (!walkAction || !idleAction) {
// 					console.warn(`Missing animations for human ${id}`);
// 				}

// 				idleAction && idleAction.play();
// 				walkAction && walkAction.setLoop(THREE.LoopRepeat, Infinity);

// 				human.userData = {
// 					mixer,
// 					actions: { walk: walkAction, idle: idleAction },
// 					path: path.length > 1
// 						? path.map((segment, index) => {
// 							const nextSegment = path[index + 1];
// 							if (!nextSegment) return null;
// 							return {
// 								start: { x: segment.x, y: segment.y, z: segment.z },
// 								end: { x: nextSegment.x, y: nextSegment.y, z: nextSegment.z },
// 								waitTime: segment.waitTime || 0,
// 								distance: Math.sqrt(
// 									Math.pow(nextSegment.x - segment.x, 2) +
// 									Math.pow(nextSegment.y - segment.y, 2) +
// 									Math.pow(nextSegment.z - segment.z, 2)
// 								),
// 							};
// 						}).filter(Boolean)
// 						: [],
// 					speed: speed || 0,
// 					currentSegmentIndex: 0,
// 					distanceTraveled: 0,
// 					started: false,
// 					finished: false,
// 					waitTimeRemaining: path[0]?.waitTime || 0,
// 				};

// 				updateHumanPath(human, scene);
// 				setupAnimation(human);

// 				resolve(human);
// 			},
// 			undefined,
// 			(error) => {
// 				console.error(`Failed to load model for human ${id}:`, error);
// 				reject(error);
// 			}
// 		);
// 	});
// }

function createHuman(
	data: TPSSHuman,
	scene: THREE.Scene
): Promise<THREE.Group> {
	console.log("Creating human with data:", data);
	const { id, speed, path, name } = data;

	if (!path || path.length < 1) {
		console.error(`Invalid path for human ${id}:`, path);
		return Promise.reject(new Error(`Invalid path for human ${id}`));
	}

	const { x, y, z } = path[0];

	return new Promise((resolve, reject) => {
		const loader = new GLTFLoader();
		loader.load(
			"./models/female_worker.glb",
			(gltf) => {
				const human = gltf.scene;

				const scaleFactor = 1 / 8;
				human.scale.set(scaleFactor, scaleFactor, scaleFactor);
				human.rotation.y = (270 * Math.PI) / 180;

				human.name = `Human${id}`;
				human.position.set(x, y, z);

				const label = createHumanLabel(`${name??'Operator'}(#${id})`, id);
				label.position.set(0, 25, 0); // Position label slightly above the human model
				human.add(label);

				const animations = gltf.animations;

				if (animations.length > 0) {
					console.log(`Available animations for human ${id}:`);
					animations.forEach((clip, index) => {
						console.log(`Animation ${index + 1}:`, clip.name);
					});
				} else {
					console.warn(`No animations found for human ${id}`);
				}

				const mixer = new THREE.AnimationMixer(human);

				// Use only the "walk" animation
				const walkClip = animations
					.find((clip) => clip.name === "Armature|mixamo.com|Layer0")
					?.trim();
				console.log(walkClip);
				// const idleClip = animations.find((clip) => clip.name === "Armature|mixamo.com|Layer0")?.trim();

				const walkAction = walkClip ? mixer.clipAction(walkClip) : null;

				if (!walkAction) {
					console.warn(
						`Missing walk animation for human ${id}. Human will remain static.`
					);
				} else {
					walkAction.setLoop(THREE.LoopRepeat, Infinity);
					const duration = walkClip?.duration || 0;
					// Start from middle of the animation (or any desired frame)
					walkAction.time = duration / 160; // Set to middle frame
					walkAction.play().paused = true; // Play but pause immediately to hold the pose
				}
				human.userData = {
					mixer,
					actions: { walk: walkAction },
					path:
						path.length > 1
							? path
									.map((segment, index) => {
										const nextSegment = path[index + 1];
										if (!nextSegment) return null;
										return {
											start: { x: segment.x, y: segment.y, z: segment.z },
											end: {
												x: nextSegment.x,
												y: nextSegment.y,
												z: nextSegment.z,
											},
											waitTime: segment.waitTime || 0,
											distance: Math.sqrt(
												Math.pow(nextSegment.x - segment.x, 2) +
													Math.pow(nextSegment.y - segment.y, 2) +
													Math.pow(nextSegment.z - segment.z, 2)
											),
										};
									})
									.filter(Boolean)
							: [],
					speed: speed || 0,
					currentSegmentIndex: 0,
					distanceTraveled: 0,
					started: false,
					finished: false,
					waitTimeRemaining: path[0]?.waitTime || 0,
				};

				// updateHumanPath(human, scene);
				setupAnimation(human);

				resolve(human);
			},
			undefined,
			(error) => {
				console.error(`Failed to load model for human ${id}:`, error);
				reject(error);
			}
		);
	});
}

function setupAnimation(egv: THREE.Object3D) {
	function animate() {
		const mixerUpdateDelta = clock.getDelta();
		egv.userData.mixer.update(mixerUpdateDelta);
		requestAnimationFrame(animate);
	}
	animate();
}
function createHumanLabel(text: string, id: number): THREE.Sprite {
	const padding = 20;
	const fontSize = 48;
	const font = "bold " + fontSize + "px Arial";

	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");

	if (!context) {
		throw new Error("Failed to get canvas rendering context");
	}

	context.font = font;
	const textWidth = context.measureText(text).width;

	canvas.width = textWidth + padding * 2;
	canvas.height = fontSize + padding * 2;

	const textColor = PREDEFINED_COLORS[id % 10];
	const textColorHex = '#' + textColor.getHexString();

	context.fillStyle = "rgba(255, 255, 255, 0.8)";
	context.fillRect(0, 0, canvas.width, canvas.height);

	context.strokeStyle = textColorHex;
	context.lineWidth = 2;
	context.strokeRect(2, 2, canvas.width - 4, canvas.height - 4);

	context.fillStyle = textColorHex;
	context.textAlign = "center";
	context.textBaseline = "middle";
	context.font = font;
	context.fillText(text, canvas.width / 2, canvas.height / 2);

	const texture = new THREE.CanvasTexture(canvas);

	const material = new THREE.SpriteMaterial({ map: texture });

	const sprite = new THREE.Sprite(material);

	const scaleFactor = 0.1;
	sprite.scale.set(canvas.width * scaleFactor, canvas.height * scaleFactor, 1);

	return sprite;
}

// function animate(group: THREE.Group, delta: number) {
// 	const userData = group.userData || {};
// 	const {
// 		path = [],
// 		speed = 0,
// 		currentSegmentIndex = 0,
// 		distanceTraveled = 0,
// 		mixer,
// 		actions,
// 		started = false,
// 		finished = false,
// 		waitTimeRemaining = 0,
// 	} = userData;

// 	if (!path.length || !mixer || !actions) {
// 		console.warn(`Skipping human due to missing path or animations: ${group.name}`);
// 		return;
// 	}

// 	if (finished) {
// 		console.log(`Human ${group.name} has finished its path.`);
// 		if (!actions.idle.isRunning()) {
// 			actions.walk.stop();
// 			actions.idle.reset().play();
// 		}
// 		return;
// 	}

// 	if (!started) {
// 		console.log(`Starting movement for human ${group.name}.`);
// 		userData.started = true;
// 		userData.currentSegmentIndex = 0;
// 		userData.distanceTraveled = 0;

// 		if (!actions.walk.isRunning()) {
// 			actions.idle.stop();
// 			actions.walk.reset().play();
// 		}
// 	}

// 	const segment = path[currentSegmentIndex];
// 	if (!segment) {
// 		console.log(`No more segments for human ${group.name}.`);
// 		userData.finished = true;
// 		return;
// 	}

// 	if (waitTimeRemaining > 0) {
// 		console.log(
// 			`Human ${group.name} waiting at segment ${currentSegmentIndex} for ${waitTimeRemaining.toFixed(2)}s.`
// 		);
// 		userData.waitTimeRemaining -= delta;
// 		return;
// 	}

// 	const start = new THREE.Vector3(segment.start.x, segment.start.y, segment.start.z);
// 	const end = new THREE.Vector3(segment.end.x, segment.end.y, segment.end.z);

// 	const segmentDistance = start.distanceTo(end);
// 	const newDistance = distanceTraveled + speed * delta;

// 	if (newDistance >= segmentDistance) {
// 		console.log(`Human ${group.name} completed segment ${currentSegmentIndex}.`);
// 		userData.currentSegmentIndex++;
// 		userData.distanceTraveled = 0;

// 		if (userData.currentSegmentIndex >= path.length) {
// 			console.log(`Human ${group.name} reached its destination.`);
// 			userData.finished = true;
// 			group.position.copy(end);
// 			return;
// 		}

// 		const nextSegment = path[userData.currentSegmentIndex];
// 		userData.waitTimeRemaining = nextSegment.waitTime || 0;
// 	} else {
// 		const direction = new THREE.Vector3().subVectors(end, start).normalize();
// 		const currentPosition = new THREE.Vector3()
// 			.copy(start)
// 			.add(direction.multiplyScalar(newDistance));
// 		group.position.copy(currentPosition);

// 		group.lookAt(end);
// 		group.rotateY(Math.PI);

// 		userData.distanceTraveled = newDistance;
// 		// console.log(`Human ${group.name} moved to position:`, currentPosition);
// 	}

// 	mixer.update(delta);
// }

// function animate(group, delta) {
//   const userData = group.userData || {};
//   const {
//     path = [],
//     speed = 0,
//     currentSegmentIndex = 0,
//     distanceTraveled = 0,
//     mixer,
//     actions,
//     started = false,
//     finished = false,
//     waitTimeRemaining = 0,
//     paused = false, // Add paused state handling
//   } = userData;

//   if (paused) {
//     console.log(`Animation paused for group ${group.name}`);
//     return;
//   }

//   if (!path.length || !mixer || !actions) {
//     console.warn(`Skipping human due to missing path or animations: ${group.name}`);
//     return;
//   }

//   if (finished) {
//     console.log(`Human ${group.name} has finished its path.`);
//     if (!actions.idle.isRunning()) {
//       actions.walk.stop();
//       actions.idle.reset().play();
//     }
//     return;
//   }

//   if (!started) {
//     console.log(`Starting movement for human ${group.name}.`);
//     userData.started = true;
//     userData.currentSegmentIndex = 0;
//     userData.distanceTraveled = 0;

//     if (!actions.walk.isRunning()) {
//       actions.idle.stop();
//       actions.walk.reset().play();
//     }
//   }

//   const segment = path[currentSegmentIndex];
//   if (!segment) {
//     console.log(`No more segments for human ${group.name}.`);
//     userData.finished = true;
//     return;
//   }

//   if (waitTimeRemaining > 0) {
//     // console.log(
//     //   `Human ${group.name} waiting at segment ${currentSegmentIndex} for ${waitTimeRemaining.toFixed(2)}s.`
//     // );
//     userData.waitTimeRemaining -= delta;
//     return;
//   }

//   const start = new THREE.Vector3(segment.start.x, segment.start.y, segment.start.z);
//   const end = new THREE.Vector3(segment.end.x, segment.end.y, segment.end.z);

//   const segmentDistance = start.distanceTo(end);
//   const newDistance = distanceTraveled + speed * delta;

//   if (newDistance >= segmentDistance) {
//     console.log(`Human ${group.name} completed segment ${currentSegmentIndex}.`);
//     userData.currentSegmentIndex++;
//     userData.distanceTraveled = 0;

//     if (userData.currentSegmentIndex >= path.length) {
//       console.log(`Human ${group.name} reached its destination.`);
//       userData.finished = true;
//       group.position.copy(end);
//       return;
//     }

//     const nextSegment = path[userData.currentSegmentIndex];
//     userData.waitTimeRemaining = nextSegment.waitTime || 0;
//   } else {
//     const direction = new THREE.Vector3().subVectors(end, start).normalize();
//     const currentPosition = new THREE.Vector3()
//       .copy(start)
//       .add(direction.multiplyScalar(newDistance));
//     group.position.copy(currentPosition);

//     group.lookAt(end);
//     group.rotateY(Math.PI);

//     userData.distanceTraveled = newDistance;
//   }

//   mixer.update(delta);
// }



function animate(group, delta) {
	const userData = group.userData || {};
	const {
		path = [],
		speed = 0,
		currentSegmentIndex = 0,
		distanceTraveled = 0,
		mixer,
		actions,
		started = false,
		finished = false,
		waitTimeRemaining = 0,
		paused = false,
	} = userData;

	if (paused || finished || !path.length) return;

	const speedMultiplier = userData.speedMultiplier || 1;

	if (!started) {
		console.log(`Starting movement for human ${group.name}.`);
		userData.started = true;
		userData.currentSegmentIndex = 0;
		userData.distanceTraveled = 0;

		if (!actions.walk.isRunning()) {
			actions.walk.reset().play();
		}
	}

	if (waitTimeRemaining > 0) {
    // Apply multiplier to wait time reduction
    userData.waitTimeRemaining -= delta * speedMultiplier;
    return;
  }

	const segment = path[currentSegmentIndex];
	if (!segment) {
		console.log(`No more segments for human ${group.name}.`);
		userData.finished = true;
		return;
	}

	if (waitTimeRemaining > 0) {
		userData.waitTimeRemaining -= delta;
		if (actions.walk.isRunning()) {
			actions.walk.stop();
			actions.walk.reset(); // Reset to initial frame while waiting
		}
		return;
	} else if (!actions.walk.isRunning()) {
		actions.walk.reset().play(); // Resume walking after wait time
	}

	const start = new THREE.Vector3(
		segment.start.x,
		segment.start.y,
		segment.start.z
	);
	const end = new THREE.Vector3(segment.end.x, segment.end.y, segment.end.z);

	const segmentDistance = start.distanceTo(end);
	const newDistance = distanceTraveled + (speed * speedMultiplier * delta);

	if (newDistance >= segmentDistance) {
		console.log(
			`Human ${group.name} completed segment ${currentSegmentIndex}.`
		);
		userData.currentSegmentIndex++;
		userData.distanceTraveled = 0;

		if (userData.currentSegmentIndex >= path.length) {
			console.log(`Human ${group.name} reached its destination.`);
			userData.finished = true;
			group.position.copy(end);
			if (actions.walk.isRunning()) {
				// actions.walk.stop();
				const duration = actions.walk.getClip().duration;
				actions.walk.time = duration / 160;
				actions.walk.paused = true;
			}
			return;
		}

		const nextSegment = path[userData.currentSegmentIndex];
		userData.waitTimeRemaining = nextSegment.waitTime || 0;
	} else {
		const direction = new THREE.Vector3().subVectors(end, start).normalize();
		const currentPosition = new THREE.Vector3()
			.copy(start)
			.add(direction.multiplyScalar(newDistance));
		group.position.copy(currentPosition);

		group.lookAt(end);

		userData.distanceTraveled = newDistance;
	}

	mixer.update(speedMultiplier * delta);
}

function drawHumanPath(humans: TPSSHuman[], scene: THREE.Scene) {
  const pathGroup = new THREE.Group();
  pathGroup.name = "humanPaths";

  const pathWidth = 0.3; // Width of the path in units

  humans.forEach((human) => {
    const index = human.id;
		const points = human.path;
    if (!points || points.length < 2) {
      console.warn(`Human ${human.id} needs at least two points to draw a path.`);
      return;
    }

    const material = new THREE.MeshPhongMaterial({
      color: getRandomColor(index),
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.5,
    });

		const offset = (index % 10 + 1) * 0.1;

    // Create path segments between consecutive points
    for (let i = 0; i < points.length - 1; i++) {
      const start = new THREE.Vector3(points[i].x, points[i].y + offset, points[i].z);
      const end = new THREE.Vector3(points[i + 1].x, points[i + 1].y + offset, points[i + 1].z);

      const direction = new THREE.Vector3().subVectors(end, start).normalize();

      // Calculate perpendicular vector for path width
      const perpendicular = new THREE.Vector3(0, 1, 0)
        .cross(direction)
        .normalize()
        .multiplyScalar(pathWidth / 2);

      // Define corners of the path segment
      const corners = [
        start.clone().add(perpendicular),     // Top-left
        start.clone().sub(perpendicular),     // Bottom-left
        end.clone().sub(perpendicular),       // Bottom-right
        end.clone().add(perpendicular),       // Top-right
      ];

      // Create geometry for the path segment
      const geometry = new THREE.BufferGeometry();
      const vertices = [
        corners[0], corners[1], corners[3],   // First triangle
        corners[3], corners[1], corners[2],   // Second triangle
      ];

      const positions = new Float32Array(
        vertices.flatMap((v) => [v.x, v.y, v.z])
      );

      geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
      geometry.computeVertexNormals(); // Add normals for proper lighting

      // Create the mesh and add it to the group
      const mesh = new THREE.Mesh(geometry, material);
      pathGroup.add(mesh);
    }
  });
  scene.add(pathGroup);
}

const PREDEFINED_COLORS = [
  new THREE.Color("#4169E1"), // Royal Blue
  new THREE.Color("#9932CC"), // Dark Orchid
  new THREE.Color("#0000FF"), // Blue
  new THREE.Color("#FFFF00"), // Yellow
  new THREE.Color("#FF00FF"), // Magenta
  new THREE.Color("#00FFFF"), // Cyan
  new THREE.Color("#FFA500"), // Orange
  new THREE.Color("#800080"), // Purple
  new THREE.Color("#4B0082"), // Indigo
  new THREE.Color("#FFC0CB"), // Pink
];

function getRandomColor(index: number): THREE.Color {
  return PREDEFINED_COLORS[index % 10];
}

export { createHumans, drawHumanPath };