import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@blueprintjs/core";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import {
  createPSSTask,
  changePSSTask,
  removePSSTask,
  createPSSAction,
  createPSSTaskMultiple,
  switchDrawingAction,
} from "../../../../../store/pss/actions";
import { ApplicationState } from "../../../../../store";
import { getCurrentPSS, getCurrentProject, getNextId } from "../../../../3d-models/utils";
import "../Factory/FactoryPathManager.css"

interface TPSSTask {
  id: number;
  description: string;
  duration: number;
  x: number;
  y: number;
  z: number;
}

export function TaskManager() {
  const [selected, setSelected] = useState<number[]>([]);

  const dispatch = useDispatch();

  const pss = useSelector((state: ApplicationState) => getCurrentPSS(state));
  const currentProject = useSelector((state: ApplicationState) => getCurrentProject(state));

  const tasks = useMemo(() => pss?.tasks ?? [], [pss]);
  const drawing = useSelector((state: ApplicationState) => state.pss.drawing);


  useEffect(() => {
    if (currentProject && !pss) {
      console.log("PSS is undefined, creating a new one...");
      dispatch(createPSSAction(currentProject.name));
    }
  }, [currentProject, pss, dispatch]);

  useEffect(() => {
    const duplicateIds = tasks
      .map((task) => task.id)
      .filter((id, index, self) => self.indexOf(id) !== index);
    if (duplicateIds.length) {
      console.warn("Duplicate task IDs detected:", duplicateIds);
    }
  }, [tasks]);

  function handleAddRow() {
    if (!pss) return;
    const newTask: TPSSTask = { id: getNextId(tasks), description: "", duration: 0, x: 0, y: 0, z: 0 };
    dispatch(createPSSTask(pss.project, newTask));
  }

  const handleDrawPath = () => {
    dispatch(switchDrawingAction(!drawing));
  };

  function handleDeleteRows() {
    if (!pss) return;
    selected.forEach((id) => {
      const taskToRemove = tasks.find((task) => task.id === id);
      if (taskToRemove) {
        dispatch(removePSSTask(pss.project, taskToRemove));
      }
    });
    setSelected([]);
  }

  function handleTaskChange(id: number, key: keyof TPSSTask, value: any) {
    if (!pss) return;
    const task = tasks.find((t) => t.id === id);
    if (task) {
      const updatedTask = { ...task, [key]: value };
      dispatch(changePSSTask(pss.project, updatedTask));
    }
  }

  function toggleSelectRow(id: number) {
    setSelected((prev) =>
      prev.includes(id) ? prev.filter((sid) => sid !== id) : [...prev, id]
    );
  }

  function handleExportToCSV() {
    if (!tasks.length) {
      alert("No data to export");
      return;
    }
    const csv = Papa.unparse(
      tasks.map(({ id, description, duration, x, y, z }) => ({
        id,
        description,
        duration,
        x,
        y,
        z,
      }))
    );
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "tasks.csv");
  }

  function handleImportFromCSV(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (!file || !pss) return;
  
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        if (!results.data || !Array.isArray(results.data)) {
          console.error("Invalid data in CSV file");
          alert("Invalid CSV file. Please check the format.");
          return;
        }
        const filteredData = results.data.filter(
          (row: any) => row.description && row.description.trim() !== ""
        );
  
        if (filteredData.length === 0) {
          alert("No valid rows with descriptions found in the CSV.");
          return;
        }
        dispatch(createPSSTaskMultiple(pss.project, [])); 
        let nextId = getNextId(tasks);
        const importedTasks: TPSSTask[] = filteredData.map((row: any) => {
          return {
            id: nextId++, 
            description: row.description.trim(),
            duration: row.duration && !isNaN(row.duration) ? Number(row.duration) : 0,
            x: row.x && !isNaN(row.x) ? parseFloat(row.x) : 0,
            y: row.y && !isNaN(row.y) ? parseFloat(row.y) : 0,
            z: row.z && !isNaN(row.z) ? parseFloat(row.z) : 0,
          };
        });
  
        dispatch(createPSSTaskMultiple(pss.project, importedTasks));
  
        console.log("Import complete.");
      },
      error: (error) => {
        console.error("Error while parsing CSV:", error);
        alert("Failed to import CSV file. Please try again.");
      },
    });
  }
  

  // function handleImportFromCSV(event: React.ChangeEvent<HTMLInputElement>) {
  //   const file = event.target.files?.[0];
  //   if (!file || !pss) return;

  //   Papa.parse(file, {
  //     header: true,
  //     complete: (results) => {
  //       if (!results.data || !Array.isArray(results.data)) {
  //         console.error("Invalid data in CSV file");
  //         alert("Invalid CSV file. Please check the format.");
  //         return;
  //       }

  //       const existingIds = tasks.map((task) => task.id);
  //       let maxId = existingIds.length ? Math.max(...existingIds) : 0;

  //       const importedTasks = results.data.map((row: any) => {
  //         let id: number;
  //         const parsedId = Number(row.id);
  //         if (parsedId && !existingIds.includes(parsedId)) {
  //           id = parsedId;
  //         } else {
  //           maxId += 1;
  //           id = maxId;
  //         }
  //         existingIds.push(id);

  //         return {
  //           id,
  //           description: row.description || "",
  //           duration: row.duration && !isNaN(row.duration) ? Number(row.duration) : 0,
  //           x: row.x && !isNaN(row.x) ? parseFloat(row.x) : 0,
  //           y: row.y && !isNaN(row.y) ? parseFloat(row.y) : 0,
  //           z: row.z && !isNaN(row.z) ? parseFloat(row.z) : 0,
  //         } as TPSSTask;
  //       });

  //       const updatedTasks = [...tasks, ...importedTasks];
  //       console.log("this is the updated task",updatedTasks)

  //       dispatch(createPSSTaskMultiple(pss.project, updatedTasks));

  //       console.log("Import complete.");
  //     },
  //     error: (error) => {
  //       console.error("Error while parsing CSV:", error);
  //       alert("Failed to import CSV file. Please try again.");
  //     },
  //   });
  // }

  const toggleSelectAll = () => {
    if (selected.length === tasks.length) {
      setSelected([]);
    } else {
      setSelected(tasks.map((task) => task.id));
    }
  };
  
  const isAllSelected = selected.length === tasks.length && tasks.length > 0;
  

  const formatToThreeDecimals = (value: number) =>
    value !== undefined && value !== null ? value.toFixed(3) : "0.000";

  return (
    <div className="factory-path-manager">
      {/* Action Buttons */}
      <div className="actions">
        <Button
          small
          icon="trash"
          text="Delete"
          intent="warning"
          disabled={!selected.length}
          onClick={handleDeleteRows}
        />
        {/* <Button
          small
          icon="plus"
          text="Add Row"
          intent="primary"
          onClick={handleAddRow}
        /> */}
        <Button
          small
          icon="download"
          text="Export to CSV"
          intent="success"
          onClick={handleExportToCSV}
        />
        <label htmlFor="csv-upload" className="bp3-button bp3-small bp3-intent-primary">
          Import from CSV
          <input
            id="csv-upload"
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleImportFromCSV}
          />
        </label>
        <Button
          small
          icon="new-drawing"
          text={drawing ? "Stop Drawing" : "Draw Path"}
          intent={drawing ? "danger" : "warning"}
          onClick={handleDrawPath}
        />
      </div>
  
      {/* Table */}
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
            <th>
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={toggleSelectAll}
              />
            </th>
              <th>WayPoints #</th>
              <th>Description</th>
              <th>Duration (seconds)</th>
              <th>X(m)</th>
              <th>Y(m)</th>
              <th>Z(m)</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={task.id || `task-${index}`}>
                <td>
                  <input
                    type="checkbox"
                    checked={selected.includes(task.id)}
                    onChange={() => toggleSelectRow(task.id)}
                  />
                </td>
                <td>{task.id}</td>
                <td>
                  <input
                    className="text-input"
                    type="text"
                    value={task.description}
                    onChange={(e) =>
                      handleTaskChange(task.id, "description", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    className="number-input"
                    type="number"
                    step="1"
                    value={task.duration}
                    
                    onChange={(e) =>
                      handleTaskChange(
                        task.id,
                        "duration",
                        parseFloat(e.target.value) || 0
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    className="number-input"
                    type="number"
                    step="1"
                    value={formatToThreeDecimals(task.x)}
                    disabled
                    onChange={(e) =>
                      handleTaskChange(task.id, "x", parseFloat(e.target.value) || 0)
                    }
                  />
                </td>
                <td>
                  <input
                    className="number-input"
                    type="number"
                    step="0.001"
                    value={formatToThreeDecimals(task.y)}
                    disabled
                    onChange={(e) =>
                      handleTaskChange(task.id, "y", parseFloat(e.target.value) || 0)
                    }
                  />
                </td>
                <td>
                  <input
                    className="number-input"
                    type="number"
                    step="0.001"
                    disabled
                    value={formatToThreeDecimals(task.z)}
                    onChange={(e) =>
                      handleTaskChange(task.id, "z", parseFloat(e.target.value) || 0)
                    }
                  />
                </td>
              </tr>
            ))}
            {tasks.length === 0 && (
              <tr>
                <td colSpan={7} className="text-center">
                  No tasks available. Use &quot;Add Row&quot; to create tasks.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
  
}

