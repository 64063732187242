import React, { useEffect, useMemo, useState } from "react";
import { HumanPathDlg } from "./HumanPathDlg";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import {
  getCurrentProject,
  getCurrentPSS,
  getNextId,
} from "../../../../3d-models/utils";
import {
  TPSSConveyor,
  TPSSEGVPath,
  TPSSHuman,
} from "../../../../../store/pss/types";
import { Button } from "@blueprintjs/core";
import { NumericCell } from "../../../../common/NumericCell";
import {
  changePSSAction,
  changePSSHuman,
  createPSSAction,
  createPSSHuman,
} from "../../../../../store/pss/actions";
import { initialHuman } from "../../../../../store/pss/initialState";
import { CheckBoxCell } from "../../../../common/CheckBoxCell";
import Papa from "papaparse";
import { InputCell } from "../../../../common/InputCell";
import "../Factory/FactoryPathManager.css"

type Props = {};

export function Human(props: Props) {
  const [dlg, setDlg] = useState<JSX.Element>();
  const [selected, setSelected] = useState<number[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);

  const currentProject = useSelector((state: ApplicationState) =>
    getCurrentProject(state)
  );
  const pss = useSelector((state: ApplicationState) => getCurrentPSS(state));
  const dispatch = useDispatch();

  const data = useMemo(() => {
    return pss?.humans ?? [];
	}, [pss?.humans]); 


  function handleChange(item: TPSSHuman, field: string, value: any) {
    if (!pss) return;
    dispatch(changePSSHuman(pss.project, { ...item, [field]: value }));
  }

  function handleSelect(item: TPSSHuman) {
    if (selected.includes(item.id)) {
      setSelected(selected.filter((s) => s !== item.id));
    } else {
      setSelected([...selected, item.id]);
    }
  }

  function handleDelete() {
    if (!pss) return;
    dispatch(
      changePSSAction(
        pss.project,
        "humans",
        data.filter((d) => !selected.includes(d.id))
      )
    );
    setSelected([]);
  }

  function handleAdd() {
    if (pss) {
      dispatch(
        createPSSHuman(pss.project, { ...initialHuman, id: getNextId(data) })
      );
    }
  }

//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (!file) return;

//     Papa.parse(file, {
//       header: true,
//       skipEmptyLines: true,
//       complete: (result) => {
//         const importedHumans: TPSSHuman[] = [];
//         if (!result.data) {
//           console.error("No data found in CSV file");
//           return;
//         }
//         result.data.forEach((row: any) => {
//           if (!row.No || !row.Speed || !row.Path) {
//             console.error("Invalid row:", row);
//             return;
//           }

//           const path: TPSSEGVPath[] = JSON.parse(row.Path).map(
//             (coord: [number, number, number], index: number) => {
//               const [x, y, z] = coord;
//               return {
//                 id: index + 1,
//                 x,
//                 y,
//                 z,
//                 waitTime: 1,
//               };
//             }
//           );
//           const result = {
//             id: parseInt(row.No, 10),
//             name: row.Name || "",
//             speed: parseFloat(row.Speed),
//             path,
//           };
//           importedHumans.push(result);
//         });

//         if (pss) {
//           dispatch(
//             changePSSAction(pss.project, "humans", [...data, ...importedHumans])
//           );
//         }
//       },
//       error: (error) => {
//         console.error("Error parsing CSV:", error);
//         alert("Failed to parse CSV file");
//       },
//     });
//   };

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	const file = event.target.files?.[0];
	if (!file) return;
  
	// Clear existing data
	if (pss) {
	  dispatch(changePSSAction(pss.project, "humans", []));
	}
  
	Papa.parse(file, {
	  header: true,
	  skipEmptyLines: true,
	  complete: (result) => {
		if (!result.data || !Array.isArray(result.data)) {
		  console.error("No valid data found in CSV file");
		  alert("Invalid CSV file format.");
		  return;
		}
  
		const importedHumans: TPSSHuman[] = [];
		let nextId = data.length
		  ? Math.max(...data.map((item) => item.id || 0)) + 1
		  : 1;
  
		result.data.forEach((row: any) => {
		  const trimmedRow = {
			No: row.No?.trim(),
			Name: row.Name?.trim(),
			Speed: row.Speed?.trim(),
			Path: row.Path?.trim(),
		  };
  
		  if (!trimmedRow.Speed || !trimmedRow.Path) {
			console.error("Invalid row:", trimmedRow);
			return;
		  }
  
		  const parsedSpeed = parseFloat(trimmedRow.Speed);
		  if (isNaN(parsedSpeed) || parsedSpeed <= 0) {
			console.error("Invalid speed in row:", trimmedRow);
			return;
		  }
  
		  const parsedPath: TPSSEGVPath[] = (() => {
			try {
			  return JSON.parse(trimmedRow.Path).map(
				(coord: [number, number, number], index: number) => {
				  const [x, y, z] = coord;
				  return { id: index + 1, x, y, z, waitTime: 1 };
				}
			  );
			} catch (error) {
			  console.error("Invalid path data in row:", trimmedRow);
			  return [];
			}
		  })();
  
		  if (!parsedPath.length) {
			console.error("Path parsing failed for row:", trimmedRow);
			return;
		  }
  
		  importedHumans.push({
			id: nextId++, 
			name: trimmedRow.Name || `Operator ${nextId - 1}`,
			speed: parsedSpeed,
			path: parsedPath,
		  });
		});
  
		if (pss) {
		  dispatch(
			changePSSAction(pss.project, "humans", [...data, ...importedHumans])
		  );
		}
	  },
	  error: (error) => {
		console.error("Error parsing CSV:", error);
		alert("Failed to parse CSV file.");
	  },
	});
  };
  
	



// function handleExportToCSV() {
//     if (!data.length) {
//       alert("No data to export");
//       return;
//     }

//     const csv = Papa.unparse(
//       data.map((item) => ({
//         No: item.id,
//         Name: item.name || "",
//         Speed: item.speed,
//         Path: JSON.stringify(item.path?.map(({ x, y, z,waitTime }) => [x, y, z,waitTime])),
//       }))
//     );

//     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
//     saveAs(blob, "humans.csv");
//   }

function handleExportToCSV() {
    if (!data.length) {
        alert("No data to export");
        return;
    }

    const filteredData = data.filter((item) => item.path && item.path.length > 0);

    if (!filteredData.length) {
        alert("No valid data to export");
        return;
    }

    const csv = Papa.unparse(
        filteredData.map((item) => ({
            No: item.id,
            Name: item.name || "",
            Speed: item.speed,
            Path: JSON.stringify(item.path?.map(({ x, y, z, waitTime }) => [x, y, z, waitTime])),
        }))
    );

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "humans.csv");
}


  const toggleSelectAll = () => {
	if (selected.length === data.length) {
	  // If all are selected, deselect all
	  setSelected([]);
	} else {
	  // If not all are selected, select all
	  setSelected(data.map((item) => item.id));
	}
  };
  
  const isAllSelected = selected.length === data.length && data.length > 0;
  

  function getRow(item: TPSSHuman) {
    if (!pss) return null;

    const handleIdChange = (value: string) => {
      const newId = parseInt(value, 10);
      if (isNaN(newId) || data.some((d) => d.id === newId)) {
        alert("Operator number must be a unique positive integer.");
        return;
      }
      dispatch(changePSSHuman(pss.project, { ...item, id: newId }));
    };

    const handleNameChange = (value: string) => {
      dispatch(changePSSHuman(pss.project, { ...item, name: value }));
    };
	  

    return (
      <tr key={item.id}>
        <CheckBoxCell
          key={item.id}
          value={selected.includes(item.id)}
          onChange={() => handleSelect(item)}
        />
          <NumericCell
            type="text"
            value={item.id}
            className={"w-100"}
          />
          <InputCell
            value={item.name || ""}
			onChange={(val) => handleChange(item, "name", val)}
            className={"w-200"}
          />
        <td>
          <Button
            small
            minimal
            icon={"menu"}
            intent={"primary"}
            className={"c-light"}
			// disabled
            onClick={() => {
              setDlg(
                <HumanPathDlg
                  name={pss?.project}
                  item={item}
                  onClose={() => setDlg(undefined)}
                />
              );
            }}
          />
        </td>
        <NumericCell
          isDecimal={true}
          value={item.speed ?? 0}
          onChange={(val) => handleChange(item, "speed", val)}
          className={"w-100"}
        />
      </tr>
    );
  }

return (
	<>
	  <div className="factory-path-manager">
		<div className="hr" />
		<div className="content">
		  <div className="actions">
			<Button
			  small
			  icon="trash"
			  text="Delete"
			  disabled={!selected.length}
			  intent="warning"
			  onClick={handleDelete}
			/>
			<Button
			  small
			  icon="plus"
			  text="Add Row"
			  intent="primary"
			  onClick={handleAdd}
			/>
			<Button
			  small
			  icon="import"
			  text="Import CSV"
			  intent="success"
			  onClick={() => document.getElementById("csvInput")?.click()}
			/>
			<input
			  id="csvInput"
			  type="file"
			  accept=".csv"
			  style={{ display: "none" }}
			  onChange={handleFileChange}
			/>
			<Button
			  small
			  icon="export"
			  text="Export to CSV"
			  intent="success"
			  onClick={handleExportToCSV}
			/>
		  </div>
		  <div className="table-container">
			<table className="table">
			  <thead>
				<tr>
				<th>
					<input
						type="checkbox"
						checked={isAllSelected}
						onChange={toggleSelectAll}
					/>
				</th>
				  <th>Operator No.</th>
				  <th>Operator Name</th>
				  <th>Operator Path</th>
				  <th>Operator Speed, m/s</th>
				</tr>
			  </thead>
			  <tbody>{data.map(getRow)}</tbody>
			</table>
		  </div>
		</div>
	  </div>
	  {dlg}
	</>
  );
	

}

