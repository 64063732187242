import * as THREE from 'three';
import { TPSSFactoryPath, TPSSTask, TPSSWaypoint } from '../../../../../store/pss/types';
import { FontLoader } from 'three';

function drawSpheresAtPoints(
  scene: THREE.Scene,
  tasks: TPSSTask[],
  radius: number = 1,
  color: number = 0xff0000
): void {
const pathGroup = new THREE.Group();
pathGroup.name = "Waypoints";

const material = new THREE.MeshStandardMaterial({ color });


const fontLoader = new FontLoader();
  fontLoader.load(
    'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', // Path to font
    (font) => {
      tasks.forEach((task) => {
        const geometry = new THREE.SphereGeometry(radius, 32, 32);
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.set(task.x, task.y, task.z);
        pathGroup.add(sphere);

        const textGeometry = new THREE.TextGeometry('#' + task.id + " " + (task.description || " Task"), {
          font,
          size: 0.5,
          height: 0.1,
        });

        const textMaterial = new THREE.MeshStandardMaterial({ color: 'red' });
        const textMesh = new THREE.Mesh(textGeometry, textMaterial);

        textMesh.position.set(task.x + radius * 2, task.y, task.z); 

        textMesh.rotation.x = -Math.PI / 2;

        pathGroup.add(textMesh);
      });

      scene.add(pathGroup);
    },
    undefined,
    (error) => {
      console.error('Error loading font:', error);
    }
  );
  scene.add(pathGroup);
}

function updateWaypointsOnPath(
  updatedPath: TPSSFactoryPath[], // The updated path (array of points in order)
  waypoints: TPSSTask[], // Existing waypoints
  tolerance: number = 0.1 // Maximum distance to consider a waypoint "on the path"
): TPSSTask[] {
  const isPointOnPath = (point: TPSSTask, path: TPSSFactoryPath[], tolerance: number): boolean => {
    for (let i = 0; i < path.length - 1; i++) {
      const start = new THREE.Vector3(path[i].x, path[i].y, path[i].z);
      const end = new THREE.Vector3(path[i + 1].x, path[i + 1].y, path[i + 1].z);
      const pointVec = new THREE.Vector3(point.x, point.y, point.z);

      // Calculate distance from point to the line segment
      const segmentVec = new THREE.Vector3().subVectors(end, start);
      const projected = new THREE.Vector3()
        .copy(pointVec)
        .sub(start)
        .projectOnVector(segmentVec);
      const closestPoint = new THREE.Vector3().copy(start).add(projected);

      // Clamp closestPoint to the segment
      if (closestPoint.distanceTo(start) > segmentVec.length()) continue;
      if (closestPoint.distanceTo(end) > segmentVec.length()) continue;

      const distance = closestPoint.distanceTo(pointVec);
      if (distance <= tolerance) {
        return true;
      }
    }
    return false;
  };

  // Filter out waypoints that are no longer on the path
  const updatedWaypoints = waypoints.filter((waypoint) =>
    isPointOnPath(waypoint, updatedPath, tolerance)
  );

  return updatedWaypoints;
}

export { drawSpheresAtPoints, updateWaypointsOnPath };