import React, { useEffect, useMemo, useState } from "react";
import { CustomDlg } from "../../common/CustomDlg";
import { Button } from "@blueprintjs/core";
import Axios from "axios";
import { secondServerAPI } from "../../../pages/utils/agent";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { convertControls, getCurrentProcess, getCurrentUI, getElementByName, getGLTFItems } from "../../3d-models/utils";
import { ProjectUI } from "../../../store/ui/types";
import { TWorkMode } from "../../../store/main/types";
import { CombinedSection, RolledSection, Section, TPipingElbow } from "../../../store/data/types";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { convertProcessToImporting } from "../../3d-models/process/process";
import { useDispatch } from "react-redux";
import { addEventAction } from "../../../store/ui/actions";
import { Scene } from "three";

type Props = {
    onClose: () => any;
};

type Project = {
    projectId: number;
    projectName: string;
    projectPhase: string;
};

export function UploadProject(props: Props) {
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState("");
    const [inputFileName, setInputFileName] = useState<string>("");
    const [ude, setUde] = useState<{ fileId: number; fileName: string }[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);


    const auth = useSelector((state: ApplicationState) => state.auth);

    const scene = useSelector((state: ApplicationState) => state.main.scene);

    const allProjects = useSelector(
        (state: ApplicationState) => state.main.projects
    );

    const currentProject = useSelector(
        (state: ApplicationState) => state.main.currentProject
    );

    const project_101 = useMemo(() => {
        return getElementByName(allProjects, currentProject);
    }, [allProjects, currentProject]);

    const ui = useSelector(
        (state: ApplicationState) => getCurrentUI(state)
    );

    const fabricatedSections = useSelector(
        (state: ApplicationState) => state.main.fabricatedSections
    );

    const rolledSections = useSelector(
        (state: ApplicationState) => state.main.rolledSections
    );

    const combinedSections = useSelector(
        (state: ApplicationState) => state.main.combinedSections
    );

    const userDefinedElbows = useSelector(
        (state: ApplicationState) => state.main.userDefinedElbows
    );

    const process = useSelector(
        (state: ApplicationState) => getCurrentProcess(state)
    );

    const controls = useSelector(
        (state: ApplicationState) => state.scene.controls
    );

    const dispatch = useDispatch();

    let content = {};

    // function createDDDJson(
    //     project: Project | undefined,
    //     ui: ProjectUI | undefined,
    //     process: TWorkMode,
    //     fabricatedSections: Section[],
    //     rolledSections: RolledSection[],
    //     combinedSections: CombinedSection[],
    //     userDefinedElbows: TPipingElbow[],
    //     controls: OrbitControls | undefined,
    //     scene: Scene,
    //     inputFileName: any,
    // ) {
    //     if (!project) return;
    //     content = {
    //         ...(process
    //             ? convertProcessToImporting(project.projectName, process, true)
    //             : {}),
    //         project: {
    //             ...project,
    //             freePipes: project?.freePipes || [],
    //             pipeAnalysis: undefined,
    //             GLTFItems: getGLTFItems(scene,project,inputFileName),
    //         } as Project,
    //         fabricatedSections,
    //         rolledSections,
    //         combinedSections,
    //         userDefinedElbows,
    //         ui: ui ? { ...ui, analysisUI: {} } : undefined,
    //         camera: convertControls(controls),
    //         GLTFItems: getGLTFItems(scene,project,inputFileName),
    //     };
    // }

    function createDDDJson(
        project: Project | undefined,
        ui: ProjectUI | undefined,
        process: TWorkMode,
        fabricatedSections: Section[],
        rolledSections: RolledSection[],
        combinedSections: CombinedSection[],
        userDefinedElbows: TPipingElbow[],
        controls: OrbitControls | undefined,
        scene: Scene,
        inputFileName: any
    ): boolean {
        if (!project || !isDataLoaded) {
            console.error("Required data is not loaded. Aborting DDD creation.");
            return false;
        }
    
        const GLTFItems = getGLTFItems(scene, project, inputFileName);
        const unmatchedItems = GLTFItems.filter(
            item => !ude.some(udeItem => udeItem.fileName === item.name)
        );
    
        if (unmatchedItems.length > 0) {
            const unmatchedNames = unmatchedItems.map(item => item.name).join(", ");
            dispatch(
                addEventAction(
                    `One or more of your current assets do not match the library and will corrupt your file. Please replace them with stored assets. Unmatched items: ${unmatchedNames}`,
                    "warning"
                )
            );
            return false;
        }
    
        content = {
            ...(process ? convertProcessToImporting(project.projectName, process, true) : {}),
            project: {
                ...project,
                freePipes: project?.freePipes || [],
                pipeAnalysis: undefined,
                GLTFItems: getGLTFItems(scene, project, inputFileName),
            } as Project,
            fabricatedSections,
            rolledSections,
            combinedSections,
            userDefinedElbows,
            ui: ui ? { ...ui, analysisUI: {} } : undefined,
            camera: convertControls(controls),
            GLTFItems: getGLTFItems(scene, project, inputFileName),
        };
    
        return true;
    }
    
    
    

    // async function handleSaveProjectFile() {
    //     createDDDJson(project_101, ui, process, fabricatedSections, rolledSections, combinedSections, userDefinedElbows, controls, scene, selectedProject);
    //     console.log("this is the selected project", selectedProject);
    //     const selectedProj = projects.find(proj => proj.projectName === selectedProject);
    //     const projectId = selectedProj ? selectedProj.projectId : null;

    //     if (projectId === null) {
    //         console.error("Selected project not found!");
    //         return;
    //     }

    //     try {
    //         const response = await Axios.post(`${secondServerAPI}rest/api/v1/layouts/uploadProjectFile`, content, {
    //             headers: {
    //                 // "user-id": 1,
    //                 "user-id": auth.User_id,
    //                 "project-id": projectId,
    //                 "file-name": inputFileName,
    //             },
    //         });

    //         if (response.status === 200) {
    //             dispatch(
    //                 addEventAction("Project File Saved Successfully", "success")
    //             )
                
    //         }
    //     } catch (err) {
    //         dispatch(
    //             addEventAction("The file already exist. Please use update","warning")
    //         )
    //     }
    // }

    async function handleSaveProjectFile() {
        // if (!isDataLoaded) {
        //     console.error("Data is not fully loaded. Aborting save operation.");
        //     return;
        // }
        const isContentCreated = createDDDJson(project_101, ui, process, fabricatedSections, rolledSections, combinedSections, userDefinedElbows, controls, scene, selectedProject);
        if (!isContentCreated) {
            console.error("Failed to create DDD file. Aborting save operation.");
            return;
        }
    
        console.log("this is the selected project", selectedProject);
        const selectedProj = projects.find(proj => proj.projectName === selectedProject);
        const projectId = selectedProj ? selectedProj.projectId : null;
    
        if (projectId === null) {
            console.error("Selected project not found!");
            return;
        }
    
        try {
            console.log("this is the content",content)
            const response = await Axios.post(`${secondServerAPI}rest/api/v1/layouts/uploadProjectFile`, content, {
                headers: {
                    // "user-id": 1,
                    "user-id": auth.User_id,
                    "project-id": projectId,
                    "file-name": inputFileName,
                },
            });
    
            if (response.status === 200) {
                dispatch(
                    addEventAction("Project File Saved Successfully", "success")
                )
            }
        } catch (err) {
            dispatch(
                addEventAction("The file already exists. Please use update", "warning")
            )
        }
    }
    


    // async function handleProjectUpdate() {
    //     createDDDJson(project_101, ui, process, fabricatedSections, rolledSections, combinedSections, userDefinedElbows, controls, scene,selectedProject);
    //     console.log("this is the selected project", selectedProject)
    //     const selectedProj = projects.find(proj => proj.projectName === selectedProject);
    //     const projectId = selectedProj ? selectedProj.projectId : null;

    //     if (projectId === null) {
    //         dispatch(
    //             addEventAction("Project does not exist.", "warning")
    //         )
    //     }

    //     try {
    //         const response = await Axios.put(`${secondServerAPI}rest/api/v1/layouts/updateProjectFile`, content, {
    //             headers: {
    //                 // "user-id": 1,
    //                 "user-id": auth.User_id,
    //                 "project-id": projectId,
    //                 "file-name": inputFileName,
    //             },
    //         });

    //         if (response.status === 200) {
    //             console.log("Projects data", response.data);
    //             dispatch(
    //                 addEventAction("Project Data Updated", "success")
    //             )
    //         }
    //         else if(response.status === 400) {
    //             console.log("Error", err);
    //         }
    //     } catch (err: any) {
    //         dispatch(
    //             addEventAction("No Project Found", "danger")
    //         )
    //     }

    // }

    async function handleProjectUpdate() {
        if (!isDataLoaded) {
            console.error("Data is not fully loaded. Aborting save operation.");
            return;
        }
        const isContentCreated = createDDDJson(
            project_101,
            ui,
            process,
            fabricatedSections,
            rolledSections,
            combinedSections,
            userDefinedElbows,
            controls,
            scene,
            selectedProject
        );
        if (!isContentCreated) {
            console.error("Failed to create DDD file. Aborting update operation.");
            return;
        }
    
        console.log("this is the selected project", selectedProject);
        const selectedProj = projects.find(proj => proj.projectName === selectedProject);
        const projectId = selectedProj ? selectedProj.projectId : null;
    
        if (projectId === null) {
            dispatch(
                addEventAction("Project does not exist.", "warning")
            );
            return;
        }
    
        try {
            const response = await Axios.put(`${secondServerAPI}rest/api/v1/layouts/updateProjectFile`, content, {
                headers: {
                    // "user-id": 1,
                    "user-id": auth.User_id,
                    "project-id": projectId,
                    "file-name": inputFileName,
                },
            });
    
            if (response.status === 200) {
                console.log("Projects data", response.data);
                dispatch(
                    addEventAction("Project Data Updated", "success")
                );
            } else if (response.status === 400) {
                console.log("Error", response);
            }
        } catch (err: any) {
            dispatch(
                addEventAction("No Project Found", "danger")
            );
        }
    }
    




    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/userandprojects`, {
                    headers: {
                        // "user-id": 1,
                        "user-id": auth.User_id,
                    },
                });
                if (response.status === 200) {
                    const projectsData = response.data.projectResponses.map((proj: any) => ({
                        projectId: proj.projectId,
                        projectName: proj.projectName,
                        projectPhase: proj.projectPhase,
                    }));
                    setProjects(projectsData);
                    if (projectsData.length > 0) {
                        setSelectedProject(projectsData[0].projectName);
                    }
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
    
        fetchProjects();
    }, []);
    
    
    useEffect(() => {
        console.log("This is the updated equipment list:", ude);
    }, [ude]);

    useEffect(() => {
        const fetchUDEFiles = async () => {
            const selectedProj = projects.find(proj => proj.projectName === selectedProject);
            const projectId = selectedProj ? selectedProj.projectId : null;
            if (!projectId) return; 
            
            try {
                const response = await Axios.get(`${secondServerAPI}/rest/api/v1/udc/getequipments`, {
                    headers: {
                        "project-id": projectId,
                        "Cache-Control": "no-cache",
                        "Pragma": "no-cache",
                        "Expires": "0",
                    },
                });
    
                if (response.status === 200) {
                    const equipmentList = response.data.map(equip => ({
                        fileId: equip.fileId,
                        fileName: equip.fileName,
                    }));
                    setUde(equipmentList);
                    setIsDataLoaded(true); 
                    console.log("This is the equipment list:", equipmentList);
                }
            } catch (error) {
                console.error("Error fetching UDE files:", error);
            }
        };
    
        if (selectedProject) {
            fetchUDEFiles();
        }
    }, [selectedProject, projects]);
    
    
    // async function checkData() {
    //     const fetchData = async () => {
    //         try {
    //             const selectedProj = projects.find(proj => proj.projectName === selectedProject);
    //             const projectId = selectedProj ? selectedProj.projectId : null;
    //             const response = await Axios.get(`${secondServerAPI}/rest/api/v1/udc/getequipments`, {
    //                 headers: {
    //                     "project-id": projectId,
    //                     "Cache-Control": "no-cache", 
    //                     "Pragma": "no-cache",        
    //                     "Expires": "0",             
    //                 },
    //             });
    
    //             if (response.status === 200) {
    //                 const equipmentList = response.data.map(equip => ({
    //                     fileId: equip.fileId,
    //                     fileName: equip.fileName,
    //                 }));
    //                 setUde(equipmentList);
    //                 console.log("This is the equipment list:", equipmentList); 
    //             }
    //         } catch (error) {
    //             console.log("This is the error", error);
    //         }
    //     };
    //     fetchData();
    // }

    const { onClose } = props;

    return (
        <>
            <CustomDlg
                title="Upload Project"
                body={
                    <div className="d-flex vertical" style={{ flexDirection: "column", padding: "5px" }}>
                        <div className="d-flex" style={{ margin: "5px" }}>
                            <div style={{ margin: 5, color: "white", fontSize: 14 }}>Project Available:</div>
                            <select
                                className="bp3-select w-100"
                                value={selectedProject}
                                onChange={(e) => setSelectedProject(e.target.value)}
                            >
                                {projects.map((proj) => (
                                    <option key={proj.projectId} value={proj.projectName}>
                                        {proj.projectName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex" style={{ margin: "5px" }}>
                            <div style={{ margin: 5, color: "white", fontSize: 14 }}>Enter File Name:</div>
                            <input
                                type="text"
                                value={inputFileName}
                                onChange={(e) => setInputFileName(e.target.value)}
                            />
                        </div>
                        <div className="dflex" style={{ justifyContent: "center" }}>
                            <Button text="Update" className="bp3-intent-primary bp3-large  bp3-icon-updated table-area-button" onClick={handleProjectUpdate} />
                            <Button text="Upload" className="bp3-intent-success bp3-large bp3-icon-cloud-upload table-area-button" onClick={handleSaveProjectFile} />
                        </div>
                    </div>
                }
                onClose={onClose}
            />
        </>
    );
}
