import React, { FunctionComponent, useEffect, useState } from "react";
import MenuButton from "../MenuButton";
import { Button, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import ProductSortingSimulationDlg from "../3d-modeling/product-sorting-simulation/ProductSortingSimulationDlg";
import { calculateShortestPath } from "../3d-modeling/product-sorting-simulation/helper/shortestPossiblePath";
import { createPSSAction, switchPauseResumeAction, switchPSSAnimationAction } from "../../../store/pss/actions";
import { createHumans, drawHumanPath } from "../../3d-models/pss/humanModeling";
import { getNextUniqueId } from "../3d-modeling/product-sorting-simulation/operatorRoutes/operatorRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentProject, getCurrentPSS } from "../../3d-models/utils";
import { ApplicationState } from "../../../store";

type Props = {};

type DIALOG = "PSS" | undefined;

const MobilityTab: FunctionComponent<Props> = () => {
  const [dlg2, setDlg2] = useState<DIALOG>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showAnimationOptions, setShowAnimationOptions] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();
	const [selectedRoutes, setSelectedRoutes] = useState<number[]>([]);
	const [activeSpeed, setActiveSpeed] = useState<number>(1);

	// const pss = useSelector((state: ApplicationState) => getCurrentPSS(state));
	const pssAnimation = useSelector(
		(state: ApplicationState) => state.pss.animate
	);
	const paused = useSelector((state: ApplicationState) => state.pss.paused);
	const threeD_scene = useSelector(
		(state: ApplicationState) => state.main.scene
	);
	const currentProject = useSelector((state: ApplicationState) =>
		getCurrentProject(state)
	);
	const pss = useSelector((state: ApplicationState) => {
		const data = getCurrentPSS(state);
		return data;
	});

	useEffect(() => {
		if (currentProject && !pss) {
			console.log("PSS is undefined, creating a new one...");
			dispatch(createPSSAction(currentProject.name));
		}
	}, [currentProject, pss, dispatch]);

	const currentSimulation = useSelector((state: ApplicationState) => {
		const simulation =
			state.pss?.simulations?.find(
				(sim) => sim.project === currentProject?.name
			) || {};
		return simulation;
	});

	const routes = currentSimulation.routes || [];
	console.log("Routes Data:", routes);

	const tasks = currentSimulation.tasks || [];
	console.log("Tasks Data:", tasks);

	const operators = currentSimulation.humans || [];
	console.log("Operators Data:", operators);

	const factoryPaths = currentSimulation.factoryPaths || [];
	console.log("Factory Paths Data:", factoryPaths);

  const handleTabClick = (tab: number, dialog: DIALOG) => {
    setSelectedTab(tab);
    setDlg2(dialog);
  };

  const handleCloseDlg = () => {
    setDlg2(undefined);
  };

  const toggleAnimationOptions = () => {
    setShowAnimationOptions(!showAnimationOptions);
  };

  const handleSpeed = (speed: number) => {
    setActiveSpeed(speed);
    const humanGroup = threeD_scene.getObjectByName("HUMAN");
    if (humanGroup) {
        humanGroup.traverse((child) => {
            if (child.userData && typeof child.userData === "object") {
                child.userData.speedMultiplier = speed;
            }
        });
    }
    console.log(`Speed set to: ${speed}`);
};


  function pointsAreEqual(p1, p2, epsilon = 1e-1) {
		return (
			Math.abs(p1.x - p2.x) < epsilon &&
			Math.abs(p1.y - p2.y) < epsilon &&
			Math.abs(p1.z - p2.z) < epsilon
		);
	}

  function resetObjects2(scene) {
    scene.children.slice().forEach((child) => {
        if (
            child.name.startsWith("HUMAN") || 
            child.name.startsWith("HumanPath") || 
            child.userData.type === "path-related"
        ) {
            scene.remove(child);
            if (child.geometry) child.geometry.dispose();
            if (child.material) child.material.dispose();
        }
    });
    console.log("Scene reset: all humans and paths cleared.", scene);
}

const handleCalculateShortestPath = async () => {
  console.log("Resetting scene before calculating shortest path...");
  resetObjects2(threeD_scene);

  const factoryPathPoints = factoryPaths.map((fp) => ({
      id: fp.id,
      description: fp.description,
      x: fp.x,
      y: fp.y,
      z: fp.z,
  }));

  try {
      const allOperatorIds = operators.map((op) => op.id);

      for (const route of routes) {
          route.operator = route.operator || [];
          route.waypoints = route.waypoints || [];

          if (route.operator.length === 0 || route.waypoints.length === 0) {
              console.warn(
                  `Route ${route.id} has no operator or waypoints assigned.`
              );
              continue;
          }

          const visitPoints = route.waypoints.map((wp) => ({
              x: wp.x,
              y: wp.y,
              z: wp.z,
          }));

          const result = calculateShortestPath(factoryPathPoints, visitPoints);

          route.operator.forEach((operatorId) => {
              const operator = operators.find((op) => op.id === operatorId);
              if (!operator) {
                  console.warn(`Operator with id ${operatorId} not found.`);
                  return;
              }

              const updatedPath = result.path.map((point) => {
                  const matchingWaypoint = route.waypoints.find((wp) =>
                      pointsAreEqual(point, { x: wp.x, y: wp.y, z: wp.z })
                  );

                  if (matchingWaypoint) {
                      console.log(
                          `Point ${JSON.stringify(
                              point
                          )} matched with waypoint ${JSON.stringify(
                              matchingWaypoint
                          )}, assigning wait time: ${matchingWaypoint.duration}`
                      );
                  } else {
                      console.log(
                          `Point ${JSON.stringify(point)} has no matching waypoint.`
                      );
                  }

                  return {
                      id: getNextUniqueId(),
                      x: point.x,
                      y: point.y,
                      z: point.z,
                      waitTime: matchingWaypoint ? matchingWaypoint.duration || 0 : 0,
                  };
              });

              operator.path = updatedPath;
              console.log(`Assigned calculated path to operator ${operator.name}`);
          });
      }

      const assignedOperators = routes.flatMap((route) => route.operator);
      const unassignedOperators = allOperatorIds.filter(
          (id) => !assignedOperators.includes(id)
      );

      const defaultPath = [
          { id: getNextUniqueId(), x: 0, y: 0, z: 0, waitTime: 0 },
      ];
      unassignedOperators.forEach((operatorId) => {
          const operator = operators.find((op) => op.id === operatorId);
          if (operator) {
              operator.path = defaultPath;
              console.log(
                  `Assigned default path to unassigned operator ${operator.name}`
              );
          }
      });

      dispatch(switchPSSAnimationAction(false));
      dispatch(switchPSSAnimationAction(true));

      createHumans(pss, threeD_scene).then((humanGroup) => {
          threeD_scene.add(humanGroup);
          console.log("Humans re-added to the scene.");

          drawHumanPath(operators, threeD_scene);
      });

      alert("Shortest paths calculated for all routes.");
  } catch (error) {
      console.error("Error calculating shortest path:", error);
      alert("An error occurred while calculating the shortest path.");
  }
};



  // const handleCalculateShortestPath = async () => {
	// 	console.log("Resetting scene before calculating shortest path...");
	// 	resetObjects2(threeD_scene);

	// 	const factoryPathPoints = factoryPaths.map((fp) => ({
	// 		id: fp.id,
	// 		description: fp.description,
	// 		x: fp.x,
	// 		y: fp.y,
	// 		z: fp.z,
	// 	}));

	// 	try {
	// 		const allOperatorIds = operators.map((op) => op.id);

	// 		for (const route of routes) {
	// 			route.operator = route.operator || [];
	// 			route.waypoints = route.waypoints || [];

	// 			if (route.operator.length === 0 || route.waypoints.length === 0) {
	// 				console.warn(
	// 					`Route ${route.id} has no operator or waypoints assigned.`
	// 				);
	// 				continue;
	// 			}

	// 			const visitPoints = route.waypoints.map((wp) => ({
	// 				x: wp.x,
	// 				y: wp.y,
	// 				z: wp.z,
	// 			}));

	// 			const result = calculateShortestPath(factoryPathPoints, visitPoints);

	// 			route.operator.forEach((operatorId) => {
	// 				const operator = operators.find((op) => op.id === operatorId);
	// 				if (!operator) {
	// 					console.warn(`Operator with id ${operatorId} not found.`);
	// 					return;
	// 				}

	// 				const updatedPath = result.path.map((point) => {
	// 					const matchingWaypoint = route.waypoints.find((wp) =>
	// 						pointsAreEqual(point, { x: wp.x, y: wp.y, z: wp.z })
	// 					);

	// 					if (matchingWaypoint) {
	// 						console.log(
	// 							`Point ${JSON.stringify(
	// 								point
	// 							)} matched with waypoint ${JSON.stringify(
	// 								matchingWaypoint
	// 							)}, assigning wait time: ${matchingWaypoint.duration}`
	// 						);
	// 					} else {
	// 						console.log(
	// 							`Point ${JSON.stringify(point)} has no matching waypoint.`
	// 						);
	// 					}

	// 					return {
	// 						id: getNextUniqueId(),
	// 						x: point.x,
	// 						y: point.y,
	// 						z: point.z,
	// 						waitTime: matchingWaypoint ? matchingWaypoint.duration || 0 : 0,
	// 					};
	// 				});

	// 				operator.path = updatedPath;
	// 				console.log(`Assigned calculated path to operator ${operator.name}`);
	// 			});
	// 		}

	// 		const assignedOperators = routes.flatMap((route) => route.operator);
	// 		const unassignedOperators = allOperatorIds.filter(
	// 			(id) => !assignedOperators.includes(id)
	// 		);

	// 		const defaultPath = [
	// 			{ id: getNextUniqueId(), x: 0, y: 0, z: 0, waitTime: 0 },
	// 		];
	// 		unassignedOperators.forEach((operatorId) => {
	// 			const operator = operators.find((op) => op.id === operatorId);
	// 			if (operator) {
	// 				operator.path = defaultPath;
	// 				console.log(
	// 					`Assigned default path to unassigned operator ${operator.name}`
	// 				);
	// 			}
	// 		});

	// 		dispatch(switchPSSAnimationAction(false));
	// 		dispatch(switchPSSAnimationAction(true));

	// 		createHumans(pss, threeD_scene).then((humanGroup) => {
	// 			threeD_scene.add(humanGroup);
	// 			console.log("Humans re-added to the scene.");

	// 			drawHumanPath(operators, threeD_scene);
	// 		});

	// 		alert("Shortest paths calculated for all routes.");
	// 	} catch (error) {
	// 		console.error("Error calculating shortest path:", error);
	// 		alert("An error occurred while calculating the shortest path.");
	// 	}
	// };

  const handleStartAnimation = () => {
    if (pssAnimation) {
			console.log("Resetting animations...");
			resetObjects2(threeD_scene);
			dispatch(switchPauseResumeAction(false));
		}

		console.log(`${pssAnimation ? "Resetting" : "Starting"} animations`);
		dispatch(switchPSSAnimationAction(!pssAnimation));

		createHumans(pss, threeD_scene).then((humanGroup) => {
			threeD_scene.add(humanGroup);
			console.log("Humans re-added to the scene after reset.");
		});
  };

  const handlePauseAnimation = () => {
    console.log("Pause Animation clicked");
    // Trigger the pause animation function
  };

  const handleResume = () => {
		console.log(`${paused ? "Resuming" : "Pausing"} animations`);
		dispatch(switchPauseResumeAction(!paused));
		const humanGroup = threeD_scene.getObjectByName("HUMAN");
		if (humanGroup) {
			humanGroup.userData.paused = !paused;
		}
	};

  return (
    <>
      {dlg2 === "PSS" && (
        <ProductSortingSimulationDlg
          onClose={handleCloseDlg}
          selected={selectedTab}
          onlyShowSelected={true}
        />
      )}
      <div className="d-flex">
        <MenuButton
          id="factory-path-button"
          text="Factory Path"
          bpIcon={<Icon size={24} icon={IconNames.PATH} />}
          onClick={() => handleTabClick(7, "PSS")}
        />
        <MenuButton
          id="task-button"
          text="Waypoints"
          bpIcon={<Icon size={24} icon={IconNames.LIST} />}
          onClick={() => handleTabClick(5, "PSS")}
        />
        <MenuButton
          id="egv-button"
          text="Operators"
          bpIcon={<Icon size={24} icon={IconNames.PEOPLE} />}
          onClick={() => handleTabClick(4, "PSS")}
        />
        <MenuButton
          id="route-button"
          text="Routes"
          bpIcon={<Icon size={24} icon={IconNames.ROUTE} />}
          onClick={() => handleTabClick(6, "PSS")}
        />
        <MenuButton
          id="result-button"
          text="Simulation Result"
          bpIcon={<Icon size={24} icon={IconNames.RESOLVE} />}
          onClick={() => handleTabClick(8, "PSS")}
        />
        <MenuButton
          id="animations-button"
          text="Animations"
          bpIcon={<Icon size={24} icon={IconNames.FILM} />}
          onClick={toggleAnimationOptions}
        />
      </div>
      {showAnimationOptions && (
        <div className="d-flex flex-column mt-2">
          <MenuButton
            id="prepare-animation-button"
            text="Prepare Animation"
            onClick={handleCalculateShortestPath}
          />
          <MenuButton
            id="start-animation-button"
            text={`${pssAnimation ? "Reset" : "Start"} animations`}
            onClick={handleStartAnimation}
          />
          <MenuButton
            text={`${paused ? "Resume" : "Pause"} animations`}
            onClick={handleResume}
          />
          {pssAnimation && (
              <>
                  <Button
                      small
                      icon={
                          <img
                              src="/icons/walk.png"
                              alt="Walk"
                              style={{
                                  width: "22px",
                                  height: "22px",
                                  filter: "brightness(0) invert(1)",
                              }}
                          />
                      }
                      intent={activeSpeed === 1 ? "primary" : "warning"}
                      onClick={() => handleSpeed(1)}
                  />
                  <Button
                      small
                      icon={
                          <img
                              src="/icons/run.png"
                              alt="Run"
                              style={{
                                  width: "22px",
                                  height: "22px",
                                  filter: "brightness(0) invert(1)",
                              }}
                          />
                      }
                      intent={activeSpeed === 2 ? "primary" : "warning"}
                      onClick={() => handleSpeed(2)}
                  />
                  <Button
                      small
                      icon={
                          <img
                              src="/icons/runer-silhouette-running-fast.png"
                              alt="Fast"
                              style={{
                                  width: "22px",
                                  height: "22px",
                                  filter: "brightness(0) invert(1)",
                              }}
                          />
                      }
                      intent={activeSpeed === 8 ? "primary" : "warning"}
                      onClick={() => handleSpeed(8)}
                  />
              </>
          )}

        </div>
      )}
    </>
  );
};

export default MobilityTab;
