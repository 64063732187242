import { Button } from "@blueprintjs/core";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  exportToCSV,
  getCurrentProject,
  importFromCSV,
  roundM,
} from "../../components/3d-models/utils";
import { SimpleSelector } from "../../components/common/SimpleSelector";
import { getFlaresMTO } from "../../components/menu-bar/3d-modeling/MTO/flare/FlareSyncMTO";
import { getOpenFramesMTO } from "../../components/menu-bar/3d-modeling/MTO/open-frame/OFSectionsSyncMTO";
import { getPipeRacksMTO } from "../../components/menu-bar/3d-modeling/MTO/pipe-rack/PRSectionsSyncMTO";
import { getPipeAccessoriesSyncMTO } from "../../components/menu-bar/3d-modeling/MTO/pipe/AccessoriesSyncMTO";
import { getPipesSyncMTO } from "../../components/menu-bar/3d-modeling/MTO/pipe/LinesSyncMTO";
import { ApplicationState } from "../../store";
import { TOpenFrame } from "../../store/main/openFrameTypes";
import { PipeRack, Project} from "../../store/main/types";
import { TProcess } from "../../store/process/types";
import "./dashboard_css/MTO.css";
import {Line} from "react-chartjs-2";
import 'chartjs-plugin-zoom';
import { getProcessSyncMTO } from "../../components/menu-bar/3d-modeling/MTO/pipe/ProcessLineMTO";
import { secondServerAPI } from "../utils/agent";
import axios from "axios";
import { getJSONForConnections } from "../../components/3d-models/designCodeAndParametersOF";
import { title } from "process";

type TMTO =
  | "All"
  | "Pipe Lines"
  | "Pipe Accessories"
  | "Flares"
  | "Pipe Racks"
  | "Open Frames"
  | "Factory Sheds"
  | "Connections"
  | "Assets";

type TSectionMTO = {
  model: string;
  designation: string;
  material: string;
  nos: number;
  length: number;
  weight: number;
};

type TChartData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    hoverBackgroundColor: string;
    hoverBorderColor: string;
  }[];
};

type TchartPipedata =  {
  length: number;
  weight: number;
  material: string;
  structure: string;
  line: string;
  tag: string;
  size: string;
};

type TchartPipeAccessoryData = {
  weight: number;
  line: string;
  tag: string;
  structure: string;
  size: string;
  type: string;
  schedule?: string | undefined;
  class?: string | number | undefined;
  nos?: number | undefined;
};

export default function MTOContainer() {
  const [mto, setMTO] = useState<TMTO>("");
  const project = useSelector((state: ApplicationState) =>
    getCurrentProject(state)
  );

  return (
    <>
      <div className="header-container">
        <div className="header-label">material take off</div>
        <SimpleSelector<TMTO>
          items={[
            "All",
            "Pipe Lines",
            "Pipe Accessories",
            "Flares",
            "Pipe Racks",
            "Open Frames",
            "Factory Sheds",
            "Connections",
            "Assets",
          ]}
          selected={mto}
          onSelect={setMTO}
          clearable={true}
          itemLabel={(item) => item}
          className={"w-200"}
        />
      </div>
      <div className="hr" />
      {getMTOTable(project,mto)}
    </>
  );
}

function getMTOTable(project: Project | undefined, type: TMTO) {
  if (!project) return null;

  const PRs = project?.models.filter((m) => m.type === "Pipe Rack") as PipeRack[];
  const OFs = project?.models.filter((m) => m.type === "Open Frame") as TOpenFrame[];
  const FSs = project?.models.filter((m) => m.type === "Factory Shed") as TOpenFrame[];

  switch (type) {
    case "Pipe Lines":
      return <div key="pipe-lines">{getPipeLinesMTO(project)}</div>;
    case "Pipe Accessories":
      return <div key="pipe-accessories">{getPipesAccessoriesMTO(project)}</div>;
    case "Flares":
      return <div key="flares">{getFlareMTO(project)}</div>;
    case "Pipe Racks":
      return <div key="pipe-racks">{getPipeRackMTO(PRs)}</div>;
    case "Open Frames":
      return <div key="open-frames">{getOpenFrameMTO(OFs)}</div>;
    case "Factory Sheds":
      return <div key="factory-sheds">{getFactoryShedsMTO(FSs)}</div>;
    case "Connections":
      return <div key="connections">{getConnectionsMTO(project)}</div>;
    case "Assets":
      return <div key="assets">{renderAssetsMTO(getAssetsMTO(project))}</div>; // Use `renderAssetsMTO`
    default:
      return (
        <>
          <div className="primary-mto-container">
            {project.freePipes?.length ? (
              <>
                <div key="default-pipe-lines">{getPipeLinesMTO(project)}</div>
                <div key="default-pipe-accessories">{getPipesAccessoriesMTO(project)}</div>
              </>
            ) : null}
            {project.flares?.length ? <div key="default-flares">{getFlareMTO(project)}</div> : null}
            {PRs.length ? <div key="default-pipe-racks">{getPipeRackMTO(PRs)}</div> : null}
            {OFs.length ? <div key="default-open-frames">{getOpenFrameMTO(OFs)}</div> : null}
            {FSs.length ? <div key="default-factory-sheds">{getFactoryShedsMTO(FSs)}</div> : null}
            <div key="connections">{getConnectionsMTO(project)}</div>
            <div key="assets">{renderAssetsMTO(getAssetsMTO(project))}</div> 
            <div></div>
          </div>
        </>
      );
  }
}

function renderAssetsMTO(mto: ClassifiedMTO) {
  return (
    <div>
      <h3>Assets MTO</h3>
      <table className="MTO-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>ID</th>
            <th>Name</th>
            <th>Material</th>
            <th>Weight (kg)</th>
            <th>Position (X, Y, Z)</th>
            <th>Dimensions</th>
          </tr>
        </thead>
        <tbody>
          {renderCategoryRows('Walls', mto.walls)}
          {renderCategoryRows('Slabs', mto.slabs)}
          {renderCategoryRows('Doors', mto.doors)}
          {renderCategoryRows('Windows', mto.windows)}
          {renderCategoryRows('Equipment', mto.equipment)}
          <tr>
            <td colSpan={6}>
              <strong>Total Weight:</strong>
            </td>
            <td>{mto.totalWeight.toFixed(2)} kg</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function renderCategoryRows(categoryName: string, items: MTOItem[]) {
  if (!items.length) return null;
  return items.map((item, index) => (
    <tr key={`${categoryName}-${index}`}>
      <td>{categoryName}</td>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.material}</td>
      <td>{item.weight.toFixed(2)}</td>
      <td>{`${item.position.x}, ${item.position.y}, ${item.position.z}`}</td>
      <td>
        {item.dimensions.height && `H: ${item.dimensions.height}, `}
        {item.dimensions.width && `W: ${item.dimensions.width}, `}
        {item.dimensions.length && `L: ${item.dimensions.length}, `}
        {item.dimensions.thickness && `T: ${item.dimensions.thickness}`}
      </td>
    </tr>
  ));
}


function fixValuesFotExport(val: any) {
  if (!val) return ` `;
  return ` ${val}`;
}

function createLabel(line: string | undefined, size: string | undefined, length: number | undefined): string | null {
  if (line || size || length) {
    return `${line || ''} || ${size || ''} || ${length || ''}`.trim();
  }
  return null; 
}

function getPipeLinesMTO(project: Project) {
  const data = getPipesSyncMTO(project.freePipes ?? []);
  const [data_1, setData_1] = useState<TchartPipedata[]>([]); 
  const chartData = {
    labels: data.map(item => `${item.tag} - ${item.line}`), 
    display:{
      Boolean: false,
    },
    datasets: [{
      label: 'Weight (kg)',
      data: data.map(item => item.weight), 
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
      hoverBackgroundColor: 'rgba(255, 204, 203, 0.9)',
      hoverBorderColor: 'rgba(255, 100, 0, 1)'
    }]
  };
  
  const labelsData = data.map(item => createLabel(item.line, item.size, item.length)).filter(label => label !== null);
  const labelsData_1 = data_1.map(item => createLabel(item.line, item.size, item.length)).filter(label => label !== null);

  const allLabels = Array.from(new Set([...labelsData, ...labelsData_1]));
  
  const dataset1Data = allLabels.map(label => {
    const item = data.find(item => `${item.line} || ${item.size} || ${item.length}` === label);
    return item ? item.weight : 0;  
  });
  
  const dataset2Data = allLabels.map(label => {
    const item = data_1.find(item => `${item.line} || ${item.size} || ${item.length}` === label);
    return item ? item.weight : 0;  
  });
  

  const chartData_1 = {
    labels: allLabels,
    datasets: [
        {
            label: 'Layout-1 ',
            // data: data.map(item => (item.weight)),
            data : dataset1Data,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            hoverBackgroundColor: 'rgba(255, 204, 203, 0.9)',
            hoverBorderColor: 'rgba(255, 100, 0, 1)'
        },
        {
            label: 'Layout-2',
            // data: data_1.map(item => (item.weight)),
            data : dataset2Data, 
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.9)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)'
        }
    ]
  };

  const options = {
    scales: {
      yAxes: [
        {
            beginAtZero: true,
            ticks: {
                callback: function(value: number, index: number, values: number[]) {
                    return value;
                },
                fontColor: '#000', 
                fontWeight: 'bold',
                fontSize: 14 
            },
            scaleLabel: {
                display: true,
                labelString: 'Kilogram (Kg)',
                fontColor: '#000',  
                fontWeight: 'bold',
                fontSize: 22  
            }
        }
    ],
    xAxes: [
      {
          beginAtZero: true,
          ticks: {
              callback: function(value: number, index: number, values: number[]) {
                  return value;
              },
              fontColor: '#000', 
              fontWeight: 'bold',
              fontSize: 14 
          },
          scaleLabel: {
              display: true,
              labelString: 'Line No. || Size || Length',
              fontColor: '#000',  
              fontWeight: 'bold',
              fontSize: 22  
          }
      }
  ],
      display:{
        Boolean: true,
      },
    },
    animation : {
      duration : 4000,
    },
    legend: {
      labels: {
        fontColor: 'rgba(0, 0, 0, 1)',
        fontSize: 22,
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pipe Lines MTO'
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
          speed: 0.5,
        },
        zoom: {
          enabled: false,
          wheel : {
            enabled: true,
          },
          drag: {
                borderColor: 'rgba(225,225,225,0.3)',
                borderWidth: 5,
                backgroundColor: 'rgb(225,225,225)',
                animationDuration: 0
          },
          mode: 'xy',
          speed: 0.5,
          threshold: 2,
          sensitivity: 3,
      } 
      }
  }
  };

  
  return (
    <>
      <div className="MTO-header">
        <span className="MTO-title">Pipe Lines</span>
        <span className="MTO-title"></span>
        <Button
          small
          icon="export"
    
          text="export to CSV"
          className="mto-export-button"
          onClick={() => {
            exportToCSV(
              [
                ...data.map((item) => {
                  return {
                    tag: item.tag,
                    "line": item.line,
                    structures: item.structure,
                    size: fixValuesFotExport(item.size),
                    material: item.material,
                    "length": fixValuesFotExport(item.length),
                    "weight": fixValuesFotExport(item.weight),
                  };
                }),
                {
                  Tag: "Total",
                  "Line No.": ` `,
                  Structures: ` `,
                  Size: ` `,
                  Material: ` `,
                  "Length (m)": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.length, 0))
                  ),
                  "Weight (kg)": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                },
              ],
              "Pipe Lines MTO"
            );
          }}
        />
        <Button 
          small
          icon="import"
      
          className="mto-export-button"
          text="import from CSV"
          onClick={() => {
            importFromCSV((rows) => {
              if (!Array.isArray(rows)) { 
                alert("CSV file is empty!");
              } else {
                setData_1(rows);
              }
            })
          }}
        />
      </div>
      <div className="MTO-hr" />
        <table className="MTO-table">
        <thead>
          <tr>
          <th>tag</th>
          <th>line no.</th>
          <th>structures</th>
          <th>size</th>
          <th>material</th>
          <th>length (m)</th>
          <th>weight (kg)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td>{item.tag}</td>
              <td >{item.line}</td>
              <td >{item.structure}</td>
              <td >{item.size}</td>
              <td >{item.material}</td>
              <td >{item.length}</td>
              <td >{item.weight}</td>
            </tr>
          ))}
          <tr>
            <td>
              <strong>Total</strong>
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              {roundM(data.reduce((acc, row) => acc + row.length, 0))}
            </td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.weight, 0))}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="p-5"
          style={{
            position: "relative",
            paddingTop: 50,
            paddingBottom: 50,
            paddingLeft: "10vw",
            paddingRight: "10vw",
            overflow: "hidden",
            background: "white",
            minHeight: "calc(43vw + 0px)",
            maxHeight: "calc(80vw + 0px)",
            minWidth: "calc(80vw + 0px)",
          }}
      >
      <Line options={options} data={chartData_1}/>
      </div>
    </>
  );
}

function getPipesAccessoriesMTO(project: Project) {
  const data = getPipeAccessoriesSyncMTO(project.freePipes ?? []);
  const [data_2, setData_2] = useState([]);
  const [selectedType, setSelectedType] = useState('All');

  function createLabel(line: string | undefined, size: string | undefined, type: string | undefined): string | null {
    if (line || size || type) {
      return `${line || ''} || ${size || ''} || ${type || ''}`.trim();
    }
    return null; 
  }
  
  const filteredData = selectedType === 'All' ? data : data.filter(item => item.type === selectedType);
  const filteredData_2 = selectedType === 'All' ? data_2 : data_2.filter(item => item.type === selectedType);

  const labelsData = filteredData.map(item => createLabel(item.line, item.size, item.type)).filter(label => label !== null);
  const labelsData_1 = filteredData_2.map(item => createLabel(item.line, item.size, item.type)).filter(label => label !== null);

  const allLabels = Array.from(new Set([...labelsData, ...labelsData_1]));

  const dataset1Data = allLabels.map(label => {
    const item = filteredData.find(item => `${item.line} || ${item.size} || ${item.type}` === label);
    return item ? item.weight : 0; 
  });
  
  const dataset2Data = allLabels.map(label => {
    const item = filteredData_2.find(item => `${item.line} || ${item.size} || ${item.type}` === label);
    return item ? item.weight : 0;
  }); 

  const chartData_3 = {
    labels: allLabels,
    datasets: [
        {
            label: 'Layout 1',
            data: dataset1Data,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 3,
            hoverBackgroundColor: 'rgba(255, 204, 203, 0.9)',
            hoverBorderColor: 'rgba(255, 100, 0, 1)'
        },
        {
            label: 'Layout 2',
            data: dataset2Data, 
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 3,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.9)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)'
        }
    ]
  };
  const options = {
    scales: {
      yAxes: [
        {
            beginAtZero: true,
            ticks: {
                callback: function(value) {
                    return value;
                },
                fontColor: '#000', 
                fontWeight: 'bold',
                fontSize: 14 
            },
            scaleLabel: {
                display: true,
                labelString: 'Weight (Kg)',
                fontColor: '#000',  
                fontWeight: 'bold',
                fontSize: 22  
            }
        }
    ],
    xAxes: [
      {
          beginAtZero: true,
          ticks: {
              callback: function(value) {
                  return value;
              },
              fontColor: '#000', 
              fontWeight: 'bold',
              fontSize: 14 
          },
          scaleLabel: {
              display: true,
              labelString: 'Line No. || Size  || Type',
              fontColor: '#000',  
              fontWeight: 'bold',
              fontSize: 22  
          }
      }
  ],
      display:{
        Boolean: false,
      },
    },
    legend: {
      labels: {
        fontColor: 'rgba(0, 0, 0, 1)',
        fontSize: 18,
      }
    },
    animation : {
      duration : 10000,
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pipe Accessories MTO'
      }
    }
  }

  const uniqueTypes = Array.from(new Set(data.map(item => item.type)));

  return (
    <>
      <div className="MTO-header">
        <span className="MTO-title">Pipes Accessories</span>
        <select className={"w-250 selector-secondary-b1l"} onChange={(e) => setSelectedType(e.target.value)} value={selectedType}>
          <option value="All">All</option>
          {uniqueTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>
        <Button
          small
          icon="export"
  
          text="export to CSV"
          className="mto-export-button"
          onClick={() => {
            exportToCSV(
              [
                ...data.map((item) => {
                  return {
                    tag: item.tag,
                    "line": item.line,
                    structures: item.structure,
                    size: fixValuesFotExport(item.size),
                    type: item.type,
                    "Schedule / Class": item.schedule ?? ` `,
                    Nos: item.nos ?? 0,
                    "weight": fixValuesFotExport(item.weight),
                  };
                }),
                {
                  tag: "Total",
                  "line": ` `,
                  structures: ` `,
                  size: ` `,
                  type: ` `,
                  "Schedule / Class": ` `,
                  Nos: ` `,
                  "weight": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                },
              ],
              "Pipe Accessories MTO"
            );
          }}
        />
        <Button 
          small
          icon="import"
  
          className="mto-export-button"
          text="import from CSV"
          onClick={() => {
            importFromCSV((rows) => {
              if (!Array.isArray(rows)) { 
                alert("CSV file is empty!");
              } else {
                setData_2(rows);
              }
            })
          }}
        />
      </div>
      <div className="MTO-hr" />
        <table className="MTO-table">
        <thead>
          <tr>
            <th>tag</th>
            <th>line no.</th>
            <th>structures</th>
            <th>size</th>
            <th>type</th>
            <th>schedule / Class</th>
            <th>nos</th>
            <th>weight (kg)</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, i) => (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{item.tag}</td>
              <td style={{ textAlign: "center" }}>{item.line}</td>
              <td style={{ textAlign: "center" }}>{item.structure}</td>
              <td style={{ textAlign: "center" }}>{item.size}</td>
              <td style={{ textAlign: "center" }}>{item.type}</td>
              <td style={{ textAlign: "center" }}>
                {item.schedule ?? item.class}
              </td>
              <td style={{ textAlign: "center" }}>{item.nos}</td>
              <td style={{ textAlign: "center" }}>{item.weight}</td>
            </tr>
          ))}
          <tr>
            <td style={{ textAlign: "center" }}>
              <strong>Total</strong>
            </td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>
              {roundM(filteredData.reduce((acc, row) => acc + row.weight, 0))}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="p-5"
          style={{
            position: "relative",
            paddingTop: 50,
            paddingBottom: 50,
            paddingLeft: "10vw",
            paddingRight: "10vw",
            overflow: "hidden",
            background: "white",
            minHeight: "calc(43vw + 0px)",
            maxHeight: "calc(80vw + 0px)",
            minWidth: "calc(80vw + 0px)",
          }}
      >
      <Line options={options} data={chartData_3} />
      </div>
    </>
  );
}

function getFlareMTO(project: Project) {
  const data = getFlaresMTO(project.flares ?? []);
  return (
    <>
      <div className="MTO-header">
        <span className="MTO-title">Flares</span>
        {/* <span className="button-primary-b1l">30 Stage</span> */}
        <Button
          small
          icon="export"
          
          text="export to CSV"
          className="mto-export-button"
          onClick={() => {
            exportToCSV(
              [
                ...data.map((item) => {
                  return {
                    Model: item.flare,
                    Material: item.material,
                    "Weight (kg)": fixValuesFotExport(item.weight),
                  };
                }),
                {
                  Model: "Total",
                  Material: ` `,
                  "Weight (kg)": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                },
              ],
              "Flares MTO"
            );
          }}
        />
      </div>
      <div className="MTO-hr" />
        <table className="MTO-table">
        <thead>
          <tr>
            <th>Model</th>
            <th>Material</th>
            <th>Weight (kg)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{item.flare}</td>
              <td style={{ textAlign: "center" }}>{item.material}</td>
              <td style={{ textAlign: "center" }}>{item.weight}</td>
            </tr>
          ))}
          <tr>
            <td style={{ textAlign: "center" }}>
              <strong>Total</strong>
            </td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.weight, 0))}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

function getPipeRackMTO(models: PipeRack[]) {
  const data = getPipeRacksMTO(models);

  return (
    <>
      <div className="MTO-header">
        <span className="MTO-title">Pipe Racks MTO</span>
        {/* <span className="button-primary-b1l">30 Stage</span> */}
        <Button
          small
          icon="export"
          
          text="export to CSV"
          className="mto-export-button"
          onClick={() => {
            exportToCSV(
              [
                ...data.map((item) => {
                  return {
                    Model: item.model,
                    Designtion: item.designation,
                    Material: item.material,
                    "Length (m)": fixValuesFotExport(item.length),
                    "Weight (kg)": fixValuesFotExport(item.weight),
                  };
                }),
                {
                  Model: "Total",
                  Designtion: ` `,
                  Material: ` `,
                  "Length (m)": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                  "Weight (kg)": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                },
              ],
              "Pipe Racks MTO"
            );
          }}
        />
      </div>
      <div className="MTOhr" />
        <table className="MTO-table">
        <thead>
          <tr>
            <th>Model</th>
            <th>Designtion</th>
            <th>Material</th>
            <th>Length (m)</th>
            <th>Weight (kg)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{item.model}</td>
              <td style={{ textAlign: "center" }}>{item.designation}</td>
              <td style={{ textAlign: "center" }}>{item.material}</td>
              <td style={{ textAlign: "center" }}>{item.length}</td>
              <td style={{ textAlign: "center" }}>{item.weight}</td>
            </tr>
          ))}
          <tr>
            <td style={{ textAlign: "center" }}>
              <strong>Total</strong>
            </td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.length, 0))}
            </td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.weight, 0))}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

function getOpenFrameMTO(models: TOpenFrame[]) {
  const data = getOpenFramesMTO(models);
  const [data_2, setData_2] = useState<TSectionMTO[]>([]);
  const [chartData_2, setChartData_2] = useState<TChartData>({
    labels: [],
    datasets: [{
      label: 'Weight (kg)',
      data: [],
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
      hoverBackgroundColor: 'rgba(255, 204, 203, 0.9)',
      hoverBorderColor: 'rgba(255, 100, 0, 1)'
    }]
  });

  function createLabel(model: string | undefined, designation: string | undefined, material: string): string | null {
    if (model || designation || material) {
      return `${model || ''} || ${designation || ''} || ${material || ''}`.trim();
    }
    return null; 
  }
  
  const labelsData = data.map(item => createLabel(item.model, item.designation, item.material)).filter(label => label !== null);
  const labelsData_1 = data_2.map(item => createLabel(item.model, item.designation, item.material))
  .filter(label => label !== null && label.toLowerCase() !== "total" && label.split("||")[0].trim() !== "Total");
  const allLabels = Array.from(new Set([...labelsData, ...labelsData_1]));
  const dataset1Data = allLabels.map(label => {
    const item = data.find(item => `${item.model} || ${item.designation} || ${item.material}` === label);
    return item ? (item.weight/1000) : 0; 
  });
  const dataset2Data = allLabels.map(label => {
    const item = data_2.find(item => `${item.model} || ${item.designation} || ${item.material}` === label);
    return item ? (item.weight/1000) : 0;
  }); 
  const chartData_3 = {
    labels : allLabels,
    datasets: [
        {
            label: 'Layout 1',
            data: dataset1Data,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            hoverBackgroundColor: 'rgba(255, 204, 203, 0.9)',
            hoverBorderColor: 'rgba(255, 100, 0, 1)'
        },
        {
            label: 'Layout 2',
            //data: data_2.map(item => item.weight),
            data: dataset2Data, 
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.9)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)'
        }
    ]
};
  const options = {
    scales: {
      yAxes: [
        {
            beginAtZero: true,
            ticks: {
                callback: function(value: number, index: number, values: number[]) {
                    return value;
                },
                fontColor: '#000', 
                fontWeight: 'bold',
                fontSize: 14 
            },
            scaleLabel: {
                display: true,
                labelString: 'Metric Ton (t)',
                fontColor: '#000',  
                fontWeight: 'bold',
                fontSize: 22  
            }
        }
    ],
    xAxes: [
      {
          beginAtZero: true,
          ticks: {
              callback: function(value: number, index: number, values: number[]) {
                  return value;
              },
              fontColor: '#000', 
              fontWeight: 'bold',
              fontSize: 14 
          },
          scaleLabel: {
              display: true,
              labelString: 'Model No. || Designation || Material',
              fontColor: '#000',  
              fontWeight: 'bold',
              fontSize: 22  
          }
      }
  ],
      display: {
        Boolean: false,
      },
    },
    animation: {
      duration: 3000,
    },
    legend: {
      labels: {
        fontColor: 'rgba(0, 0, 0, 1)',
        fontSize: 18,
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Open Frame MTO'
      }
    }
  };

  return (
    <>
      <div className="MTO-header">
        <span className="MTO-title">Open Frames</span>
        {/* <span className="button-primary-b1l">30 Stage</span> */}
        <Button
          small
          icon="export"
          text="export to CSV"
          className="mto-export-button"
          onClick={() => {
            exportToCSV(
              [
                ...data.map((item) => {
                  return {
                    model: item.model,
                    designation: item.designation,
                    material: item.material,
                    "length": fixValuesFotExport(item.length),
                    "weight": fixValuesFotExport(item.weight),
                  };
                }),
                {
                  model: "Total",
                  designation: ` `,
                  material: ` `,
                  "length": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                  "weight": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                },
              ],
              "Open Frames MTO"
            );
          }}
        />
        <Button 
          small
          icon="import"
        
          className="mto-export-button"
          text="import from CSV"
          onClick={() => {
            importFromCSV((rows) => {
              if (!Array.isArray(rows)) { 
                alert("CSV file is empty!");
              } else {
                setData_2(rows);
              }
            })
          }}
        />
      </div>
      <div className="MTO-hr" />
      <table className="MTO-table">
        <thead>
          <tr>
            <th>Model</th>
            <th>Designtion</th>
            <th>Material</th>
            <th>Length (m)</th>
            <th>Weight (kg)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{item.model}</td>
              <td style={{ textAlign: "center" }}>{item.designation}</td>
              <td style={{ textAlign: "center" }}>{item.material}</td>
              <td style={{ textAlign: "center" }}>{item.length}</td>
              <td style={{ textAlign: "center" }}>{item.weight}</td>
            </tr>
          ))}
          <tr>
            <td style={{ textAlign: "center" }}>
              <strong>Total</strong>
            </td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.length, 0))}
            </td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.weight, 0))}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="p-5"
        style={{
          position: "relative",
          paddingTop: 50,
          paddingBottom: 50,
          paddingLeft: "10vw",
          paddingRight: "10vw",
          overflow: "hidden",
          background: "white",
          minHeight: "calc(43vw + 0px)",
          maxHeight: "calc(80vw + 0px)",
          minWidth: "calc(80vw + 0px)",
        }}
      >
        {/* <Polar options={chartOptions} data={chartData_2} /> */}
        <Line options={options} data={chartData_3} />
        {/* <Bar options={options} data={chartData} /> */}
      </div>
    </>
  );
}

function getFactoryShedsMTO(models: TOpenFrame[]) {
  const data = getOpenFramesMTO(models);

  return (
    <>
      <div className="MTO-header">
        <span className="MTO-title">Factory Sheds</span>
        {/* <span className="button-primary-b1l">30 Stage</span> */}
        <Button
          small
          icon="export"
      
          text="export to CSV"
          className="mto-export-button"
          onClick={() => {
            exportToCSV(
              [
                ...data.map((item) => {
                  return {
                    Model: item.model,
                    Designtion: item.designation,
                    Material: item.material,
                    "Length (m)": fixValuesFotExport(item.length),
                    "Weight (kg)": fixValuesFotExport(item.weight),
                  };
                }),
                {
                  Model: "Total",
                  Designtion: ` `,
                  Material: ` `,
                  "Length (m)": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                  "Weight (kg)": fixValuesFotExport(
                    roundM(data.reduce((acc, item) => acc + item.weight, 0))
                  ),
                },
              ],
              "Factory Sheds MTO"
            );
          }}
        />
      </div>
      <div className="MTO-hr" />
        <table className="MTO-table">
        <thead>
          <tr>
            <th>Model</th>
            <th>Designtion</th>
            <th>Material</th>
            <th>Length (m)</th>
            <th>Weight (kg)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{item.model}</td>
              <td style={{ textAlign: "center" }}>{item.designation}</td>
              <td style={{ textAlign: "center" }}>{item.material}</td>
              <td style={{ textAlign: "center" }}>{item.length}</td>
              <td style={{ textAlign: "center" }}>{item.weight}</td>
            </tr>
          ))}
          <tr>
            <td style={{ textAlign: "center" }}>
              <strong>Total</strong>
            </td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>-</td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.length, 0))}
            </td>
            <td style={{ textAlign: "center" }}>
              {roundM(data.reduce((acc, row) => acc + row.weight, 0))}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export type Data = {
  mto: {
    number_of_bolts: number,
    bolts: Record<string, { quantity: number, diameter: number }>,
    plates: Record<string, { area: number, thickness: number, material: string, mass: number }>,
    total_mass: number
  }
}

function getConnectionsMTO(project: Project) {
  const [data, setData] = useState<Data | null>(null);

  useEffect(() => {
    const formData = getJSONForConnections(project);
    const fetchData = async () => {
      try {
        const response = await axios.post<Data>(
          `${secondServerAPI}/api/v1/connection/mto/rectangularBasePlates`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data);
      } catch (err) {
        alert("The base Plate MTO could not be fetched please check the model data");
        console.error(err);
      }
    };

    fetchData();
  }, [project]);

  return (
    <>
    {data && (
      <div>
        <div className="MTO-header">
          <span className="MTO-title">Bolts</span>
        </div>
        <div className="MTO-hr" />
        <table className="MTO-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Quantity</th>
              <th>Diameter</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data.mto.bolts).map(([id, bolt]) => (
              <tr key={id}>
                <td style={{ textAlign: "center" }}>{id}</td>
                <td style={{ textAlign: "center" }}>{bolt.quantity}</td>
                <td style={{ textAlign: "center" }}>{bolt.diameter}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="MTO-header">
          <span className="MTO-title">Plates</span>
        </div>
        <div className="MTO-hr" />
        <table className="MTO-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Area</th>
              <th>Thickness</th>
              <th>Material</th>
              <th>Mass</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data.mto.plates).map(([id, plate]) => (
              <tr key={id}>
                <td style={{ textAlign: "center" }}>{id}</td>
                <td style={{ textAlign: "center" }}>{plate.area.toFixed(2)}</td>
                <td style={{ textAlign: "center" }}>{plate.thickness.toFixed(2)}</td>
                <td style={{ textAlign: "center" }}>{plate.material}</td>
                <td style={{ textAlign: "center" }}>{plate.mass.toFixed(2)}</td>
              </tr>
            ))}
            <td style={{ textAlign: "center" }}>Total</td>
            <td style={{ textAlign: "center" }}></td>
            <td style={{ textAlign: "center" }}></td>
            <td style={{ textAlign: "center" }}></td>
            <td style={{ textAlign: "center" }}>{data.mto.total_mass.toFixed(2)}</td>
          </tbody>
        </table>
      </div>
    )}
    </>
  );
}

type MTOItem = {
  id: string;
  name: string;
  material: string;
  dimensions: {
    height?: number;
    width?: number;
    length?: number;
    thickness?: number;
  };
  weight: number;
  position: {
    x: number;
    y: number;
    z: number;
  };
};

type ClassifiedMTO = {
  walls: MTOItem[];
  slabs: MTOItem[];
  doors: MTOItem[];
  windows: MTOItem[];
  equipment: MTOItem[];
  totalWeight: number;
};

function getAssetsMTO(project: Project): ClassifiedMTO {
  const initialMTO: ClassifiedMTO = {
    walls: [],
    slabs: [],
    doors: [],
    windows: [],
    equipment: [],
    totalWeight: 0,
  };

  if (!project || !Array.isArray(project.GLTFItems)) {
    console.error("Project or GLTF items are undefined or not an array.");
    return initialMTO;  
  }

  const classifiedMTO = project.GLTFItems.reduce((mto, item, index) => {
    if (!item) return mto;  

    const mtoItem: MTOItem = {
      id: `item_${index}`,
      name: item.name || `Unnamed_${index}`,
      material: item.material || "Unknown",
      dimensions: item.dimensions || {},
      weight: item.weight || 0,
      position: item.position || { x: 0, y: 0, z: 0 }, 
    };

    switch (item.Type?.toLowerCase()) {
      case "wall":
        mto.walls.push(mtoItem);
        break;
      case "slab":
        mto.slabs.push(mtoItem);
        break;
      case "door":
        mto.doors.push(mtoItem);
        break;
      case "window":
        mto.windows.push(mtoItem);
        break;
      case "equipment":
        mto.equipment.push(mtoItem);
        break;
      default:
        console.warn(`Unknown item type: ${item.Type}`);
        break;
    }

    mto.totalWeight += mtoItem.weight;

    return mto;
  }, initialMTO);

  return classifiedMTO;
}

