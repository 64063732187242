import React, { useMemo, useState, useEffect } from "react";
import { MenuItem, Navbar, Button, InputGroup, Popover, Position, Tooltip } from "@blueprintjs/core";
import { Menu } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeWorkModeAction } from "../../store/main/actions";
import { createProcessAction } from "../../store/process/actions";
import { createPSSAction } from "../../store/pss/actions";
import { ApplicationState } from "../../store";
import Logo from "../../assets/A-Logo-White-Transparent.png";
import MTOContainer from "../dashboard/MTOContainer";
import Dashboard from "../dashboard/Dashboard";
import SchedulePlaner from "../dashboard/SchedulePlaner";
import ProjectPlaner from "../dashboard/ProjectPlaner";
import ShowUserProfile from "../dashboard/profile/userProfile";
import "./NewModeSwitcher.css";
import DashboardHome from "../dashboard/dashboardHome/dashboardHome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { API_ROOT, secondServerAPI, secondServerAPILearning } from "../utils/agent";
import Axios from "axios";
import Team from "../dashboard/team/team";
import { logOutAction } from "../../store/auth/actions";
import { push } from "connected-react-router";
import OpenModelDlg from "../../components/menu-bar/project-tab/OpenModelDlg";
import { getCurrentProject, openGenericProject } from "../../components/3d-models/utils";
import { ModelType, TWorkMode } from "../../store/main/types";
import { RemoteFiles } from "../../components/menu-bar/help-tab/remote-files/RemoteFiles";
import { saveToPDF } from "../dashboard/dashboard-utils";
import { setUserRole } from "../../store/main/actions";
import { notification } from "antd";

export function newModeSwitcher() {
    const [input, setInput] = useState("");
    const [sidebarWidth, setSidebarWidth] = useState(250);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activeButton, setActiveButton] = useState("home");
    const [profilePhoto, setProfilePhoto] = useState<string>("https://www.w3schools.com/howto/img_avatar.png");
    const [userName, setUserName] = useState<string>("");
    const [dialog, setDialog] = useState<JSX.Element>();
    const [showHelpDropdown, setShowHelpDropdown] = useState<boolean>(false);
    const [isManager, setIsManager] = useState<boolean>(false);
    const [notifications, setNotifications] = useState([]);

    const history = useHistory();
    const dispatch = useDispatch();
    const currentProject = useSelector((state: ApplicationState) => state.main.currentProject);
    const products = useSelector((state: ApplicationState) => state.main.products ?? []);
    const isProduction = useMemo(() => process.env.NODE_ENV === "production", []);
    const auth = useSelector((state: ApplicationState) => state.auth);

    const project = useSelector((state: ApplicationState) =>
        getCurrentProject(state)
    );

    const projects = useSelector(
        (state: ApplicationState) => state.main.projects
    );

    const userRole_orig = useSelector((state: ApplicationState) => state.userRole);

    function handleNotificationClose() {
        setNotifications([]);
    }

    // const mode = useSelector((state: ApplicationState) => state.main.workMode);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const photoResponse = await Axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/profile_photo`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id": 1, 
                    },
                    responseType: 'blob',
                });

                if (photoResponse.status === 200) {
                    const url = URL.createObjectURL(photoResponse.data);
                    setProfilePhoto(url);
                }

                const userResponse = await Axios.get(`${secondServerAPILearning}/api/v1/dashboard/userInformation`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id": 1, 
                    }
                });

                if (userResponse.status === 200) {
                    setUserName(userResponse.data.userName);
                    const userId = userResponse.data.userId;

                    const rolesResponse = await Axios.get(`${API_ROOT}/rest/api/v1/dashboard/get/userroles`, {
                        headers: {
                            "user-id": userId
                            // "user-id": 1, 
                        }
                    });

                    if (rolesResponse.status === 200) {
                        const users = rolesResponse.data;
                        const loggedInUser = users.find((user: any) => user.userId === userId);
                        console.log("this is the logged in user", loggedInUser);
                        dispatch(setUserRole(loggedInUser.role));
                        if (loggedInUser && loggedInUser.role === "Manager") {
                            setIsManager(true);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        const fetchNotifications = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/usernotifications`, {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    }
                })
                if( response.status === 200 ){
                    console.log("this is the response data",response.data);
                    setNotifications(response.data);
                    console.log("this is the notfication", notifications);
                }
            }catch (error) {
                console.log("this is an error", error);
            }
        }
        fetchUserData();
        fetchNotifications();
    }, []);

    function handleInputChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setInput(e.target.value);
    }

    function handleSidebarResize(e: { clientX: React.SetStateAction<number>; }) {
        setSidebarWidth(e.clientX);
    }

    function toggleSidebar() {
        setIsSidebarOpen(!isSidebarOpen);
    }

    function handleButtonClick(button: React.SetStateAction<string>) {
        setActiveButton(button);
    }

    function handleSelectMode(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        const mode = event.currentTarget.id as TWorkMode;
        dispatch(changeWorkModeAction(mode));
        if (mode === "LEARNINGS") {
            history.push("/learnings");
            return;
        }
        switch (mode) {
            case "STRUCTURE":
                dispatch(createPSSAction(currentProject));
                break;
            case "DESIGNER":
                dispatch(createPSSAction(currentProject));
                dispatch(createProcessAction(currentProject));
                break;
            case "PROCESS":
            case "PRODESIGNER":
            case "PIPDESIGNER":
            case "STRDESIGNER":
                dispatch(createProcessAction(currentProject));
                break;
        }
        history.push("/editor");
    }

    function handleOpenProject() {
        setDialog(
            <OpenModelDlg
                mode={"DESIGNER"}
                onClose={handleCloseOpenModelDlg}
                extensions={[".psm", ".p2p", ".pds", ".ods", ".pps", ".ddd"]}
            />
        );
    }

    function handleCloseOpenModelDlg(file?: File, type?: ModelType | undefined) {
        openGenericProject(dispatch, file, "DESIGNER", projects, type).finally(() =>
            setDialog(undefined)
        );
        handleButtonClick('home');
    }

    function handleOpenRemoteFilesList() {
        setDialog(<RemoteFiles onClose={() => setDialog(undefined)} />);
    }

    function handleDownloadDatasheet() {
        Axios.get(`${API_ROOT}/datasheet/download`, {
            responseType: 'blob',
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'datasheet.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error("Error downloading datasheet:", error);
            });
    }

    function handleLogOut() {
        window.localStorage.setItem("jwt", "")
        dispatch(logOutAction())
        dispatch(push("/"))
        Axios.get(`${API_ROOT}/rest/api/v1/deleteUser`, {
            headers: { 'user-id': auth.User_id }
        })
            .then(response => {
                console.log("Updated");
            })
    }

    function invokeReportIssue() {
        window.open("https://asets-tech-support.atlassian.net/servicedesk/customer/portal/1", "_blank");
    }

    return (
        <div className="new-mode-switcher-container">
            <Navbar className="primary-newmodeswitcher-navbar">
                <div className="secondary-newmodeswitcher-navbar">
                    <img src={Logo} alt="Logo" className="navbar-logo" />
                    <h1 className="navbar-title">ASETS</h1>
                    <div className="tertiary-newmodeswitcher-navbar">
                        <Popover
                            className="file-popover"
                            content={
                                <Menu className="file-menu">
                                    <MenuItem className="file-menu-item" icon="projects" text="open project" onClick={handleOpenProject} />
                                    <MenuItem className="file-menu-item" icon="download" text="download datasheet" onClick={handleOpenRemoteFilesList} />
                                    <MenuItem className="file-menu-item" icon="saved" text="save to PDF" onClick={() => saveToPDF(project)} />
                                </Menu>
                            }
                            position={Position.BOTTOM}
                        >
                            {/* <Button className="popover-button" rightIcon="caret-down">
                                file
                            </Button> */}
                        </Popover>
                        {/* <InputGroup
                            large
                            type="text"
                            leftIcon="search"
                            className="search-input-group"
                            value={input}
                            onChange={handleInputChange}
                            placeholder="Search..."
                            style={{
                                borderRadius: "12px",
                            }}
                        /> */}
                        {/* <span className="welcome-text">Welcome, <span style={{ color: "#F1C40F", fontWeight: "bold" }}>{userName}</span></span> */}
                        {/* <Button className="navbar-button" icon="notifications" /> */}
                        <div style={{ position: 'relative' }}>
                            <Popover
                                className="file-popover"
                                content={
                                    <Menu className="file-menu" style={{backgroundColor: "white"}}>
                                        {notifications.map((notification, index) => (
                                            <p className="file-menu-item" key={index}>{notification}</p>
                                        ))}
                                        {notifications.length === 0 && (
                                            <p className="file-menu-item" style={{color: "gray"}}>No notifications</p>
                                        )}
                                    </Menu>

                                }
                                position={Position.BOTTOM}
                                onClose={handleNotificationClose}
                            >
                                <Button className="popover-button" icon="notifications">
                                    {notifications.length > 0 && (
                                        <span style={{
                                            position: 'absolute',
                                            top: '0px',
                                            right: '30px',
                                            background: 'red',
                                            color: 'white',
                                            borderRadius: '50%',
                                            padding: '2px 4px',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}>
                                            {notifications.length}
                                        </span>
                                    )}
                                </Button>
                            </Popover>
                        </div>


                        <Popover
                            content={
                                <Menu className="file-menu">
                                    <MenuItem className="file-menu-item" icon="person" text="profile" onClick={() => { handleButtonClick("profile") }} />
                                    <MenuItem
                                        className="file-menu-item"
                                        icon="help"
                                        text="help"
                                        onMouseEnter={() => setShowHelpDropdown(true)}
                                    >
                                        {showHelpDropdown && (
                                            <MenuItem
                                                className="file-menu-item"
                                                text="report an issue"
                                                onClick={invokeReportIssue}
                                            />
                                        )}
                                    </MenuItem>
                                    <MenuItem className="file-menu-item" icon="log-out" text="logout" onClick={handleLogOut} />
                                </Menu>
                            }
                            position={Position.BOTTOM}
                            className="avatar-container"
                        >
                            <div className="avatar-and-icon">
                                <img src={profilePhoto} alt="Avatar" className="nav-avatar" />
                                <FontAwesomeIcon icon={faAngleDown} className="down-arrow-icon" style={{ color: "#1F8EB7", fontSize: "1.5rem" }} />
                            </div>
                        </Popover>
                    </div>
                </div>
            </Navbar>
            <div className="main-content">
                <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`} style={{ width: isSidebarOpen ? sidebarWidth : 60 }}>
                    <Button rightIcon={isSidebarOpen ? "arrow-left" : "arrow-right"} onClick={toggleSidebar} className="toggle-sidebar-button" style={{ paddingTop: "20px" }} />
                    {isSidebarOpen ? (
                        <div className="sidebar-content">
                            <div className="sidebar-menu-item">
                                <Popover
                                    content={
                                        <Menu className="file-menu">
                                            <MenuItem className="file-menu-item" id={"DESIGNER"} icon="draw" text="integrator" onClick={handleSelectMode} disabled={isProduction && !(products.includes("Designer")|| products.includes("integrator"))} />
                                            {/* <MenuItem className="file-menu-item" id={"PIPING"} icon="pulse" text="piping" onClick={handleSelectMode} disabled={isProduction && !(products.includes("Pipe") || products.includes("piping"))}/>
                                            <MenuItem className="file-menu-item" id={"STRUCTURE"} icon="cube-add" text="structure" onClick={handleSelectMode}  disabled={isProduction && !(products.includes("PR") || products.includes("OF") || products.includes("flare") || products.includes("tower") || products.includes("structural"))}/> */}
                                            <MenuItem className="file-menu-item" icon="comparison" text="viewer" onClick={() => window.open("http://idsviewer.asets.io/", "_blank")} />
                                        </Menu>
                                    }
                                    position={Position.LEFT}
                                >
                                    <Button className={`editor-launch-button ${activeButton === 'launch' ? 'active' : ''}`} icon="rocket-slant" text="launch editor" onClick={() => handleButtonClick('launch')} />
                                </Popover>
                            </div>
                            <div className="sidebar-menu-item">
                                <Button className={`editor-button ${activeButton === 'home' ? 'active' : ''}`} icon="home" text="home" onClick={() => handleButtonClick('home')} />
                            </div>
                            {/* { userRole_orig.userRole === ("Manager" || "Team Lead") && (
                                <div className="sidebar-menu-item">
                                    <Button className={`editor-button ${activeButton === 'dashboard' ? 'active' : ''}`} icon="projects" text="project planner" onClick={() => handleButtonClick('dashboard')} />
                                </div>
                            )} */}
                           
                            {/* <div className="sidebar-menu-item">
                                <Button className={`editor-button ${activeButton === 'project' ? 'active' : ''}`} icon="document" text="project planner" onClick={() => handleButtonClick('project')} />
                            </div> */}
                            {/* <div className="sidebar-menu-item">
                                <Button className={`editor-button ${activeButton === 'scheduler' ? 'active' : ''}`} icon="calendar" text="scheduler" onClick={() => handleButtonClick('scheduler')} />
                            </div> */}
                            {/* <div className="sidebar-menu-item">
                                <Button className={`editor-button ${activeButton === 'material' ? 'active' : ''}`} icon="clipboard" text="material takeoff" onClick={() => handleButtonClick('material')} />
                            </div> */}
                            {isManager && ( 
                                <div className="sidebar-menu-item">
                                    <Button className={`editor-button ${activeButton === 'team' ? 'active' : ''}`} icon="people" text="team" onClick={() => handleButtonClick('team')} />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="sidebar-icons">
                            <Tooltip content = "Home">
                                <div className="menu-item"><Button icon="home" minimal onClick={() => handleButtonClick('home')} /></div>
                            </Tooltip>
                            {/* <Tooltip content={"Project Planner"}>
                                <div className="menu-item"><Button icon="dashboard" minimal onClick={() => handleButtonClick('dashboard')} /></div>
                            </Tooltip> */}
                            {/* <Tooltip>
                                <div className="menu-item"><Button icon="document" minimal onClick={() => handleButtonClick('project')} /></div>
                            </Tooltip> */}
                            <Tooltip content={"Schedule Planner"}>
                                <div className="menu-item"><Button icon="calendar" minimal onClick={() => handleButtonClick('scheduler')} /></div>
                            </Tooltip>
                            <Tooltip content={"Material Take Off"}>
                                <div className="menu-item"><Button icon="clipboard" minimal onClick={() => handleButtonClick('material')} /></div>
                            </Tooltip>
                            <Tooltip content={"Team"}>
                                {isManager && (
                                    <div className="menu-item"><Button icon="people" minimal onClick={() => handleButtonClick('team')} /></div>
                                )}
                            </Tooltip>
                        </div>
                    )}
                    <div className="sidebar-resizer" onMouseDown={() => {
                        document.addEventListener('mousemove', handleSidebarResize);
                        document.addEventListener('mouseup', () => {
                            document.removeEventListener('mousemove', handleSidebarResize);
                        }, { once: true });
                    }} />
                </div>
                <div className="content" style={{ marginLeft: isSidebarOpen ? sidebarWidth : 60 }}>
                    {activeButton === 'home' && <DashboardHome />}
                    {activeButton === 'dashboard' && <Dashboard />}
                    {activeButton === 'material' && <MTOContainer />}
                    {activeButton === 'scheduler' && <SchedulePlaner />}
                    {activeButton === 'profile' && <ShowUserProfile />}
                    {activeButton === 'team' && <Team />}
                </div>
            </div>
            {dialog}
        </div>
    );
}
