import * as actions from "./actions";
import { ActionType, createReducer } from "typesafe-actions";
import { initialPSSState, initialPSS } from "./initialState";
import { TPSSState, EPSSActionTypes } from "./types";
import { kMaxLength } from "buffer";

export type Actions = ActionType<typeof actions>;

const reducer = createReducer<TPSSState, Actions>({ ...initialPSSState })
  .handleType(EPSSActionTypes.SET_PSS, (state, action) => {
    const { name, pss } = action.payload;
    if (state.simulations.some((s) => s.project === name)) {
      return {
        ...state,
        simulations: state.simulations.map((s) => (s.project === name ? pss : s)),
      };
    } else {
      return { ...state, simulations: [...state.simulations, { ...pss, project: name }] };
    }
  })
  .handleType(EPSSActionTypes.LOAD_PSS, (state, action) => {
    const { name, pss } = action.payload;
    return { ...state, simulations: [...state.simulations, { ...pss, project: name }] };
  })
  .handleType(EPSSActionTypes.CREATE_PSS, (state, action) => {
    const { name } = action.payload;
    return { ...state, simulations: [...state.simulations, { ...initialPSS, project: name }] };
  })
  .handleType(EPSSActionTypes.CHANGE_PSS, (state, action) => {
    const { name, field, value } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === name ? { ...s, [field]: value } : s
      ),
    };
  })
  .handleType(EPSSActionTypes.REMOVE_PSS, (state, action) => {
    const { name } = action.payload;
    return {
      ...state,
      simulations: state.simulations.filter((s) => s.project !== name),
    };
  })
  .handleType(EPSSActionTypes.RENAME_PSS, (state, action) => {
    const { oldName, newName } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === oldName ? { ...s, name, project: newName } : s
      ),
    };
  })
  .handleType(EPSSActionTypes.CREATE_ELEMENT, (state, action) => {
    const { name, item } = action.payload;
    console.log("create element", name, item);
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === name ? { ...s, conveyors: [...s.conveyors, item] } : s
      ),
    };
  })
  .handleType(EPSSActionTypes.CHANGE_ELEMENT, (state, action) => {
    const { name, item } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === name
          ? { ...s, conveyors: s.conveyors.map((c) => (c.id === item.id ? item : c)) }
          : s
      ),
    };
  })
  .handleType(EPSSActionTypes.REMOVE_ELEMENT, (state, action) => {
    const { name, item } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === name ? { ...s, conveyors: s.conveyors.filter((c) => c.id !== item.id) } : s
      ),
    };
  })
  .handleType(EPSSActionTypes.CREATE_HUMAN, (state, action) => {
    const { name, item } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === name ? { ...s, humans: [...s.humans, item] } : s
      ),
    };
  })
  .handleType(EPSSActionTypes.CHANGE_HUMAN, (state, action) => {
    const { name, item } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === name
          ? { ...s, humans: s.humans.map((c) => (c.id === item.id ? item : c)) }
          : s
      ),
    };
  })
  
  .handleType(EPSSActionTypes.REMOVE_HUMAN, (state, action) => {
    const { name, item } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((s) =>
        s.project === name ? { ...s, humans: s.humans.filter((c) => c.id !== item.id) } : s
      ),
    };
  })
  .handleType(EPSSActionTypes.SWITCH_PAUSE_RESUME, (state, action) => {
    const { paused } = action.payload;
    return { ...state, paused };
  })
  .handleType(EPSSActionTypes.SWITCH_ANIMATION, (state, action) => {
    const { animate } = action.payload;
    return { ...state, animate };
  })
  .handleType(EPSSActionTypes.SET_SPEED_MULTIPLIER, (state, action) => {
    const { speedMultiplier } = action.payload;
    return { ...state, speedMultiplier };
  })
  .handleType(EPSSActionTypes.CREATE_TASK, (state, action) => {
    const { name, task } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === name
          ? { ...simulation, tasks: [...(simulation.tasks || []), task] }
          : simulation
      ),
    };
  })
  .handleType(EPSSActionTypes.CREATE_TASK_2, (state, action) => {
    console.log("State before CREATE_TASK_2:", state);
    console.log("CREATE_TASK_2 payload:", action.payload);
  
    const { name, tasks } = action.payload;
  
    if (!Array.isArray(tasks)) {
      console.error("Invalid tasks array:", tasks);
      return state; 
    }
  
    return {
      ...state,
      simulations: (state.simulations || []).map((simulation) =>
        simulation.project === name
          ? {
              ...simulation,
              tasks: [...(simulation.tasks || []), ...tasks], // Ensure tasks exists and merge arrays
            }
          : simulation
      ),
    };
  })
  // Handle updating a task
  .handleType(EPSSActionTypes.CHANGE_TASK, (state, action) => {
    const { name, task } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === name
          ? {
              ...simulation,
              tasks: simulation.tasks.map((t) =>
                t.id === task.id ? { ...t, ...task } : t
              ),
            }
          : simulation
      ),
    };
  })

  // Handle removing a task
  .handleType(EPSSActionTypes.REMOVE_TASK, (state, action) => {
    const { name, task } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === name
          ? {
              ...simulation,
              tasks: simulation.tasks.filter((t) => t.id !== task.id),
            }
          : simulation
      ),
    };
  })
  .handleType(EPSSActionTypes.CREATE_ROUTE, (state, action) => {
    const { project, route } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? { ...simulation, routes: [...simulation.routes, route] }
          : simulation
      ),
    };
  })

  // Update an existing route
  .handleType(EPSSActionTypes.CHANGE_ROUTE, (state, action) => {
    const { project, route } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? {
              ...simulation,
              routes: simulation.routes.map((r) =>
                r.id === route.id ? { ...r, ...route } : r
              ),
            }
          : simulation
      ),
    };
  })

  // Remove a route
  .handleType(EPSSActionTypes.REMOVE_ROUTE, (state, action) => {
    const { project, routeId } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? {
              ...simulation,
              routes: simulation.routes.filter((r) => r.id !== routeId),
            }
          : simulation
      ),
    };
  })

  // Add a waypoint to a route
  .handleType(EPSSActionTypes.ADD_WAYPOINT, (state, action) => {
    const { project, routeId, waypoint } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? {
              ...simulation,
              routes: simulation.routes.map((r) =>
                r.id === routeId
                  ? { ...r, waypoints: [...r.waypoints, waypoint] }
                  : r
              ),
            }
          : simulation
      ),
    };
  })

  // Remove a waypoint from a route
  // .handleType(EPSSActionTypes.REMOVE_WAYPOINT, (state, action) => {
  //   const { project, routeId, waypointId } = action.payload;
  //   return {
  //     ...state,
  //     simulations: state.simulations.map((simulation) =>
  //       simulation.project === project
  //         ? {
  //             ...simulation,
  //             routes: simulation.routes.map((r) =>
  //               r.id === routeId
  //                 ? {
  //                     ...r,
  //                     waypoints: r.waypoints.filter((w) => w.id !== waypointId),
  //                   }
  //                 : r
  //             ),
  //           }
  //         : simulation
  //     ),
  //   };
  // })
  .handleType(EPSSActionTypes.REMOVE_WAYPOINT, (state, action) => {
    const { project, routeId, waypointId } = action.payload;
    console.log("Reducer REMOVE_WAYPOINT - before state:", state);
  
    const newState = {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? {
              ...simulation,
              routes: simulation.routes.map((r) =>
                r.id === routeId
                  ? {
                      ...r,
                      waypoints: r.waypoints.filter(
                        (w) => w.id !== waypointId
                      ),
                    }
                  : r
              ),
            }
          : simulation
      ),
    };
    console.log("Reducer REMOVE_WAYPOINT - after state:", newState);
    return newState;
  })  
  .handleType(EPSSActionTypes.CREATE_FACTORY_PATH, (state, action) => {
    const { project, factoryPath } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? {
              ...simulation,
              factoryPaths: [...simulation.factoryPaths, factoryPath],
            }
          : simulation
      ),
    };
  })

  // Handle factory path updates
  .handleType(EPSSActionTypes.CHANGE_FACTORY_PATH, (state, action) => {
    const { project, factoryPath } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? {
              ...simulation,
              factoryPaths: simulation.factoryPaths.map((path) =>
                path.id === factoryPath.id ? { ...path, ...factoryPath } : path
              ),
            }
          : simulation
      ),
    };
  })

  // Handle factory path removal
  .handleType(EPSSActionTypes.REMOVE_FACTORY_PATH, (state, action) => {
    const { project, id } = action.payload;
    return {
      ...state,
      simulations: state.simulations.map((simulation) =>
        simulation.project === project
          ? {
              ...simulation,
              factoryPaths: simulation.factoryPaths.filter((path) => path.id !== id),
            }
          : simulation
      ),
    };
  })
  
  .handleType(EPSSActionTypes.SWITCH_DRAWING, (state, action) => {
    const { drawing } = action.payload;
    console.log(drawing);
    return { ...state, drawing };
  })
  
  .handleType(EPSSActionTypes.SET_PATH_WIDTH, (state, action) => {
    const { pathWidth } = action.payload;
    return { ...state, pathWidth };
  })

  .handleType(EPSSActionTypes.SET_PATH_THICKNESS, (state, action) => {
    const { pathThickness } = action.payload;
    return { ...state, pathThickness };
  })

  .handleType(EPSSActionTypes.SET_PATH_COLOR, (state, action) => {
    const { pathColor } = action.payload;
    return { ...state, pathColor };
  });

export { reducer as PSSReducer };
