import React, { useState, useMemo, useEffect } from "react";
import { CustomDlg } from "../../common/CustomDlg";
import { Button } from "@blueprintjs/core";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addEventAction } from "../../../store/ui/actions";
import { ApplicationState } from "../../../store";
import { getCurrentUI, saveGenericProject, getCurrentProcess, saveToFile } from "../../3d-models/utils";
import { SimpleSelector } from "../../common/SimpleSelector";
import { getElementByName } from "../../3d-models/utils";
import { authReducer } from "../../../store/auth/reducer";
import { convertProcessToImporting } from "../../3d-models/process/process";
import { secondServerAPI } from "../../../pages/utils/agent";

type Props = {
    onClose: () => any;
    items: any[];
    onChange: (items: any[]) => any;
    stype: string;
}

type saveType = 
    | "Process"
    | "Structural"
    | "Piping"

type Project = {
    projectId: number;
    projectName: string;
    // companyName: string;
    projectPhase: string;
};
    

export function LayoutData(props: Props) {
    const { items, onChange, onClose, stype } = props;
    // const [saveType, setSaveType] = useState<saveType>();
    const [layoutName, setLayoutName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projects_1, setProjects] = useState<Project[]>([]);
    const [image, setImage] = useState<File | null>(null);

    const projects = useSelector(
        (state: ApplicationState) => state.main.projects
    );
    
    const currentProject = useSelector(
        (state: ApplicationState) => state.main.currentProject
    );

    const project = useMemo(() => {
        return getElementByName(projects, currentProject);
    }, [projects, currentProject]);

    const ui = useSelector(
        (state: ApplicationState) => getCurrentUI(state)
    );

    const fabricatedSections = useSelector(
        (state: ApplicationState) => state.main.fabricatedSections
    );

    const rolledSections = useSelector(
        (state: ApplicationState) => state.main.rolledSections
    );

    const combinedSections = useSelector(
        (state: ApplicationState) => state.main.combinedSections
    );

    const userDefinedElbows = useSelector(
        (state: ApplicationState) => state.main.userDefinedElbows
    );

    const process = useSelector(
        (state: ApplicationState) => getCurrentProcess(state)
    );

    const auth = useSelector((state: ApplicationState) => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/userandprojects`, {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    },
                });
                if (response.status === 200) {
                    const projectsData = response.data.projectResponses.map((proj: any) => ({
                        projectId: proj.projectId,
                        projectName: proj.projectName,
                        projectPhase: proj.projectPhase,
                    }));
                    setProjects(projectsData);
                    setProjectName(projectsData[0].projectName);
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjects();
    }, []);

    async function handleSaveLayout() {
        let data: any = [];
        console.log("project name", projectName);
        const projectId = projects_1.find(proj => proj.projectName === projectName);
        console.log("project id", projectId)
        if (stype === "Structural") {
            if (!project || !ui?.openFrameUI) {
                dispatch(
                    addEventAction(
                        "No Structure exist. Please Add Structure",
                        "warning"
                    )
                );
                return;
            }
            const type = "Open Frame";
            data = {
                type,
                items: project.models.filter(
                    (m) => m.type === type || m.type === "ROAD"
                ),
                ui: ui.openFrameUI,
                fabricatedSections,
                rolledSections,
                combinedSections,
                availableData: ui?.availableData,
            };
        } else if (stype === "Piping") {
            if (!project) {
                dispatch(
                    addEventAction(
                        "No Piping exist. Please Add Piping",
                        "warning"
                    )
                );
                return;
            }
            data = {
                items: project.freePipes ?? [],
                UDEs: userDefinedElbows,
                availableData: ui?.availableData,
            };
        } else if (stype === "Process") {
            if (!project || !process) {
                dispatch(
                    addEventAction(
                        "No Process exist. Please Add Process",
                        "warning"
                    )
                );
                return;
            }
            data = {
                ...convertProcessToImporting(project?.name, process),
                availableData: ui?.availableData,
            };
        }

        try {
            const response = await Axios.post(
                `${secondServerAPI}rest/api/v1/layouts/upload`,
                {
                    data: data
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "layout": `${stype}_${layoutName}`,
                        "user-id": auth.User_id,
                        // "user-id": 1,
                        "project-id": projectId?.projectId,
                        "type": stype,
                    }
                }
            );

            if (image) {
                const formdata = new FormData();
                formdata.append("image", image);
                const response_1 = await Axios.post(
                    `${secondServerAPI}rest/api/v1/layouts/uploadLayoutImage`,
                    formdata, 
                    {
                        headers: {
                            "Content-Type": "multipart/form-data", 
                            "layout": `${stype}_${layoutNameUpdated}`,
                            "user-id": auth.User_id,
                            // "user-id": 1,
                            "project": projectNameUpdated,
                            "type": stype,
                        }
                    }
                );
            }
            if (response.status === 200) {
                dispatch(
                    addEventAction(
                        "Layout saved successfully",
                        "success"
                    )
                );
            }
        } catch (error: any) {
            dispatch(
                addEventAction(
                    `${error} Layout could not be saved`,
                    "danger"
                )
            );
        }
    }
    

    return (
        <>
            <CustomDlg title={"Layout Data"}
                idText="Save Layout Data"
                body={
                    <div>
                        <div style={{margin: 5,padding: 15,backgroundColor: "black"}}>
                        <div className="d-flex f-column">
                            <input 
                                className="bp3-input bp3-large" 
                                type="text" 
                                placeholder="Layout Name...."
                                style={{ margin: 5 }}
                                value={layoutName}
                                onChange={(e) => setLayoutName(e.target.value)}
                            />
                           <div style={{ margin: 5, color: "white", fontSize: 14 }}>Project Available:</div>
                            <select
                                className="bp3-select w-100"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                            >
                                {projects_1.map((project) => (
                                    <option key={project.projectId} value={project.projectName}>
                                        {project.projectName}
                                    </option>
                                ))}
                            </select>
                        </div>
    
                        <Button 
                            className='bp3-intent-success bp3-large bp3-icon-cloud-upload table-area-button' 
                            text="Save Layout"
                            onClick={handleSaveLayout}
                            style={{ margin: 5 }}
                        />
                        </div>
                        
                    </div>
                }
                onClose={onClose}
            />
        </>
    );
}
