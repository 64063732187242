import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@blueprintjs/core";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentProject, getCurrentPSS, getNextId } from "../../../../3d-models/utils";
import { changeFactoryPath, changePSSAction, createFactoryPath, createPSSAction, switchDrawingAction } from "../../../../../store/pss/actions";
import { initialFactoryPath } from "../../../../../store/pss/initialState";
import { TPSSFactoryPath } from "../../../../../store/pss/types";
import { CheckBoxCell } from "../../../../common/CheckBoxCell";
import { NumericCell } from "../../../../common/NumericCell";
import { SimpleInput } from "../../../../common/SimpleInput";
import "./FactoryPathManager.css"

type FactoryPath = {
  id: number;
  description: string;
  x: number;
  y: number;
  z: number;
};

export function FactoryPathManager() {
  const [selected, setSelected] = useState<number[]>([]);
  const drawing = useSelector((state: ApplicationState) => state.pss.drawing);

  const currentProject = useSelector((state: ApplicationState) =>
		getCurrentProject(state)
	);

  
	const pss = useSelector((state: ApplicationState) => getCurrentPSS(state));

	const dispatch = useDispatch();

	const data = useMemo(() => {
		return pss?.factoryPaths ?? [];
	}, [pss]);

  useEffect(() => {
		if (currentProject && !pss) {
			console.log("PSS is undefined, creating a new one...");
			dispatch(createPSSAction(currentProject.name));
		}
	}, [currentProject, pss, dispatch]);

  const handleAddRow = () => {
    const newId = getNextId(pss?.factoryPaths);

    if (pss) {
			dispatch(
				createFactoryPath(pss.project, { ...initialFactoryPath, id: newId, description: `Path #${newId}` })
			);
		} else {
			console.warn("PSS is not ready yet.");
		}
  };
  
  const handleDrawPath = () => {
    dispatch(switchDrawingAction(!drawing));
  };

  const handleDeleteRows = () => {
    if (!pss) return;
    dispatch(
			changePSSAction(
				pss.project,
				"factoryPaths",
				data.filter((d) => !selected.includes(d.id))
			)
		);
    setSelected([]);
  };

  const handleFactoryPathChange = (
    path: FactoryPath,
    key: keyof FactoryPath,
    value: any
  ) => {
    if (!pss) return;
    dispatch(changeFactoryPath(pss.project, { ...path, [key]: value }));
  };

  const toggleSelectRow = (id: number) => {
    setSelected((prev) =>
      prev.includes(id) ? prev.filter((sid) => sid !== id) : [...prev, id]
    );
  };

  const handleExportToCSV = () => {
    if (!data.length) {
      alert("No data to export");
      return;
    }
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "factoryPaths.csv");
  };

  const handleImportFromCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        if (!pss) return;
        if (!result.data || !Array.isArray(result.data)) {
          console.error("Invalid data in CSV file");
          alert("Invalid CSV file. Please check the format.");
          return;
        }
  
        const importedPaths: FactoryPath[] = result.data.map((row: any, index: number) => ({
          id: row.id && !isNaN(row.id) ? parseInt(row.id, 10) : null, // Use null for new ID generation
          description: row.description || `Path #${index + 1}`,
          x: row.x && !isNaN(row.x) ? parseFloat(row.x) : 0,
          y: row.y && !isNaN(row.y) ? parseFloat(row.y) : 0,
          z: row.z && !isNaN(row.z) ? parseFloat(row.z) : 0,
        }));
  
        const updatedData: FactoryPath[] = [...data];
        const addedPaths: FactoryPath[] = [];
        const skippedPaths: FactoryPath[] = [];
        const updatedPaths: FactoryPath[] = [];
  
        importedPaths.forEach((importedPath) => {
          const existingIndex = updatedData.findIndex((d) => d.id === importedPath.id);
          if (existingIndex !== -1) {
            updatedData[existingIndex] = { ...updatedData[existingIndex], ...importedPath };
            updatedPaths.push(importedPath);
          } else if (importedPath.id === null || updatedData.some((d) => d.id === importedPath.id)) {
            const newId = getNextId(updatedData);
            const newPath = { ...importedPath, id: newId };
            updatedData.push(newPath);
            addedPaths.push(newPath);
          } else {
            updatedData.push(importedPath);
            addedPaths.push(importedPath);
          }
        });
        dispatch(changePSSAction(pss.project, "factoryPaths", updatedData));
      },
      error: (error) => {
        console.error("Error parsing CSV file:", error);
        alert("Failed to import CSV file. Please try again.");
      },
    });
  };

  const toggleSelectAll = () => {
    if (selected.length === data.length) {
      setSelected([]);
    } else {
      setSelected(data.map((path) => path.id));
    }
  };
  
  const isAllSelected = selected.length === data.length && data.length > 0;
  


  const getRow = (path: any) => (
    <tr key={path.id}>
      <td>
        <CheckBoxCell
          key={path.id}
          value={selected.includes(path.id)}
          onChange={() => toggleSelectRow(path.id)}
        />
      </td>
      <td>{path.id}</td>
      <td>
        <input
          type="text"
          value={path.description}
          className="text-input"
          onChange={(e) =>
            dispatch(
              changeFactoryPath(pss?.project, {
                ...path,
                description: e.target.value,
              })
            )
          }
        />
      </td>
      <td>
        <input
          type="number"
          value={path.x}
          className="number-input"
          onChange={(e) =>
            dispatch(
              changeFactoryPath(pss?.project, {
                ...path,
                x: parseFloat(e.target.value),
              })
            )
          }
        />
      </td>
      <td>
        <input
          type="number"
          value={path.y}
          className="number-input"
          onChange={(e) =>
            dispatch(
              changeFactoryPath(pss?.project, {
                ...path,
                y: parseFloat(e.target.value),
              })
            )
          }
        />
      </td>
      <td>
        <input
          type="number"
          value={path.z}
          className="number-input"
          onChange={(e) =>
            dispatch(
              changeFactoryPath(pss?.project, {
                ...path,
                z: parseFloat(e.target.value),
              })
            )
          }
        />
      </td>
    </tr>
  );


  // return (
  //   <div className="d-flex f-column">
  //     {/* Action Buttons */}
  //     <div className="d-flex f-ai-center mb-3">
  //       <Button
  //         small
  //         icon="trash"
  //         text="Delete"
  //         intent="warning"
  //         disabled={!selected.length}
  //         onClick={handleDeleteRows}
  //       />
  //       <Button
  //         small
  //         icon="plus"
  //         text="Add Row"
  //         intent="primary"
  //         onClick={handleAddRow}
  //       />
  //       <Button
  //         small
  //         icon="download"
  //         text="Export to CSV"
  //         intent="success"
  //         onClick={handleExportToCSV}
  //       />
  //       <label htmlFor="csv-upload" className="bp3-button bp3-small bp3-intent-primary">
  //         Import from CSV
  //         <input
  //           id="csv-upload"
  //           type="file"
  //           accept=".csv"
  //           style={{ display: "none" }}
  //           onChange={handleImportFromCSV}
  //         />
  //       </label>
  //       <Button
  //         small
  //         icon="new-drawing"
  //         text={drawing ? "Stop Drawing" : "Draw Path"}
  //         intent={drawing? "danger" : "warning"}
  //         onClick={handleDrawPath}
  //       />
  //       {/* <Button
  //         small
  //         icon="contrast"
  //         text="Color"
  //         intent="warning"
  //         onClick={handleDrawPath}
  //       />
  //       <Button
  //         small
  //         icon="list"
  //         text="Width"
  //         intent="warning"
  //         onClick={handleDrawPath}
  //       /> */}
  //     </div>

  //     {/* Table */}
  //     <div className="table-container">
  //       <table className="table bg-gray">
  //         <thead>
  //           <tr>
  //             <th>Select</th>
  //             <th>Path #</th>
  //             <th>Description</th>
  //             <th>X</th>
  //             <th>Y</th>
  //             <th>Z</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {data.map((path) => (
  //             getRow(path)
  //           ))}
  //           {data.length === 0 && (
  //             <tr>
  //               <td colSpan={6} className="text-center">
  //                 No factory paths available. Use &quot;Add Row&quot; to create paths.
  //               </td>
  //             </tr>
  //           )}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );
  
  return (
    <div className="factory-path-manager">
      {/* Action Buttons */}
      <div className="actions">
        <Button
          small
          icon="trash"
          text="Delete"
          intent="warning"
          disabled={!selected.length}
          onClick={handleDeleteRows}
        />
        <Button
          small
          icon="plus"
          text="Add Row"
          intent="primary"
          disabled={drawing}
          onClick={handleAddRow}
        />
        <Button
          small
          icon="download"
          text="Export to CSV"
          intent="success"
          onClick={handleExportToCSV}
        />
        <label htmlFor="csv-upload" className="bp3-button bp3-small bp3-intent-primary">
          Import from CSV
          <input
            id="csv-upload"
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleImportFromCSV}
          />
        </label>
        <Button
          small
          icon="new-drawing"
          text={drawing ? "Stop Drawing" : "Draw Path"}
          intent={drawing ? "danger" : "warning"}
          onClick={handleDrawPath}
        />
        {/* Uncomment if needed */}
        {/* <Button
          small
          icon="contrast"
          text="Color"
          intent="warning"
          onClick={handleDrawPath}
        />
        <Button
          small
          icon="list"
          text="Width"
          intent="warning"
          onClick={handleDrawPath}
        /> */}
      </div>
  
      {/* Table */}
      <div className="table-container">
        <table className="table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={toggleSelectAll}
              />
            </th>
            <th>Path #</th>
            <th>Description</th>
            <th>X</th>
            <th>Y</th>
            <th>Z</th>
          </tr>
        </thead>
          <tbody>
            {data.map((path) => getRow(path))}
            {data.length === 0 && (
              <tr>
                <td colSpan={6} className="text-center">
                  No factory paths available. Use &quot;Add Row&quot; to create paths.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
  
  
}
