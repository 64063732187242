import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ApplicationState } from '../../store';
import Axios from 'axios';
import { Card } from '@blueprintjs/core';
import "./userList.css";
import { secondServerAPI } from '../utils/agent';

interface User {
    username: string;
    email: string;
    userId: number;
    companyName: string;
    idsRegistrationOn: string; 
    productAccess: string[]; 
    totalLoginHours: string;
  }

const UserList = () => {
  const [users, setUsers] = useState<User[]>([]);
  const auth = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    const fetchUsers = async () => {
      const config = {
        headers: {
            // 'user-id': 1,
            "user-id": auth.User_id,
        },
      };

      try {
        const response = await Axios.get(`${secondServerAPI}/rest/api/v1/getAllUsersActivity`, config);
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch user activities', error);
      }
    };

    if (auth) {
      fetchUsers();
    }
  }, [auth]);

  return (
    <div className="user-list">
      <Card>
        <div className="user-list-header">
          <div className="bp3-control-group .bp3-large user-list-header-title">
            <h3>Name</h3>
            <h3>Company</h3>
            <h3>Account Created</h3>
            <h3>Product Access</h3>
            <h3>Total Login Hours</h3>
          </div>
        </div>
      </Card>
      {users.map((User) => (
        <Card key={User.userId} className='user-list-details-card'>
          <div className="user-list-details">
            <div className="bp3-control-group .bp3-large user-list-details-map">
              <h3>{User.username}</h3>
              <h3>{User.companyName}</h3>
              <h3>{new Date(User.idsRegistrationOn).toLocaleDateString()}</h3>
              <h3 className="product-access-count">{User.productAccess.length}</h3>
              <h3>{User.totalLoginHours}</h3>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

export default UserList;
