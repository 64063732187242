import { Direction2 } from "../main/types";

export enum EPSSActionTypes {
  SET_PSS = "SET_PSS",
  LOAD_PSS = "LOAD_PSS",

  CREATE_PSS = "CREATE_PSS",
  CHANGE_PSS = "CHANGE_PSS",
  REMOVE_PSS = "REMOVE_PSS",
  RENAME_PSS = "RENAME_PSS",

  CREATE_ELEMENT = "CREATE_ELEMENT",
  CHANGE_ELEMENT = "CHANGE_ELEMENT",
  REMOVE_ELEMENT = "REMOVE_ELEMENT",
  
  CREATE_HUMAN = "CREATE_HUMAN",
  CHANGE_HUMAN = "CHANGE_HUMAN",
  REMOVE_HUMAN = "REMOVE_HUMAN",

  SWITCH_PAUSE_RESUME = "SWITCH_PAUSE_RESUME",
  SWITCH_ANIMATION = "SWITCH_ANIMATION",
  SWITCH_DRAWING = "SWITCH_DRAWING",
  SET_PATH_WIDTH = "SET_PATH_WIDTH",
  SET_PATH_THICKNESS = "SET_PATH_THICKNESS",
  SET_PATH_COLOR = "SET_PATH_COLOR",

  CREATE_TASK = "CREATE_TASK",
  CHANGE_TASK = "CHANGE_TASK",
  REMOVE_TASK = "REMOVE_TASK",

  CREATE_ROUTE = "CREATE_ROUTE",
  CHANGE_ROUTE = "CHANGE_ROUTE",
  REMOVE_ROUTE = "REMOVE_ROUTE",
  ADD_WAYPOINT = "ADD_WAYPOINT",
  REMOVE_WAYPOINT = "REMOVE_WAYPOINT",

  SET_SPEED_MULTIPLIER = "SET_SPEED_MULTIPLIER",
  
  CREATE_FACTORY_PATH = "CREATE_FACTORY_PATH",
  CHANGE_FACTORY_PATH = "CHANGE_FACTORY_PATH",
  REMOVE_FACTORY_PATH = "REMOVE_FACTORY_PATH",
  CREATE_TASK_2 = "CREATE_TASK_2",
}

export enum ERackColor {
  RED = "Red",
  YELLOW = "Yellow",
  BLUE = "Blue",
  GREEN = "Green",
}

export type TPSSState = {
  animate?: boolean;
  paused?: boolean;
  drawing?: boolean;
  pathWidth?: number;
  pathThickness?: number;
  pathColor?: string;
  speedMultiplier: number;
  simulations: TPSS[];
};

export type TPSSWaypoint = {
  id: number;
  description: string;
  x: number;
  y: number;
  z: number;
};

export type TPSSRoute = {
  id: number;
  description: string;
  operator: TPSSHuman[];
  waypoints: TPSSWaypoint[];
};

export type TPSS = {
  project: string;
  racks: number;
  conveyors: TPSSConveyor[];
  humans: TPSSHuman[];
  tasks: TPSSTask[];
  routes: TPSSRoute[]; 
  factoryPaths: TPSSFactoryPath[];
};

export type TPSSFactoryPath = {
  id: number;
  description: string;
  x: number;
  y: number;
  z: number;
};


export type TPSSTask = {
  id: number;
  description: string;
  duration: number;
  x: number; 
  y: number; 
  z: number; 
};


export type TPSSHuman = {
  id: number;
  name: string
  speed: number;
  path: TPSSEGVPath[];
}

export type TPSSConveyor = {
  id: number;
  x: number;
  y: number;
  z: number;
  direction: Direction2;
  length: number;
  height: number;
  speed: number;

  EGVPath: TPSSEGVPath[];
  EGVSpeed: number;
  EGVRacksPerTrip: number;

  peopleCountL: number;
  peopleSpacingL: number;
  peopleRackAssignmentL: TPSSRackAssignment[];
  peopleStartPositionL: number;

  peopleCountR: number;
  peopleSpacingR: number;
  peopleRackAssignmentR: TPSSRackAssignment[];
  peopleStartPositionR: number;
};

export type TPSSEGVPath = {
  id: number;
  x: number;
  y: number;
  z: number;
  waitTime: number;
};

export type TPSSRackAssignment = {
  id: number;
  color: ERackColor;
};

