import React, { useState, useEffect } from "react";
import { CustomDlg } from "../../common/CustomDlg";
import { Button, Spinner } from "@blueprintjs/core";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addEventAction } from "../../../store/ui/actions";
import { ApplicationState } from "../../../store";
import { secondServerAPI } from "../../../pages/utils/agent";

type Props = {
    onClose: () => any;
    items: any[];
    onChange: (items: any[]) => any;
    stype: string;
}

type Project = {
    projectId: number;
    projectName: string;
    projectPhase: string;
};

export function UploadEquipment(props: Props) {
    const { items, onChange, onClose, stype } = props;
    const [equipmentName, setEquipmentName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectId, setProjectId] = useState<number>();
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const auth = useSelector((state: ApplicationState) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/userandprojects`, {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    },
                });
                if (response.status === 200) {
                    const projectsData = response.data.projectResponses.map((proj: any) => ({
                        projectId: proj.projectId,
                        projectName: proj.projectName,
                        projectPhase: proj.projectPhase,
                    }));
                    setProjects(projectsData);
                    if (projectsData.length > 0) {
                        setProjectName(projectsData[0].projectName);
                        setProjectId(projectsData[0].projectId); 
                    }
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjects();
    }, [auth.User_id]);

    useEffect(() => {
        const selectedProject = projects.find((project) => project.projectName === projectName);
        if (selectedProject) {
            setProjectId(selectedProject.projectId);
        }
    }, [projectName, projects]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleSaveLayout = async () => {
        if (!selectedFile) {
            dispatch(addEventAction("No file selected", "danger"));
            return;
        }
        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            setLoading(true);
            const response = await Axios.post(
                `${secondServerAPI}/zuul/node/upload`,
                formData,
                {
                    headers: {
                        // "user-id": 1,
                        "user-id": auth.User_id,
                        "file-name": equipmentName,
                        "project-id": projectId,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            if (response.status === 200) {
                dispatch(
                    addEventAction("Asset uploaded successfully", "success")
                );
                setLoading(false);
            }
        } catch (error: any) {
            dispatch(
                addEventAction(`${error} Equipment could not be saved`, "danger")
            );
            alert("Error saving Asset")
            setLoading(false);
        }
    };

    return (
        <>
            <CustomDlg 
                title={"Upload Asset"} 
                idText="upload-equipment" 
                body={
                    <div>
                        <div style={{ margin: 5, padding: 15, backgroundColor: "black" }}>
                            <div className="d-flex f-column">
                                <input
                                    className="bp3-input bp3-large"
                                    type="text"
                                    placeholder="Asset Name..."
                                    style={{ margin: 5 }}
                                    value={equipmentName}
                                    onChange={(e) => setEquipmentName(e.target.value)}
                                />
                                <div style={{ margin: 5, color: "white", fontSize: 14 }}>Project Available:</div>
                                <select
                                    className="bp3-select w-100"
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                >
                                    {projects.map((project) => (
                                        <option key={project.projectId} value={project.projectName}>
                                            {project.projectName}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    className="bp3-input bp3-large"
                                    type="file"
                                    style={{ margin: 5 }}
                                    onChange={handleFileChange}
                                />
                            </div>
                            <Button
                                className='bp3-intent-success bp3-large bp3-icon-cloud-upload table-area-button'
                                text="Upload Asset"
                                onClick={handleSaveLayout}
                                style={{ margin: 5 }}
                            />
                            {
                                loading && (
                                    <div style={{ marginLeft: 10 }}>
                                        Uploading...<Spinner/>
                                    </div>
                                )
                            }  
                        </div>
                    </div>
                }
                onClose={onClose}
            />
        </>
    );
}
