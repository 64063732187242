import React, { useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { ApplicationState } from '../../store';
import { Card, Elevation, Button, Icon, IconSize } from '@blueprintjs/core';
import logo from "../../assets/A-Logo-White-Transparent.png";
import "./adminDashboard.css";
import Axios from 'axios';
import { secondServerAPI } from '../utils/agent';
import { Link} from "react-router-dom";
import UserList from './usersList';
import UserDetails from './userDetails';

interface User {
  username: string;
  email: string;
  userId: number;
  companyName: string;
  idsRegistrationOn: string;
  productAccess: string[];
  currentlyLoggedIn: boolean;
  currentLoginTime: string | null;
  loginActivitiesOfUser: {
      date: string;
      loginActivities: {
          loginTime: string;
          logoutTime: string;
          durationOfLogin: string;
      }[];
      totalLoginDurationInADay: string;
  }[];
  totalLoginHours: string;
}


export default function AdminDashboard() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeView, setActiveView] = useState<string>('option1');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const auth = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    if (activeView !== 'default') {
      Axios.get(`${secondServerAPI}/rest/api/v1/getAllUsersActivity`, {
        headers: {
          'user-id': auth.User_id,
        // 'user-id': 1,
        },
      })
        .then(response => {
          if (Array.isArray(response.data)) {
            setUsers(response.data);
          } else {
            setUsers([]);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error fetching logged in users:", error);
          setIsLoading(false);
        });
    }
  }, [activeView]);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const handleButtonClick = (view: string) => setActiveView(view);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  function calculateLoginDuration(loginTime: string): string {
    const loginDateUTC = new Date(loginTime);
    const nowUTC = new Date();
    const difference = nowUTC.getTime() - loginDateUTC.getTime();
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    return `${hours} hours and ${minutes} minutes`;
  }

  function searchItems(items: User[]): User[] {
    return items.filter((item) => 
      item.username.toLowerCase().includes(searchText.toLowerCase()) || 
      item.email.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  const handleUserCardClick = (user: User) => {
    setSelectedUser(user);
    setActiveView('userDetails');
  };

  return (
    <div className='admin-dashboard'>
      <nav className="bp3-navbar">
        <div className="bp3-navbar-group bp3-align-left">
          <img className="Company-Logo" src={logo} alt="Company Logo" />
          <div className="bp3-navbar-heading">Integrated Design Suite</div>
        </div>
        <div className="bp3-navbar-group bp3-align-right">
          <input
            className="bp3-input"
            placeholder="Search..."
            type="text"
            value={searchText}
            onChange={handleSearchChange}
          />
          <Link to="/modes"><Icon icon="home" className="bp3-button bp3-minimal" /></Link>
          <Icon icon="cog" className="bp3-button bp3-minimal" />
        </div>
      </nav>
      <div className="content-area">
        <Button icon="new-grid-item" minimal={true} onClick={toggleSidebar} className="menu-button bp3-intent-none">
          DASHBOARD
        </Button>
        <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
          <Button className='bp3-intent-none bp3-icon-user bp3-icon-large' onClick={() => handleButtonClick('option1')}>Active User</Button>
          <Button className='bp3-intent-none bp3-icon-list-detail-view bp3-icon-standard' onClick={() => handleButtonClick('option2')}>User List</Button>
          {/* <Button className='bp3-intent-none bp3-icon-user bp3-icon-standard' onClick={() => handleButtonClick('option3')}></Button> */}
        </div>
        <div className="main-content">
          <Card className="intro-card" interactive={true} elevation={Elevation.TWO}>
            <h1>Welcome Back to <span style={{color:"#1f8eb7"}}>IDS™</span></h1>
            <p>Dive into Your Digital Ecosystem</p>
          </Card>
          {activeView === 'option1' && !isLoading ? (
            users.length > 0 ? (
                <div>
                <Card className="user-intro-card" interactive={true} elevation={Elevation.TWO}>
                    <h1>Current Active Sessions Overview</h1>
                    <p>Explore the active connections within <span style={{color:"#1f8eb7"}}>IDS™</span> platform. Here&apos;s a glance at who&apos;s online now:</p>
                </Card>
              <div className="user-cards-container">
                {users.map(user => (
                  <Card key={user.userId} className="user-card" interactive={true} elevation={Elevation.TWO} onClick={() => handleUserCardClick(user)}>
                    <h2>{user.username}</h2>
                    <text><Icon icon="envelope" intent='primary'/> Email: {user.email}</text>
                    <text><Icon icon="office" intent='success'/> Company : {user.companyName}</text>
                    <text><Icon icon="time" intent='warning'/> Login Time: {new Date(user.currentLoginTime).toLocaleString()}</text>
                    <text><Icon icon="stopwatch" intent='danger'/> Total Time on Prod: {user.totalLoginHours}</text>
                    {/* <li><Icon icon="updated" /> Login Duration: {calculateLoginDuration(user.loginTime)}</li> */}
                  </Card>
                ))}
              </div>
              </div>
            ) : (
              <Card className="no-users-card" interactive={true} elevation={Elevation.ZERO}>
                <h5>No Users Logged In</h5>
              </Card>
            )
          ):null}
          {activeView === 'option2' && !isLoading ? (
            <UserList/>
          ): null}
          {activeView === 'userDetails' && selectedUser && (
          <UserDetails user={selectedUser} />
        )}
        </div>
      </div>
    </div>
  );
}
