import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@blueprintjs/core";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import {
	createPSSRoute,
	changePSSRoute,
	removePSSRoute,
	addPSSWaypoint,
	removePSSWaypoint,
	switchPSSAnimationAction,
	changePSSHuman,
	createPSSAction,
	switchPauseResumeAction,
} from "../../../../../store/pss/actions";
import { ApplicationState } from "../../../../../store";
import { calculateShortestPath } from "../helper/shortestPossiblePath";
import { TPSSEGVPath } from "../../../../../store/pss/types";
import { getCurrentProject, getCurrentPSS } from "../../../../3d-models/utils";
import { resetObjects } from "../../../../work-field/workFieldUtils/sceneUtils";
import { secondServerAPI } from "../../../../../pages/utils/agent";
import axios from "axios";
import {
	createHumans,
	drawHumanPath,
} from "../../../../3d-models/pss/humanModeling";
import { Scene } from "three";

let currentId = 0;

export function getNextUniqueId() {
	currentId += 1;
	return currentId;
}

export function UserRoutesManager() {
	const dispatch = useDispatch();
	const [selectedRoutes, setSelectedRoutes] = useState<number[]>([]);
	const [activeSpeed, setActiveSpeed] = useState<number>(1);

	// const pss = useSelector((state: ApplicationState) => getCurrentPSS(state));
	const pssAnimation = useSelector(
		(state: ApplicationState) => state.pss.animate
	);
	const paused = useSelector((state: ApplicationState) => state.pss.paused);
	const threeD_scene = useSelector(
		(state: ApplicationState) => state.main.scene
	);
	const currentProject = useSelector((state: ApplicationState) =>
		getCurrentProject(state)
	);
	const pss = useSelector((state: ApplicationState) => {
		const data = getCurrentPSS(state);
		return data;
	});

	useEffect(() => {
		if (currentProject && !pss) {
			console.log("PSS is undefined, creating a new one...");
			dispatch(createPSSAction(currentProject.name));
		}
	}, [currentProject, pss, dispatch]);

	const currentSimulation = useSelector((state: ApplicationState) => {
		const simulation =
			state.pss?.simulations?.find(
				(sim) => sim.project === currentProject?.name
			) || {};
		return simulation;
	});

	const routes = currentSimulation.routes || [];
	console.log("Routes Data:", routes);

	const tasks = currentSimulation.tasks || [];
	console.log("Tasks Data:", tasks);

	const operators = currentSimulation.humans || [];
	console.log("Operators Data:", operators);

	const factoryPaths = currentSimulation.factoryPaths || [];
	console.log("Factory Paths Data:", factoryPaths);

	const taskDescriptions = useMemo(
		() => tasks.map((task) => ({ id: task.id, description: task.description })),
		[tasks]
	);

	const handleAddRoute = () => {
		if (!currentSimulation.project) return;
		const newRoute = {
			id: getNextUniqueId(),
			description: "",
			waypoints: [],
			operator: [],
		};
		console.log("Adding new route:", newRoute);
		dispatch(createPSSRoute(currentSimulation.project, newRoute));
	};

	const handleDeleteRoutes = () => {
		console.log("Deleting routes:", selectedRoutes);
		selectedRoutes.forEach((routeId) =>
			dispatch(removePSSRoute(currentSimulation.project, routeId))
		);
		setSelectedRoutes([]);
	};

	const handleAddWaypoint = (routeId: number, taskDescription: number) => {
		const task = tasks.find((t) => t.id === taskDescription);
		if (!task) {
			console.error("Task not found:", taskDescription);
			return;
		}

		const newWaypoint = {
			id: getNextUniqueId(),
			x: task.x,
			y: task.y,
			z: task.z,
			description: taskDescription,
			time: "00:00",
			duration: task.duration || 0,
		};
		console.log("Adding waypoint:", newWaypoint);
		dispatch(addPSSWaypoint(currentSimulation.project, routeId, newWaypoint));
	};

	const handleUpdateWaypointTime = (
		routeId: number,
		waypointId: number,
		newTime: string
	) => {
		const timePattern = /^([0-1]\d|2[0-3]):([0-5]\d)$/;
		if (!timePattern.test(newTime)) {
			alert("Please enter a valid time in HH:MM format.");
			return;
		}

		const route = routes.find((r) => r.id === routeId);
		if (!route) return;

		const updatedWaypoints = route.waypoints.map((wp) =>
			wp.id === waypointId ? { ...wp, time: newTime } : wp
		);

		const updatedRoute = { ...route, waypoints: updatedWaypoints };
		console.log("Updating waypoint time:", {
			routeId,
			waypointId,
			newTime,
			updatedRoute,
		});
		dispatch(changePSSRoute(currentSimulation.project, updatedRoute));
	};

	const handleRemoveWaypoint = (routeId: number, waypointId: number) => {
		console.log("Removing waypoint:", { routeId, waypointId });
		dispatch(removePSSWaypoint(currentSimulation.project, routeId, waypointId));
	};

	const toggleRouteSelection = (routeId: number) => {
		setSelectedRoutes((prev) =>
			prev.includes(routeId)
				? prev.filter((id) => id !== routeId)
				: [...prev, routeId]
		);
	};

	const exportToCSV = () => {
		const csv = Papa.unparse(
			routes.map((route) => ({
				id: route.id,
				description: route.description,
				operators: JSON.stringify(route.operator),
				waypoints: JSON.stringify(
					route.waypoints.map(({ id, description, time }) => ({
						id,
						description,
						time,
					}))
				),
			}))
		);
		const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		console.log("Exporting routes to CSV");
		saveAs(blob, "routes.csv");
	};

	const handleCalculateShortestPath1 = async () => {
		const factoryPathPoints = factoryPaths.map((fp) => ({
			id: fp.id,
			description: fp.description,
			x: fp.x,
			y: fp.y,
			z: fp.z,
		}));

		try {
			for (const route of routes) {
				route.operator = route.operator || [];
				route.waypoints = route.waypoints || [];

				if (route.operator.length === 0 || route.waypoints.length === 0) {
					console.warn(
						`Route ${route.id} has no operator or waypoints assigned.`
					);
					continue;
				}

				const visitPoints = route.waypoints.map((wp) => ({
					x: wp.x,
					y: wp.y,
					z: wp.z,
				}));

				// Use the API call to calculate the shortest path
				const response = await axios.post(
					`${secondServerAPI}/short/shortest_path`,
					{
						factoryPoints: factoryPathPoints,
						visitPoints: visitPoints,
					}
				);

				const result = response.data;

				route.operator.forEach((operatorId) => {
					const operator = operators.find((op) => op.id === operatorId);
					if (!operator) {
						console.warn(`Operator with id ${operatorId} not found.`);
						return;
					}

					const updatedPath = result.path.map((point) => {
						let waitTime = 0;

						for (const wp of route.waypoints) {
							const waypointPoint = { x: wp.x, y: wp.y, z: wp.z };

							if (pointsAreEqual(point, waypointPoint)) {
								waitTime = wp.duration || 0;
								break;
							}
						}

						return {
							id: getNextUniqueId(),
							x: point.x,
							y: point.y,
							z: point.z,
							waitTime,
						};
					});

					console.log("Calculated path for operator:", updatedPath);

					const updatedHuman = {
						...operator,
						path: updatedPath,
					};

					console.log("Dispatching CHANGE_HUMAN with:", updatedHuman);
					dispatch(changePSSHuman(currentSimulation.project, updatedHuman));
				});

				console.log(
					`Shortest path for Route ${route.id} calculated with cost: ${result.cost}`
				);
			}

			dispatch(switchPSSAnimationAction(false));
			dispatch(switchPSSAnimationAction(true));
			resetObjects(threeD_scene);

			alert("Shortest paths calculated for all routes.");
		} catch (error) {
			console.error("Error calculating shortest path:", error);
			alert("An error occurred while calculating the shortest path.");
		}
	};

	const handleCalculateShortestPath2 = async () => {
		resetObjects2(threeD_scene);

		const factoryPathPoints = factoryPaths.map((fp) => ({
			id: fp.id,
			description: fp.description,
			x: fp.x,
			y: fp.y,
			z: fp.z,
		}));

		try {
			for (const route of routes) {
				route.operator = route.operator || [];
				route.waypoints = route.waypoints || [];

				if (route.operator.length === 0 || route.waypoints.length === 0) {
					console.warn(
						`Route ${route.id} has no operator or waypoints assigned.`
					);
					continue;
				}

				const visitPoints = route.waypoints.map((wp) => ({
					x: wp.x,
					y: wp.y,
					z: wp.z,
				}));

				// API Call to calculate shortest path
				// const response = await axios.post(`${secondServerAPI}/short/shortest_path`, {
				//   factoryPoints: factoryPathPoints,
				//   visitPoints: visitPoints,
				// });
				// const result = response.data;

				const result = calculateShortestPath(factoryPathPoints, visitPoints);

				route.operator.forEach((operatorId) => {
					const operator = operators.find((op) => op.id === operatorId);
					if (!operator) {
						console.warn(`Operator with id ${operatorId} not found.`);
						return;
					}

					const updatedPath = result.path.map((point) => {
						let waitTime = 0;

						for (const wp of route.waypoints) {
							const waypointPoint = { x: wp.x, y: wp.y, z: wp.z };

							if (pointsAreEqual(point, waypointPoint)) {
								waitTime = wp.duration || 0;
								break;
							}
						}

						return {
							id: getNextUniqueId(),
							x: point.x,
							y: point.y,
							z: point.z,
							waitTime,
						};
					});

					const updatedHuman = {
						...operator,
						path: updatedPath,
					};

					dispatch(changePSSHuman(currentSimulation.project, updatedHuman));
				});

				console.log(
					`Shortest path for Route ${route.id} calculated with cost: ${result.cost}`
				);
			}

			// Restart the animation after all updates are complete
			dispatch(switchPSSAnimationAction(false));
			dispatch(switchPSSAnimationAction(true));

			// Recreate and add humans back to the scene
			createHumans(pss, threeD_scene).then((humanGroup) => {
				threeD_scene.add(humanGroup);
				console.log("Humans re-added to the scene.");
			});

			alert("Shortest paths calculated for all routes.");
		} catch (error) {
			console.error("Error calculating shortest path:", error);
			alert("An error occurred while calculating the shortest path.");
		}
	};

	const handleCalculateShortestPath3 = async () => {
		console.log("Resetting scene before calculating shortest path...");
		resetObjects2(threeD_scene);

		const factoryPathPoints = factoryPaths.map((fp) => ({
			id: fp.id,
			description: fp.description,
			x: fp.x,
			y: fp.y,
			z: fp.z,
		}));

		try {
			const allOperatorIds = operators.map((op) => op.id);

			for (const route of routes) {
				route.operator = route.operator || [];
				route.waypoints = route.waypoints || [];

				if (route.operator.length === 0 || route.waypoints.length === 0) {
					console.warn(
						`Route ${route.id} has no operator or waypoints assigned.`
					);
					continue;
				}

				const visitPoints = route.waypoints.map((wp) => ({
					x: wp.x,
					y: wp.y,
					z: wp.z,
				}));

				const result = calculateShortestPath(factoryPathPoints, visitPoints);

				route.operator.forEach((operatorId) => {
					const operator = operators.find((op) => op.id === operatorId);
					if (!operator) {
						console.warn(`Operator with id ${operatorId} not found.`);
						return;
					}

					const updatedPath = result.path.map((point) => ({
						id: getNextUniqueId(),
						x: point.x,
						y: point.y,
						z: point.z,
						waitTime: 0,
					}));

					operator.path = updatedPath;
					console.log(`Assigned calculated path to operator ${operator.name}`);
				});
			}

			const assignedOperators = routes.flatMap((route) => route.operator);
			const unassignedOperators = allOperatorIds.filter(
				(id) => !assignedOperators.includes(id)
			);

			const defaultPath = [
				{ id: getNextUniqueId(), x: 0, y: 0, z: 0, waitTime: 0 },
			];
			unassignedOperators.forEach((operatorId) => {
				const operator = operators.find((op) => op.id === operatorId);
				if (operator) {
					operator.path = defaultPath;
					console.log(
						`Assigned default path to unassigned operator ${operator.name}`
					);
				}
			});

			dispatch(switchPSSAnimationAction(false));
			dispatch(switchPSSAnimationAction(true));

			createHumans(pss, threeD_scene).then((humanGroup) => {
				threeD_scene.add(humanGroup);
				console.log("Humans re-added to the scene.");
			});

			alert("Shortest paths calculated for all routes.");
		} catch (error) {
			console.error("Error calculating shortest path:", error);
			alert("An error occurred while calculating the shortest path.");
		}
	};

	const handleCalculateShortestPath = async () => {
		console.log("Resetting scene before calculating shortest path...");
		resetObjects2(threeD_scene);

		const factoryPathPoints = factoryPaths.map((fp) => ({
			id: fp.id,
			description: fp.description,
			x: fp.x,
			y: fp.y,
			z: fp.z,
		}));

		try {
			const allOperatorIds = operators.map((op) => op.id);

			for (const route of routes) {
				route.operator = route.operator || [];
				route.waypoints = route.waypoints || [];

				if (route.operator.length === 0 || route.waypoints.length === 0) {
					console.warn(
						`Route ${route.id} has no operator or waypoints assigned.`
					);
					continue;
				}

				const visitPoints = route.waypoints.map((wp) => ({
					x: wp.x,
					y: wp.y,
					z: wp.z,
				}));

				const result = calculateShortestPath(factoryPathPoints, visitPoints);

				route.operator.forEach((operatorId) => {
					const operator = operators.find((op) => op.id === operatorId);
					if (!operator) {
						console.warn(`Operator with id ${operatorId} not found.`);
						return;
					}

					const updatedPath = result.path.map((point) => {
						const matchingWaypoint = route.waypoints.find((wp) =>
							pointsAreEqual(point, { x: wp.x, y: wp.y, z: wp.z })
						);

						if (matchingWaypoint) {
							console.log(
								`Point ${JSON.stringify(
									point
								)} matched with waypoint ${JSON.stringify(
									matchingWaypoint
								)}, assigning wait time: ${matchingWaypoint.duration}`
							);
						} else {
							console.log(
								`Point ${JSON.stringify(point)} has no matching waypoint.`
							);
						}

						return {
							id: getNextUniqueId(),
							x: point.x,
							y: point.y,
							z: point.z,
							waitTime: matchingWaypoint ? matchingWaypoint.duration || 0 : 0,
						};
					});

					operator.path = updatedPath;
					console.log(`Assigned calculated path to operator ${operator.name}`);
				});
			}

			const assignedOperators = routes.flatMap((route) => route.operator);
			const unassignedOperators = allOperatorIds.filter(
				(id) => !assignedOperators.includes(id)
			);

			const defaultPath = [
				{ id: getNextUniqueId(), x: 0, y: 0, z: 0, waitTime: 0 },
			];
			unassignedOperators.forEach((operatorId) => {
				const operator = operators.find((op) => op.id === operatorId);
				if (operator) {
					operator.path = defaultPath;
					console.log(
						`Assigned default path to unassigned operator ${operator.name}`
					);
				}
			});

			dispatch(switchPSSAnimationAction(false));
			dispatch(switchPSSAnimationAction(true));

			createHumans(pss, threeD_scene).then((humanGroup) => {
				threeD_scene.add(humanGroup);
				console.log("Humans re-added to the scene.");

				drawHumanPath(operators, threeD_scene);
			});

			alert("Shortest paths calculated for all routes.");
		} catch (error) {
			console.error("Error calculating shortest path:", error);
			alert("An error occurred while calculating the shortest path.");
		}
	};

	// Helper function to check if two points are equal
	function pointsAreEqual(p1, p2, epsilon = 1e-3) {
		return (
			Math.abs(p1.x - p2.x) < epsilon &&
			Math.abs(p1.y - p2.y) < epsilon &&
			Math.abs(p1.z - p2.z) < epsilon
		);
	}

	// const handleCalculateShortestPath = async () => {
	// const factoryPathPoints = factoryPaths.map((fp) => ({
	//   id: fp.id,
	//   description: fp.description,
	//   x: fp.x,
	//   y: fp.y,
	//   z: fp.z,
	// }));

	// try {
	//   for (const route of routes) {
	//     route.operator = route.operator || [];
	//     route.waypoints = route.waypoints || [];

	//     if (route.operator.length === 0 || route.waypoints.length === 0) {
	//       console.warn(`Route ${route.id} has no operator or waypoints assigned.`);
	//       continue;
	//     }

	//     const visitPoints = route.waypoints.map((wp) => ({
	//       x: wp.x,
	//       y: wp.y,
	//       z: wp.z,
	//     }));

	//     // API Call to calculate shortest path
	//     // const response = await axios.post(`${secondServerAPI}/short/shortest_path`, {
	//     //   factoryPoints: factoryPathPoints,
	//     //   visitPoints: visitPoints,
	//     // });
	//     // const re = response;

	//     // const result = response;

	//     const result = calculateShortestPath(factoryPathPoints, visitPoints);

	//     route.operator.forEach((operatorId) => {
	//       const operator = operators.find((op) => op.id === operatorId);
	//       if (!operator) {
	//         console.warn(`Operator with id ${operatorId} not found.`);
	//         return;
	//       }

	//       const updatedPath = result.path.map((point) => {
	//         let waitTime = 0;

	//         for (const wp of route.waypoints) {
	//           const waypointPoint = { x: wp.x, y: wp.y, z: wp.z };

	//           if (pointsAreEqual(point, waypointPoint)) {
	//             waitTime = wp.duration || 0;
	//             break;
	//           }
	//         }

	//         return {
	//           id: getNextUniqueId(),
	//           x: point.x,
	//           y: point.y,
	//           z: point.z,
	//           waitTime,
	//         };
	//       });

	//       const updatedHuman = {
	//         ...operator,
	//         path: updatedPath,
	//       };

	//       dispatch(changePSSHuman(currentSimulation.project, updatedHuman));
	//     });

	//     console.log(`Shortest path for Route ${route.id} calculated with cost: ${result.cost}`);
	//   }

	//   resetObjects2(threeD_scene);

	//   dispatch(switchPSSAnimationAction(false));
	//   dispatch(switchPSSAnimationAction(true));
	//   createHumans(pss, threeD_scene).then((humanGroup) => {
	//     threeD_scene.add(humanGroup);
	//     console.log("Humans re-added to the scene after reset.");
	//   });

	//   alert("Shortest paths calculated for all routes.");
	// } catch (error) {
	//   console.error("Error calculating shortest path:", error);
	//   alert("An error occurred while calculating the shortest path.");
	// }
	// };

  function resetObjects2(scene) {
    scene.children.slice().forEach((child) => {
        if (
            child.name.startsWith("HUMAN") || 
            child.name.startsWith("HumanPath") || 
            child.userData.type === "path-related"
        ) {
            scene.remove(child);
            if (child.geometry) child.geometry.dispose();
            if (child.material) child.material.dispose();
        }
    });
    console.log("Scene reset: all humans and paths cleared.", scene);
}

	// function resetObjects2(scene: Scene) {
	// 	// Remove the existing human group
	// 	const oldHumanGroup = scene.getObjectByName("HUMAN");
	// 	if (oldHumanGroup) {
	// 		console.log("Removing old human group...");
	// 		scene.remove(oldHumanGroup);
	// 		oldHumanGroup.traverse((child) => {
	// 			if (child.geometry) child.geometry.dispose();
	// 			if (child.material) child.material.dispose();
	// 		});
	// 	}

	// 	const oldHumanPaths = scene.getObjectByName("humanPaths");
	// 	if (oldHumanPaths) {
	// 		console.log("Removing old human paths...");
	// 		scene.remove(oldHumanPaths);
	// 		oldHumanPaths.traverse((child) => {
	// 			if (child.geometry) child.geometry.dispose();
	// 			if (child.material) child.material.dispose();
	// 		});
	// 	}
	// 	console.log("Scene reset complete. All humans and paths cleared.");
	// }

	const handleStart = () => {
		if (pssAnimation) {
			console.log("Resetting animations...");
			resetObjects2(threeD_scene);
			dispatch(switchPauseResumeAction(false));
		}

		console.log(`${pssAnimation ? "Resetting" : "Starting"} animations`);
		dispatch(switchPSSAnimationAction(!pssAnimation));

		createHumans(pss, threeD_scene).then((humanGroup) => {
			threeD_scene.add(humanGroup);
			console.log("Humans re-added to the scene after reset.");
		});
	};

	const handleResume = () => {
		console.log(`${paused ? "Resuming" : "Pausing"} animations`);
		dispatch(switchPauseResumeAction(!paused));
		const humanGroup = threeD_scene.getObjectByName("HUMAN");
		if (humanGroup) {
			humanGroup.userData.paused = !paused;
		}
	};

	const handleFileSelect = () => {
		const input = document.createElement("input");
		input.type = "file";
		input.accept = ".csv";

		input.onchange = (event: Event) => {
			const file = (event.target as HTMLInputElement).files?.[0];
			if (!file) return;

			Papa.parse(file, {
				header: true,
				skipEmptyLines: true,
				complete: (results) => {
					const { data } = results;

					if (data && Array.isArray(data)) {
						const errors: string[] = [];
						const validRoutes = [];
						const assignedOperators = new Set();

						data.forEach((row, index) => {
							const routeDescription = row.description || `Route ${index + 1}`;
							const rawWaypoints = JSON.parse(row.waypoints || "[]");
							const operatorIds = JSON.parse(row.operators || "[]");

							const waypoints = rawWaypoints
								.map((wp: { description: number; time?: string }) => {
									const matchingTask = tasks.find(
										(task) => task.id === wp.description
									);
									if (matchingTask) {
										return {
											id: getNextUniqueId(),
											description: matchingTask.description,
											x: matchingTask.x,
											y: matchingTask.y,
											z: matchingTask.z,
											time: wp.time || "00:00",
											duration: matchingTask.duration || 0,
										};
									} else {
										errors.push(
											`Route ${routeDescription}: Invalid waypoint description "${wp.description}".`
										);
										return null;
									}
								})
								.filter(Boolean);
							const validOperatorIds = operatorIds.filter((id: number) => {
								if (assignedOperators.has(id)) {
									errors.push(
										`Route ${routeDescription}: Operator ${id} is already assigned to another route.`
									);
									return false;
								}
								if (!operators.some((op) => op.id === id)) {
									errors.push(
										`Route ${routeDescription}: Invalid operator ID ${id}.`
									);
									return false;
								}
								return true;
							});

							validOperatorIds.forEach((id) => assignedOperators.add(id));

							validRoutes.push({
								id: getNextUniqueId(),
								description: routeDescription,
								waypoints,
								operator: validOperatorIds,
							});
						});

						validRoutes.forEach((route) =>
							dispatch(createPSSRoute(currentSimulation.project, route))
						);

						if (errors.length > 0) {
							alert(
								`Some routes could not be imported:\n\n${errors.join("\n")}`
							);
						} else {
							alert("All routes imported successfully!");
						}
					} else {
						alert("Invalid CSV format. Please check your file.");
					}
				},
				error: (error) => {
					console.error("Error parsing CSV file:", error);
					alert("An error occurred while importing the CSV file.");
				},
			});

			// Papa.parse(file, {
			//     header: true,
			//     skipEmptyLines: true,
			//     complete: (results) => {
			//         const { data } = results;

			//         if (data && Array.isArray(data)) {
			//             const errors: string[] = [];
			//             const validRoutes = [];

			//             data.forEach((row, index) => {
			//                 const routeDescription = row.description || `Route ${index + 1}`;
			//                 const rawWaypoints = JSON.parse(row.waypoints || "[]");
			//                 const operatorIds = JSON.parse(row.operators || "[]");

			//                 // Map waypoint descriptions to their corresponding tasks
			//                 const waypoints = rawWaypoints.map((wp: { description: number, time?: string }) => {
			//                     const matchingTask = tasks.find(
			//                         (task) => task.id === wp.description
			//                     );
			//                     if (matchingTask) {
			//                         return {
			//                             id: getNextUniqueId(),
			//                             description: matchingTask.description,
			//                             x: matchingTask.x,
			//                             y: matchingTask.y,
			//                             z: matchingTask.z,
			//                             time: wp.time || "00:00", // Add default time if missing
			//                             duration: matchingTask.duration || 0,
			//                         };
			//                     } else {
			//                         errors.push(
			//                             `Route ${routeDescription}: Invalid waypoint description "${wp.description}".`
			//                         );
			//                         return null; // Invalid waypoint
			//                     }
			//                 }).filter(Boolean); // Remove invalid waypoints

			//                 // Validate operators
			//                 const invalidOperators = operatorIds.filter(
			//                     (id: number) => !operators.some((op) => op.id === id)
			//                 );
			//                 if (invalidOperators.length > 0) {
			//                     errors.push(
			//                         `Route ${routeDescription}: Invalid operators ${invalidOperators.join(", ")}.`
			//                     );
			//                 }

			//                 // Add valid route if there are no errors
			//                 if (waypoints.length > 0 && invalidOperators.length === 0) {
			//                     validRoutes.push({
			//                         id: getNextUniqueId(),
			//                         description: routeDescription,
			//                         waypoints,
			//                         operator: operatorIds,
			//                     });
			//                 }
			//             });

			//             // Dispatch valid routes
			//             validRoutes.forEach((route) =>
			//                 dispatch(createPSSRoute(currentSimulation.project, route))
			//             );

			//             // Display errors, if any
			//             if (errors.length > 0) {
			//                 alert(`Some routes could not be imported:\n\n${errors.join("\n")}`);
			//             } else {
			//                 alert("All routes imported successfully!");
			//             }
			//         } else {
			//             alert("Invalid CSV format. Please check your file.");
			//         }
			//     },
			//     error: (error) => {
			//         console.error("Error parsing CSV file:", error);
			//         alert("An error occurred while importing the CSV file.");
			//     },
			// });
		};

		input.click();
	};

	const toggleSelectAll = () => {
		if (selectedRoutes.length === routes.length) {
			setSelectedRoutes([]);
		} else {
			setSelectedRoutes(routes.map((route) => route.id));
		}
	};

	const isAllSelected =
		selectedRoutes.length === routes.length && routes.length > 0;

	const handleSpeed = (speed: number) => {
		setActiveSpeed(speed);
		const humanGroup = threeD_scene.getObjectByName("HUMAN");
		if (humanGroup) {			humanGroup.traverse((child) => {
				console.log("child => ", child);
				if (child.userData && typeof child.userData === "object") {
					child.userData.speedMultiplier = speed;
				}
			});
		}
	};

	return (
		<div className="factory-path-manager">
			<div className="actions">
				<Button
					small
					icon="trash"
					text="Delete Selected"
					intent="warning"
					disabled={selectedRoutes.length === 0}
					onClick={handleDeleteRoutes}
				/>
				<Button
					small
					icon="plus"
					text="Add Route"
					intent="primary"
					onClick={handleAddRoute}
				/>
				<Button
					small
					icon="download"
					text="Export to CSV"
					intent="success"
					onClick={exportToCSV}
				/>
				<Button
					small
					icon="download"
					text="Import from CSV"
					intent="primary"
					onClick={handleFileSelect}
				/>
				{/* <Button
					small
					icon="path-search"
					text="Calculate Shortest Path"
					intent="primary"
					onClick={handleCalculateShortestPath}
				/>
				<Button
					small
					text={`${pssAnimation ? "Reset" : "Start"} animations`}
					intent="danger"
					onClick={handleStart}
				/>
				{pssAnimation && (
					<>
						<Button
							small
							text={`${paused ? "Resume" : "Pause"} animations`}
							intent="danger"
							onClick={handleResume}
						/>
						<Button
							small
							icon={
								<img
									src="/icons/walk.png"
									alt="walking man"
									style={{
										width: "22px",
										height: "22px",
										filter: "brightness(0) invert(1)",
									}}
								/>
							}
							intent={activeSpeed === 1 ? "primary" : "warning"}
							onClick={() => handleSpeed(1)}
						/>
						<Button
							small
							icon={
								<img
									src="/icons/run.png"
									alt="running man"
									style={{
										width: "22px",
										height: "22px",
										filter: "brightness(0) invert(1)",
									}}
								/>
							}
							intent={activeSpeed === 2 ? "primary" : "warning"}
							onClick={() => handleSpeed(2)}
						/>
						<Button
							small
							icon={
								<img
									src="/icons/runer-silhouette-running-fast.png"
									alt="Flying man"
									style={{
										width: "22px",
										height: "22px",
										filter: "brightness(0) invert(1)",
									}}
								/>
							}
							intent={activeSpeed === 8 ? "primary" : "warning"}
							onClick={() => handleSpeed(8)}
						/>
					</>
				)} */}
			</div>

			<div className="table-container">
				<table className="table">
					<thead>
						<tr>
							<th>
								<input
									type="checkbox"
									checked={isAllSelected}
									onChange={toggleSelectAll}
								/>
							</th>
							{/* <th>Route #</th> */}
							<th>Task Description</th>
							<th>Operators</th>
							<th>Waypoints</th>
						</tr>
					</thead>
					<tbody>
						{routes.map((route) => (
							<tr key={route.id}>
								<td>
									<input
										className="text-input"
										type="checkbox"
										checked={selectedRoutes.includes(route.id)}
										onChange={() => toggleRouteSelection(route.id)}
									/>
								</td>
								{/* <td>{route.id}</td> */}
								<td>
									<input
										className="text-input"
										type="text"
										value={route.description}
										onChange={(e) => {
											console.log(
												"Updating route description:",
												e.target.value
											);
											dispatch(
												changePSSRoute(currentSimulation.project, {
													...route,
													description: e.target.value,
												})
											);
										}}
									/>
								</td>
								<td>
									<div>
										{/* <select
                      className="text-input"
                      value=""
                      onChange={(e) => {
                        const operatorId = Number(e.target.value);
                        if (operatorId && !route.operator.includes(operatorId)) {
                          console.log("Adding operator to route:", operatorId);
                          dispatch(
                            changePSSRoute(currentSimulation.project, {
                              ...route,
                              operator: [...route.operator, operatorId],
                            })
                          );
                        }
                      }}
                    >
                      <option value="">Select Operator</option>
                      {operators.map((op) => (
                        <option key={op.id} value={op.id}>
                          {op.name}
                        </option>
                      ))}
                    </select> */}
                    <select
                      className="text-input"
                      value=""
                      onChange={(e) => {
                        const operatorId = Number(e.target.value);
                        if (operatorId) {
                          const selectedOperator = operators.find((op) => op.id === operatorId);
                          const isAlreadyAssigned = routes.some(
                            (r) => r.operator.includes(operatorId) && r.id !== route.id
                          );

                          if (isAlreadyAssigned) {
                            alert(`Operator ${selectedOperator.name} is already assigned to another route.`);
                            return; 
                          }
                          if (selectedOperator && (selectedOperator.speed === 0 || selectedOperator.speed < 0)) {
                            alert(
                              `Operator ${selectedOperator.name} has a speed of 0 or is invalid and cannot be assigned to a route.`
                            );
                            return;
                          }
                          console.log("Assigning operator to route:", operatorId);
                          dispatch(
                            changePSSRoute(currentSimulation.project, {
                              ...route,
                              operator: [operatorId], 
                            })
                          );
                        }
                      }}
                    >
                      <option value="">Select Operator</option>
                      {operators.map((op) => (
                        <option key={op.id} value={op.id}>
                          {op.name}
                        </option>
                      ))}
                    </select>
                    <ul>
                      {route.operator.map((opId) => {
                        const op = operators.find(
                          (operator) => operator.id === opId
                        );
                        return (
                          <li key={opId}>
                            {op ? op.name : `Operator ${opId}`}
                            <Button
                              small
                              icon="trash"
                              intent="danger"
                              onClick={() => {
                                console.log("Removing operator:", opId);
                                dispatch(
                                  changePSSRoute(currentSimulation.project, {
                                    ...route,
                                    operator: route.operator.filter(
                                      (id) => id !== opId
                                    ),
                                  })
                                );
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </td>
                <td>
                  <div>
                    <select
                      value=""
                      onChange={(e) =>
                        handleAddWaypoint(route.id,Number(e.target.value))
                      }
                    >
                      <option value="">Select Waypoints</option>
                      {/* {taskDescriptions.map((desc, index) => (
                        <option key={index} value={desc}>
                          {desc}
                        </option>
                      ))} */}
                      {taskDescriptions.map((task, index) => (
                        <option key={index} value={task.id}>
                          {`${task.id} - ${task.description}`}
                        </option>
                      ))}

                    </select>
                    {/* <ul>
                      {route.waypoints.map((waypoint) => (
                        <li key={waypoint.id}>
                          {waypoint.description}
                          <input
                            type="time"
                            value={waypoint.time || "00:00"}
                            onChange={(e) =>
                              handleUpdateWaypointTime(
                                route.id,
                                waypoint.id,
                                e.target.value
                              )
                            }
                          />
                          <Button
                            small
                            icon="trash"
                            intent="danger"
                            onClick={() =>
                              handleRemoveWaypoint(route.id, waypoint.id)
                            }
                          />
                        </li>
                      ))}
                    </ul> */}
                    <ul>
                      {route.waypoints.map((waypoint) => (
                        <li key={waypoint.id}>
                          {`${waypoint.id} - ${waypoint.description}`}
                          <input
                            type="time"
                            value={waypoint.time || "00:00"}
                            onChange={(e) =>
                              handleUpdateWaypointTime(
                                route.id,
                                waypoint.id,
                                e.target.value
                              )
                            }
                          />
                          <Button
                            small
                            icon="trash"
                            intent="danger"
                            onClick={() =>
                              handleRemoveWaypoint(route.id, waypoint.id)
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
            {routes.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  No routes available. Use &quot;Add Route&quot; to create routes.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
