import React, { useEffect, useState } from "react";
import { Button, FormGroup, InputGroup, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import Logo from "../../assets/A-Logo-Blue-Transparent.png";
import "./Login.css";

type Props = {
  inProgress?: boolean;
  onUnload: () => any;
  onSubmit: (email: string, password: string) => any;
  loginError: string;
  setLoginError: (error: string) => void;
};

export function Login({
  onUnload,
  onSubmit,
  inProgress,
  loginError,
  setLoginError,
}: Props) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => onUnload();
  }, []);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSubmit(email, password);
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (loginError) {
      setLoginError("");
    }
    setter(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-page">
      <div className="primary-container">
        <div className="container-1">
          <h2>Simulate. Optimize. Evolve.</h2>
          <h1>AI Built Intelligence- Redefining Operations</h1>
        </div> 
        <div className="container-2">
          <div className="logo-container-2">
            <img className="primary-logo" src={Logo} alt="Logo" />
            <h1>
              Integrated
              <br />
              Design
              <br />
              Suite
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="login-text-group">email</div>
            <FormGroup>
              <InputGroup
                large
                type="email"
                value={email}
                className="login-input-group"
                autoFocus={true}
                onChange={(e) => handleInputChange(e, setEmail)}
              />
            </FormGroup>
            <div className="login-text-group">password</div>
            <FormGroup style={{ position: "relative" }}>
              <InputGroup
                large
                type={showPassword ? "text" : "password"}
                className="login-input-group"
                value={password}
                onChange={(e) => handleInputChange(e, setPassword)}
              />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
                onClick={togglePasswordVisibility}
              >
                <Icon icon={showPassword ? IconNames.EYE_OFF : IconNames.EYE_OPEN} />
              </span>
            </FormGroup>
            <a href="/forgot">forgot your password?</a>
            {loginError && <div className="error-message">{loginError}</div>}
            <FormGroup className="t-center">
              <Button
                large
                type="submit"
                className="form-group-button"
                loading={inProgress}
              >
                Login
              </Button>
            </FormGroup>
          </form>
          <div className="login-text-group-2">don&apos;t have an account?<a href="/register" target="_blank" className="anchor" rel="noopener noreferrer"> create one</a></div>
          {/* <div className="login-text-group-2">have an account?<a href="/buy" target="_blank" className="anchor" rel="noopener noreferrer"> Buy Credits</a></div> */}
        </div>
      </div>
    </div>
  );
}
