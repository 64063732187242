import { TPSSFactoryPath } from "../../../../../store/pss/types";

type Point3D = {
    x: number;
    y: number;
    z: number;
  };
  
  type FactoryPath = {
    startPoint: Point3D;
    endPoint: Point3D;
  };
  
  type Graph = {
    [key: number]: { [key: number]: number };
  };
  
  type PathResult = {
    path: Point3D[];
    cost: number;
  };
  
  export function convertToFactoryPaths(factoryPoints: TPSSFactoryPath[]): FactoryPath[] {
    console.log("Converting TPSSFactoryPath to FactoryPaths:", factoryPoints);
    const factoryPaths: FactoryPath[] = [];
    for (let i = 0; i < factoryPoints.length - 1; i++) {
      const startPoint = {
        x: factoryPoints[i].x,
        y: factoryPoints[i].y,
        z: factoryPoints[i].z,
      };
      const endPoint = {
        x: factoryPoints[i + 1].x,
        y: factoryPoints[i + 1].y,
        z: factoryPoints[i + 1].z,
      };
      factoryPaths.push({ startPoint, endPoint });
    }
    console.log("Converted FactoryPaths:", factoryPaths);
    return factoryPaths;
  }
  
  function buildGraph(
    factoryPaths: FactoryPath[],
    visitPoints: Point3D[]
  ): { graph: Graph; visitIndices: number[]; points: Point3D[] } {
    const pointIndices = new Map<string, number>();
    const points: Point3D[] = [];
    let idx = 0;
  
    // Add all factory path points to the graph
    factoryPaths.forEach(({ startPoint, endPoint }) => {
      [startPoint, endPoint].forEach((point) => {
        const key = `${point.x},${point.y},${point.z}`;
        if (!pointIndices.has(key)) {
          pointIndices.set(key, idx++);
          points.push(point);
        }
      });
    });
  
    const graph: Graph = {};
  
    // Initialize graph nodes
    points.forEach((_, index) => {
      graph[index] = {};
    });
  
    // Add factory path connections to the graph (bidirectional)
    factoryPaths.forEach(({ startPoint, endPoint }) => {
      const startIdx = pointIndices.get(`${startPoint.x},${startPoint.y},${startPoint.z}`)!;
      const endIdx = pointIndices.get(`${endPoint.x},${endPoint.y},${endPoint.z}`)!;
      const distance = calculateDistance(startPoint, endPoint);
  
      graph[startIdx][endIdx] = distance;
      graph[endIdx][startIdx] = distance; // Bidirectional
    });
  
    const visitIndices: number[] = [];
    const acceptableDistance = 1.0; // Adjust as necessary
  
    visitPoints.forEach((visitPoint) => {
      const visitKey = `${visitPoint.x},${visitPoint.y},${visitPoint.z}`;
      if (!pointIndices.has(visitKey)) {
        for (const factoryPath of factoryPaths) {
          const { startPoint, endPoint } = factoryPath;
  
          // Project the visit point onto the factory path segment
          const projection = projectPointOntoSegment(visitPoint, startPoint, endPoint);
          const distanceToProjection = calculateDistance(visitPoint, projection);
  
          if (distanceToProjection <= acceptableDistance) {
            const startIdx = pointIndices.get(`${startPoint.x},${startPoint.y},${startPoint.z}`)!;
            const endIdx = pointIndices.get(`${endPoint.x},${endPoint.y},${endPoint.z}`)!;
  
            // Check if projection is on an existing node
            const projectionKey = `${projection.x},${projection.y},${projection.z}`;
            if (!pointIndices.has(projectionKey)) {
              const visitIdx = idx++;
              pointIndices.set(projectionKey, visitIdx);
              points.push(projection);
              graph[visitIdx] = {};
  
              // Calculate distances
              const distanceToStart = calculateDistance(startPoint, projection);
              const distanceToEnd = calculateDistance(projection, endPoint);
  
              // Update graph edges
              delete graph[startIdx][endIdx];
              delete graph[endIdx][startIdx];
  
              graph[startIdx][visitIdx] = distanceToStart;
              graph[visitIdx][startIdx] = distanceToStart;
              graph[visitIdx][endIdx] = distanceToEnd;
              graph[endIdx][visitIdx] = distanceToEnd;
            }
  
            visitIndices.push(pointIndices.get(projectionKey)!);
            break; // Stop processing once added
          }
        }
      } else {
        visitIndices.push(pointIndices.get(visitKey)!);
      }
    });
  
    return { graph, visitIndices, points };
  }
  
  
//   function buildGraph(
//     factoryPaths: FactoryPath[],
//     visitPoints: Point3D[]
//   ): { graph: Graph; visitIndices: number[]; points: Point3D[] } {
//     console.log("Building graph with factoryPaths:", factoryPaths);
//     console.log("Visit points:", visitPoints);
  
//     const pointIndices = new Map<string, number>();
//     const points: Point3D[] = [];
//     let idx = 0;
  
//     // Add all factory path points to the graph
//     factoryPaths.forEach(({ startPoint, endPoint }) => {
//       [startPoint, endPoint].forEach((point) => {
//         const key = `${point.x},${point.y},${point.z}`;
//         if (!pointIndices.has(key)) {
//           pointIndices.set(key, idx++);
//           points.push(point);
//         }
//       });
//     });
  
//     const graph: Graph = {};
  
//     // Initialize graph nodes
//     points.forEach((_, index) => {
//       graph[index] = {};
//     });
  
//     // Add factory path connections to the graph (bidirectional)
//     factoryPaths.forEach(({ startPoint, endPoint }) => {
//       const startIdx = pointIndices.get(`${startPoint.x},${startPoint.y},${startPoint.z}`)!;
//       const endIdx = pointIndices.get(`${endPoint.x},${endPoint.y},${endPoint.z}`)!;
//       const distance = calculateDistance(startPoint, endPoint);
  
//       graph[startIdx][endIdx] = distance;
//       graph[endIdx][startIdx] = distance; // Bidirectional
//     });
  
//     const visitIndices: number[] = [];
  
//     // Add visit points to the graph
//     const acceptableDistance = 1.0; // Adjust as necessary
  
//     visitPoints.forEach((visitPoint) => {
//       const visitKey = `${visitPoint.x},${visitPoint.y},${visitPoint.z}`;
//       if (!pointIndices.has(visitKey)) {
//         let inserted = false;
  
//         for (const factoryPath of factoryPaths) {
//           const { startPoint, endPoint } = factoryPath;
  
//           // Project the visit point onto the factory path segment
//           const projection = projectPointOntoSegment(visitPoint, startPoint, endPoint);
//           const distanceToProjection = calculateDistance(visitPoint, projection);
  
//           if (distanceToProjection <= acceptableDistance) {
//             // Insert the waypoint into the graph
//             const startIdx = pointIndices.get(`${startPoint.x},${startPoint.y},${startPoint.z}`)!;
//             const endIdx = pointIndices.get(`${endPoint.x},${endPoint.y},${endPoint.z}`)!;
  
//             // Remove the original edge
//             delete graph[startIdx][endIdx];
//             delete graph[endIdx][startIdx];
  
//             // Add the waypoint as a new node
//             const visitIdx = idx++;
//             pointIndices.set(visitKey, visitIdx);
//             points.push(visitPoint);
//             graph[visitIdx] = {};
  
//             // Calculate distances
//             const distanceToStart = calculateDistance(startPoint, visitPoint);
//             const distanceToEnd = calculateDistance(visitPoint, endPoint);
  
//             // Add new edges
//             graph[startIdx][visitIdx] = distanceToStart;
//             graph[visitIdx][startIdx] = distanceToStart;
//             graph[visitIdx][endIdx] = distanceToEnd;
//             graph[endIdx][visitIdx] = distanceToEnd;
  
//             visitIndices.push(visitIdx);
//             inserted = true;
  
//             // Split the factory path segment at the waypoint
//             // Update the factoryPaths array if necessary
//             break; // Exit the loop after inserting the waypoint
//           }
//         }
  
//         if (!inserted) {
//           console.error(
//             `Visit point ${JSON.stringify(visitPoint)} does not lie on or near any factory path. Ignoring it.`
//           );
//         }
//       } else {
//         visitIndices.push(pointIndices.get(visitKey)!);
//         console.log(`Waypoint already in graph: ${JSON.stringify(visitPoint)}`);
//       }
//     });
  
//     console.log("Visit indices:", visitIndices);
//     console.log("Total waypoints:", visitPoints.length);
//     console.log("Graph after adding visit points:", graph);
//     return { graph, visitIndices, points };
//   }
  
  
  function projectPointOntoSegment(p: Point3D, a: Point3D, b: Point3D): Point3D {
    const ap = subtractPoints(p, a);
    const ab = subtractPoints(b, a);
    const abLengthSquared = dotProduct(ab, ab);
    let t = dotProduct(ap, ab) / abLengthSquared;
    t = Math.max(0, Math.min(1, t)); // Clamp t to [0, 1]
    return {
      x: a.x + ab.x * t,
      y: a.y + ab.y * t,
      z: a.z + ab.z * t,
    };
  }
  
  function subtractPoints(a: Point3D, b: Point3D): Point3D {
    return { x: a.x - b.x, y: a.y - b.y, z: a.z - b.z };
  }
  
  function crossProduct(a: Point3D, b: Point3D): Point3D {
    return {
      x: a.y * b.z - a.z * b.y,
      y: a.z * b.x - a.x * b.z,
      z: a.x * b.y - a.y * b.x,
    };
  }
  
  function dotProduct(a: Point3D, b: Point3D): number {
    return a.x * b.x + a.y * b.y + a.z * b.z;
  }
  
  function magnitude(a: Point3D): number {
    return Math.sqrt(a.x ** 2 + a.y ** 2 + a.z ** 2);
  }
  
  function squaredDistance(a: Point3D, b: Point3D): number {
    return (a.x - b.x) ** 2 + (a.y - b.y) ** 2 + (a.z - b.z) ** 2;
  }
  
  function calculateDistance(a: Point3D, b: Point3D): number {
    return Math.sqrt(squaredDistance(a, b));
  }

  function dijkstraWithDirectVisits(
    graph: Graph,
    start: number,
    end: number,
    visitIndices: Set<number>
  ): { path: number[]; cost: number } {
    console.log(`\nModified Dijkstra's algorithm from node ${start} to node ${end}`);
  
    const distances: { [key: number]: number } = {};
    const previous: { [key: number]: number | null } = {};
    const visited: Set<number> = new Set();
    const priorityQueue: [number, number][] = []; // [node, priority]
  
    // Initialize distances and previous nodes
    for (const node in graph) {
      const nodeIdx = parseInt(node);
      distances[nodeIdx] = Infinity;
      previous[nodeIdx] = null;
    }
  
    distances[start] = 0;
    priorityQueue.push([start, 0]);
  
    while (priorityQueue.length > 0) {
      // Sort queue by priority (distance)
      priorityQueue.sort((a, b) => a[1] - b[1]);
      const [current, currentDistance] = priorityQueue.shift()!;
      
      if (visited.has(current)) continue;
      visited.add(current);
  
      console.log(`Visiting node ${current} with current distance ${currentDistance}`);
  
      // Stop when reaching the end node
      if (current === end) {
        console.log(`Reached the target node ${end}`);
        break;
      }
  
      // Explore neighbors
      for (const neighborStr in graph[current]) {
        const neighbor = parseInt(neighborStr);
        const weight = graph[current][neighbor];
  
        if (visited.has(neighbor)) continue;
  
        const tentativeDistance = distances[current] + weight;
  
        console.log(
          `  Checking neighbor ${neighbor}: current distance ${distances[neighbor]}, ` +
          `tentative distance ${tentativeDistance}, edge weight ${weight}`
        );
  
        // If this neighbor is a visit point, prioritize its inclusion
        const isVisitPoint = visitIndices.has(neighbor);
        const adjustedTentativeDistance = isVisitPoint ? tentativeDistance - 0.1 : tentativeDistance;
  
        if (adjustedTentativeDistance < distances[neighbor]) {
          distances[neighbor] = tentativeDistance;
          previous[neighbor] = current;
          console.log(
            `  Updated distance for node ${neighbor} to ${tentativeDistance}. Previous node: ${current}`
          );
          priorityQueue.push([neighbor, adjustedTentativeDistance]);
        }
      }
    }
  
    // Reconstruct the shortest path
    const path: number[] = [];
    let u: number | null = end;
    if (previous[u] !== null || u === start) {
      while (u !== null) {
        path.unshift(u);
        u = previous[u];
      }
    }
  
    const cost = distances[end];
    if (cost === Infinity) {
      console.log("No path found.");
      return { path: [], cost: Infinity };
    }
  
    console.log("Final shortest path (node indices):", path);
    console.log("Final distances from start node:", distances);
    console.log("Previous nodes mapping:", previous);
    console.log(`Total cost from node ${start} to node ${end}: ${cost}`);
  
    return { path, cost };
  }
  
  
//   function dijkstra(graph: Graph, start: number, end: number): { path: number[]; cost: number } {
//     console.log(`\nDijkstra's algorithm from node ${start} to node ${end}`);
  
//     const distances: { [key: number]: number } = {};
//     const previous: { [key: number]: number | null } = {};
//     const visited: Set<number> = new Set();
//     const queue: Set<number> = new Set();
  
//     // Initialize distances and previous nodes
//     for (const node in graph) {
//       const nodeIdx = parseInt(node);
//       distances[nodeIdx] = Infinity;
//       previous[nodeIdx] = null;
//       queue.add(nodeIdx);
//     }
  
//     distances[start] = 0;
  
//     while (queue.size > 0) {
//       // Find the unvisited node with the smallest distance
//       let current: number | null = null;
//       let minDistance = Infinity;
//       for (const node of queue) {
//         if (distances[node] < minDistance) {
//           minDistance = distances[node];
//           current = node;
//         }
//       }
  
//       if (current === null) {
//         break;
//       }
  
//       console.log(`Visiting node ${current} with current distance ${distances[current]}`);
  
//       if (current === end) {
//         console.log(`Reached the target node ${end}`);
//         break;
//       }
  
//       queue.delete(current);
//       visited.add(current);
  
//       // Explore neighbors
//       for (const neighborStr in graph[current]) {
//         const neighbor = parseInt(neighborStr);
//         const weight = graph[current][neighbor];
  
//         if (visited.has(neighbor)) {
//           continue;
//         }
  
//         const tentativeDistance = distances[current] + weight;
  
//         console.log(
//           `  Checking neighbor ${neighbor}: current distance ${distances[neighbor]}, ` +
//           `tentative distance ${tentativeDistance}, edge weight ${weight}`
//         );
  
//         if (tentativeDistance < distances[neighbor]) {
//           distances[neighbor] = tentativeDistance;
//           previous[neighbor] = current;
//           console.log(
//             `  Updated distance for node ${neighbor} to ${tentativeDistance}. Previous node: ${current}`
//           );
//         }
//       }
//     }
  
//     // Reconstruct the shortest path
//     const path: number[] = [];
//     let u: number | null = end;
//     if (previous[u] !== null || u === start) {
//       while (u !== null) {
//         path.unshift(u);
//         u = previous[u];
//       }
//     }
  
//     const cost = distances[end];
//     if (cost === Infinity) {
//       console.log("No path found.");
//       return { path: [], cost: Infinity };
//     }
  
//     console.log("Final shortest path (node indices):", path);
//     console.log("Final distances from start node:", distances);
//     console.log("Previous nodes mapping:", previous);
//     console.log(`Total cost from node ${start} to node ${end}: ${cost}`);
  
//     return { path, cost };
//   }
  
export function calculateShortestPath(
    factoryPoints: TPSSFactoryPath[],
    visitPoints: Point3D[]
  ): PathResult {
    console.log("Calculating shortest path with factoryPoints:", factoryPoints);
    console.log("Visit points:", visitPoints);
  
    const factoryPaths = convertToFactoryPaths(factoryPoints);
    const { graph, visitIndices, points } = buildGraph(factoryPaths, visitPoints);
  
    console.log("Built graph:", graph);
    console.log("Visit indices:", visitIndices);
    console.log("Points:", points);
  
    if (visitIndices.length < 2) {
      console.log("Not enough visit points to compute path.");
      return { path: [], cost: 0 };
    }
  
    const finalPathIndices: number[] = [];
    let totalCost = 0;
  
    const visitIndexSet = new Set(visitIndices);
  
    for (let i = 0; i < visitIndices.length - 1; i++) {
      const startIdx = visitIndices[i];
      const endIdx = visitIndices[i + 1];
      const result = dijkstraWithDirectVisits(graph, startIdx, endIdx, visitIndexSet);
      if (result.path.length === 0) {
        console.error(`No path found between waypoints ${i} and ${i + 1}.`);
        return { path: [], cost: Infinity };
      }
      if (i > 0) result.path.shift();
      finalPathIndices.push(...result.path);
      totalCost += result.cost;
    }
  
    const finalPath = finalPathIndices.map((idx) => points[idx]);
  
    console.log("Final path:", finalPath);
    return { path: finalPath, cost: totalCost };
  }
    


//   export function calculateShortestPath(
//     factoryPoints: TPSSFactoryPath[],
//     visitPoints: Point3D[]
//   ): PathResult {
//     console.log("Calculating shortest path with factoryPoints:", factoryPoints);
//     console.log("Visit points:", visitPoints);
  
//     const factoryPaths = convertToFactoryPaths(factoryPoints);
//     const { graph, visitIndices, points } = buildGraph(factoryPaths, visitPoints);
  
//     console.log("Built graph:", graph);
//     console.log("Visit indices:", visitIndices);
//     console.log("Points:", points);
  
//     if (visitIndices.length < 2) {
//       console.log("Not enough visit points to compute path.");
//       return { path: [], cost: 0 };
//     }
  
//     const finalPathIndices: number[] = [];
//     let totalCost = 0;
  
//     for (let i = 0; i < visitIndices.length - 1; i++) {
//       const startIdx = visitIndices[i];
//       const endIdx = visitIndices[i + 1];
//       const result = dijkstra(graph, startIdx, endIdx);
//       if (result.path.length === 0) {
//         console.error(`No path found between waypoints ${i} and ${i + 1}.`);
//         return { path: [], cost: Infinity };
//       }
//       // Avoid duplicate points in the path
//       if (i > 0) result.path.shift();
//       finalPathIndices.push(...result.path);
//       totalCost += result.cost;
//     }
  
//     const finalPath = finalPathIndices.map((idx) => points[idx]);
  
//     console.log("Final path:", finalPath);
//     return { path: finalPath, cost: totalCost };
//   }
  
  
  
  