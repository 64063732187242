import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, HTMLSelect } from "@blueprintjs/core";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { ApplicationState } from "../../../../../store";
import {
	TPSS,
	TPSSHuman,
	TPSSRoute,
	TPSSTask,
} from "../../../../../store/pss/types";
import "./SimulationResult.css";
import { getCurrentProject } from "../../../../3d-models/utils";
import { Bar } from "react-chartjs-2";

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "#ffffff", 
        font: {
          size: 14, 
        },
      },
    },
    title: {
      display: true,
      text: "Simulation Results",
      color: "#ffffff",
      font: {
        size: 24, 
        weight: "bold",
      },
    },
  },
  scales: {
    x: {
      grid: {
        color: "rgba(255, 255, 255, 0.2)", 
      },
      ticks: {
        color: "#ffffff", 
        font: {
          size: 12,
        },
      },
    },
    y: {
      grid: {
        color: "rgba(255, 255, 255, 0.2)",
      },
      ticks: {
        color: "#ffffff",
        font: {
          size: 12,
        },
      },
    },
  },
};


export function SimulationResultManager() {
  const currentProject = useSelector((state: ApplicationState) =>
    getCurrentProject(state)
  );
  const currentSimulation = useSelector((state: ApplicationState) => {
    const simulation =
      state.pss?.simulations?.find(
        (sim) => sim.project === currentProject?.name
      ) || {};
    return simulation;
  });
  const humans: TPSSHuman[] = currentSimulation.humans || [];
  const routes: TPSSRoute[] = currentSimulation.routes || [];
  const tasks: TPSSTask[] = currentSimulation.tasks || [];

	const [selectedHumanId, setSelectedHumanId] = useState<number>(
		humans.length > 0 ? humans[0].id : 0
	);

  const allStats = humans.map((human) => {
    const humanRoutes = routes.filter((route) => route.operator.includes(human.id));
    return calculateHumanStats(human, humanRoutes, tasks);
  });
  

  const chartData = {
    labels: humans.map((human) => human.name),
    datasets: [
      {
        label: "Total Distance Walked (m)",
        data: allStats.map((stat) => stat.totalDistanceWalked),
        backgroundColor: "rgba(75, 192, 192, 0.6)", 
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
      },
      {
        label: "Total Time Taken (s)",
        data: allStats.map((stat) => stat.totalTimeTaken),
        backgroundColor: "rgba(153, 102, 255, 0.6)", 
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 2,
      },
    ],
  };
  
  const aggregateStatistics = {
    average: calculateAggregate(allStats, "totalDistanceWalked", "average"),
    min: calculateAggregate(allStats, "totalDistanceWalked", "min"),
    max: calculateAggregate(allStats, "totalDistanceWalked", "max"),
    median: calculateAggregate(allStats, "totalDistanceWalked", "median"),
  };
  
  function calculateAggregate(data, key, type) {
    const values = data.map((item) => item[key]).filter((value) => value != null);
    values.sort((a, b) => a - b);
  
    switch (type) {
      case "average": {
        return values.reduce((sum, val) => sum + val, 0) / values.length;
      }
      case "min": {
        return values[0];
      }
      case "max": {
        return values[values.length - 1];
      }
      case "median": {
        const mid = Math.floor(values.length / 2);
        return values.length % 2 === 0
          ? (values[mid - 1] + values[mid]) / 2
          : values[mid];
      }
      default:
        return 0;
    }
  }

  const selectedHuman = humans.find((human) => human.id === selectedHumanId);
  const humanRoutes = routes.filter((route) =>
    route.operator.includes(selectedHumanId)
  );

  const stats = selectedHuman
    ? calculateHumanStats(selectedHuman, humanRoutes, tasks)
    : null;

    const exportResults = () => {
      if (humans.length === 0) return;
    
      const csvData = [];
      
      humans.forEach((human) => {
        const selectedHumanId = human.id;
        const selectedHuman = humans.find((h) => h.id === selectedHumanId);
        const humanRoutes = routes.filter((route) =>
          route.operator.includes(selectedHumanId)
        );
    
        const stats = selectedHuman
          ? calculateHumanStats(selectedHuman, humanRoutes, tasks)
          : null;
    
        if (stats) {
          const generalMetrics = [
            { Section: `General Metrics - ${selectedHuman.name}` },
            { Metric: "Total Distance Walked (m)", Value: stats.totalDistanceWalked.toFixed(2) },
            { Metric: "Total Distance Climbed (m)", Value: stats.totalDistanceClimbed.toFixed(2) },
            { Metric: "Total Distance Descended (m)", Value: stats.totalDistanceDescended.toFixed(2) },
            { Metric: "Total Time Taken (s)", Value: stats.totalTime.toFixed(2) },
            { Metric: "Total Wait Time (s)", Value: stats.totalWaitTime.toFixed(2) },
            { Metric: "Number of Staircases Ascended", Value: stats.staircasesAscended },
            { Metric: "Number of Staircases Descended", Value: stats.staircasesDescended },
            { Metric: "Number of Steps Ascended", Value: stats.stepsAscended },
            { Metric: "Number of Steps Descended", Value: stats.stepsDescended },
          ];
    
          const waypointsData = stats.waypointsVisited.map((wp) => ({
            Description: wp.description,
            ArrivalTime: wp.arrivalTime,
            ScheduledTime: wp.scheduledTime,
            IsLate: wp.isLate ? "Yes" : "No",
            LateBySeconds: wp.lateBySeconds.toFixed(2),
            WaitTime: wp.waitTime.toFixed(2),
          }));
    
          // Combine results into the CSV data
          csvData.push(...generalMetrics, {}, { Section: `Waypoints Visited - ${selectedHuman.name}` }, ...waypointsData, {});
        }
      });
    
      const csv = Papa.unparse(csvData, {
        columns: [
          "Section",
          "Metric",
          "Value",
          "Description",
          "ArrivalTime",
          "ScheduledTime",
          "IsLate",
          "LateBySeconds",
          "WaitTime",
        ],
      });
    
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `simulation_results_all_humans.csv`);
    };

  return (
    <div className="simulation-results-dialog simulation-results-container p-5">
      <h3 className="bp4-heading mb-4">Simulation Results</h3>
      {humans.length > 0 ? (
        <>
          <div className="controls-container mb-4">
            <HTMLSelect
              value={selectedHumanId}
              onChange={(e) => setSelectedHumanId(Number(e.target.value))}
            >
              {humans.map((human) => (
                <option key={human.id} value={human.id}>
                  {human.name}
                </option>
              ))}
            </HTMLSelect>
            <Button intent="primary" small onClick={exportResults}>
              Export CSV
            </Button>
          </div>
          {stats && (
            <div className="stats-container">
              <h4>General Metrics</h4>
              <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered">
                <tbody>
                  <tr>
                    <td>Total Distance Walked (m)</td>
                    <td>{stats.totalDistanceWalked.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Total Time Taken (s)</td>
                    <td>{stats.totalTimeTaken.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Total Idle Time (s)</td>
                    <td>{stats.totalWaitTime.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Number of Staircases Ascended</td>
                    <td>{stats.staircasesAscended}</td>
                  </tr>
                  <tr>
                    <td>Number of Staircases Descended</td>
                    <td>{stats.staircasesDescended}</td>
                  </tr>
                </tbody>
              </table>
              <h4>Waypoints Visited</h4>
              <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Arrival Time</th>
                    <th>Scheduled Time</th>
                    <th>Late?</th>
                    <th>Wait Time (s)</th>
                  </tr>
                </thead>
                <tbody>
                  {stats.waypointsVisited.map((wp, index) => {
                    const statusText = wp.isLate
                      ? wp.lateBySeconds > 0
                        ? "Late"
                        : "Early"
                      : "On Time";
                    const statusColor = wp.isLate
                      ? wp.lateBySeconds > 0
                        ? "red"
                        : "green"
                      : "black";
                    return (
                      <tr key={index}>
                        <td>{wp.description}</td>
                        <td>{wp.arrivalTime}</td>
                        <td>{wp.scheduledTime}</td>
                        <td style={{ color: statusColor }}>{statusText}</td>
                        <td>{wp.waitTime.toFixed(2)}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <div className="aggregate-stats mt-4">
                  <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered">
                    <thead>
                      <tr>
                        <th>Statistic</th>
                        <th>Average</th>
                        <th>Min</th>
                        <th>Max</th>
                        <th>Median</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Total Distance Walked (m)</td>
                        <td>{aggregateStatistics.average.toFixed(2)}</td>
                        <td>{aggregateStatistics.min.toFixed(2)}</td>
                        <td>{aggregateStatistics.max.toFixed(2)}</td>
                        <td>{aggregateStatistics.median.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="summary-container mt-5">
                  <h4>Summary Results</h4>
                  <div className="chart-container">
                      <Bar data={chartData} options={chartOptions} />
                  </div>

                </div>
                <h4 className="bp4-heading mb-3 text-center">Reference Values Used</h4>
                <tbody>
                  <tr>
                    <td>Average Step Height (m)</td>
                    <td>0.17</td>
                  </tr>
                  <tr>
                    <td>Average Step Length (m)</td>
                    <td>0.8</td>
                  </tr>
                  <tr>
                    <td>Default Walking Speed (m/s)</td>
                    <td>1.4</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <p>No humans available in the simulation.</p>
      )}
    </div>
  );
}

// Helper Functions

// function calculateHumanStats(human, humanRoutes, tasks) {
//   const REFERENCE_VALUES = {
//     stepHeight: 0.17,
//     stepLength: 0.8,
//     defaultSpeed: 1.4,
//   };

//   let totalDistanceWalked = 0;
//   let totalDistanceClimbed = 0;
//   let totalDistanceDescended = 0;
//   let totalTimeTaken = 0;
//   let totalTime = 0; 
//   let totalWaitTime = 0;
//   const staircasesAscended = 0;
//   const staircasesDescended = 0;
//   let stepsAscended = 0;
//   let stepsDescended = 0;
//   const waypointsVisited = [];

//   const speed = human.speed || REFERENCE_VALUES.defaultSpeed;

//   const firstWaypoint = humanRoutes[0]?.waypoints?.[0];
//   if (firstWaypoint) {
//     totalTime = parseTimeToSeconds(firstWaypoint.time || "00:00");
//   }

//   human.path.forEach((pathPoint, index) => {
//     if (index > 0) {
//       const prevPoint = human.path[index - 1];
//       const dx = pathPoint.x - prevPoint.x;
//       const dy = pathPoint.y - prevPoint.y;
//       const dz = pathPoint.z - prevPoint.z;

//       const flatDistance = Math.sqrt(dx ** 2 + dz ** 2);
//       const distance = Math.sqrt(flatDistance ** 2 + dy ** 2);

//       totalDistanceWalked += flatDistance;

//       if (Math.abs(dy) > 0.5) {
//         if (dy > 0) totalDistanceClimbed += dy;
//         else totalDistanceDescended += Math.abs(dy);
//       }

//       const timeTakenForSegment = distance / speed;
//       totalTime += timeTakenForSegment;
//       totalTimeTaken += timeTakenForSegment; // Accumulate time taken for the segment
//     }

//     // Match waypoints
//     const waypoint = findWaypointAtPoint(pathPoint, humanRoutes);
//     if (waypoint) {
//       const arrivalTime = totalTime;
//       const scheduledTime = parseTimeToSeconds(waypoint.time);
//       const lateBy = arrivalTime - scheduledTime;

//       waypointsVisited.push({
//         description: waypoint.description,
//         arrivalTime: formatSecondsToTime(arrivalTime),
//         scheduledTime: waypoint.time,
//         isLate: lateBy > 0,
//         lateBySeconds: lateBy,
//         waitTime: pathPoint.waitTime || 0,
//       });

//       totalTime += pathPoint.waitTime || 0;
//       totalWaitTime += pathPoint.waitTime || 0;
//     }
//   });

//   stepsAscended = Math.round(totalDistanceClimbed / REFERENCE_VALUES.stepHeight);
//   stepsDescended = Math.round(totalDistanceDescended / REFERENCE_VALUES.stepHeight);

//   return {
//     totalDistanceWalked,
//     totalDistanceClimbed,
//     totalDistanceDescended,
//     totalTime,
//     totalTimeTaken,
//     totalWaitTime,
//     staircasesAscended,
//     staircasesDescended,
//     stepsAscended,
//     stepsDescended,
//     waypointsVisited,
//   };
// }

function calculateHumanStats(human, humanRoutes, tasks) {
  const REFERENCE_VALUES = {
    stepHeight: 0.17,
    stepLength: 0.8,
    defaultSpeed: 1.4,
  };

  let totalDistanceWalked = 0;
  let totalDistanceClimbed = 0;
  let totalDistanceDescended = 0;
  let totalTimeTaken = 0;
  let totalTime = 0; 
  let totalWaitTime = 0;
  let staircasesAscended = 0;
  let staircasesDescended = 0;
  let stepsAscended = 0;
  let stepsDescended = 0;
  const waypointsVisited = [];

  const speed = human.speed || REFERENCE_VALUES.defaultSpeed;

  const firstWaypoint = humanRoutes[0]?.waypoints?.[0];
  if (firstWaypoint) {
    totalTime = parseTimeToSeconds(firstWaypoint.time || "00:00");
  }

  let isAscending = false;
  let isDescending = false;

  human.path.forEach((pathPoint, index) => {
    if (index > 0) {
      const prevPoint = human.path[index - 1];
      const dx = pathPoint.x - prevPoint.x;
      const dy = pathPoint.y - prevPoint.y;
      const dz = pathPoint.z - prevPoint.z;

      const flatDistance = Math.sqrt(dx ** 2 + dz ** 2);
      const distance = Math.sqrt(flatDistance ** 2 + dy ** 2);

      totalDistanceWalked += flatDistance;

      if (Math.abs(dy) > 0.5) { // Threshold for detecting a staircase
        if (dy > 0) {
          totalDistanceClimbed += dy;
          if (!isAscending) {
            staircasesAscended++;
            isAscending = true;
          }
          isDescending = false;
        } else {
          totalDistanceDescended += Math.abs(dy);
          if (!isDescending) {
            staircasesDescended++;
            isDescending = true;
          }
          isAscending = false;
        }
      } else {
        isAscending = false;
        isDescending = false;
      }

      const timeTakenForSegment = distance / speed;
      totalTime += timeTakenForSegment;
      totalTimeTaken += timeTakenForSegment; // Accumulate time taken for the segment
    }

    // Match waypoints
    const waypoint = findWaypointAtPoint(pathPoint, humanRoutes);
    if (waypoint) {
      const arrivalTime = totalTime;
      const scheduledTime = parseTimeToSeconds(waypoint.time);
      const lateBy = arrivalTime - scheduledTime;

      waypointsVisited.push({
        description: waypoint.description,
        arrivalTime: formatSecondsToTime(arrivalTime),
        scheduledTime: waypoint.time,
        isLate: lateBy > 0,
        lateBySeconds: lateBy,
        waitTime: pathPoint.waitTime || 0,
      });

      totalTime += pathPoint.waitTime || 0;
      totalWaitTime += pathPoint.waitTime || 0;
    }
  });

  stepsAscended = Math.round(totalDistanceClimbed / REFERENCE_VALUES.stepHeight);
  stepsDescended = Math.round(totalDistanceDescended / REFERENCE_VALUES.stepHeight);

  return {
    totalDistanceWalked,
    totalDistanceClimbed,
    totalDistanceDescended,
    totalTime,
    totalTimeTaken,
    totalWaitTime,
    staircasesAscended,
    staircasesDescended,
    stepsAscended,
    stepsDescended,
    waypointsVisited,
  };
}



function findWaypointAtPoint(point, humanRoutes) {
	for (const route of humanRoutes) {
		for (const waypoint of route.waypoints) {
			if (pointsAreEqual(point, waypoint)) {
				return waypoint;
			}
		}
	}
	return null;
}

function pointsAreEqual(p1, p2, epsilon = 1e-2) {
  return (
    Math.abs(p1.x - p2.x) < epsilon &&
    Math.abs(p1.y - p2.y) < epsilon &&
    Math.abs(p1.z - p2.z) < epsilon
  );
}


function parseTimeToSeconds(timeStr) {
	const [hours, minutes] = timeStr.split(":").map(Number);
	return hours * 3600 + minutes * 60;
}

function formatSecondsToTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const isPM = h >= 12;
  const hour12 = h % 12 || 12; 
  const ampm = isPM ? "PM" : "AM";
  return `${padZero(hour12)}:${padZero(m)} ${ampm}`;
}

function padZero(num) {
	return num.toString().padStart(2, "0");
}



