import React, { Dispatch, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { CustomDlg } from "../../../../common/CustomDlg";
import { CustomTabsDlg } from "../../../../common/CustomTabsDlg";
import { ApplicationState } from "../../../../../store";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { secondServerAPI } from "../../../../../pages/utils/agent";
import { concatUnique, getCurrentProcess, getCurrentUI, getElementByName, getIndexName, getIndexName_1, getNextId } from "../../../../3d-models/utils";
import { changeCombinedSections, changeFabracatedSections, changeProjectAction, changeRolledSections, changeUserDefinedElbows } from "../../../../../store/main/actions";
import { FreePipe, ModelType, Project } from "../../../../../store/main/types";
import { fixOpenFrame, fixPipes } from "../../../project-tab/projectUtils";
import { OpenFrameUI, ProjectUI } from "../../../../../store/ui/types";
import { TPipingValve } from "../../../../../store/data/piping-valves";
import { addEventAction, changeOFUIAction, changeUIAction, createUIAction } from "../../../../../store/ui/actions";
import { initialStateProjectUI } from "../../../../../store/ui/initialState";
import { setProcessAction } from "../../../../../store/process/actions";
import { TProcess, TProcessLine } from "../../../../../store/process/types";
import { fixImportedProcess } from "../../../../3d-models/process/process";
import { Button } from "@blueprintjs/core";
import MenuButton from "../../../MenuButton";
import { createProject } from "../../../../../store/main/actions";
import { getUIByProjectName } from "../../../../3d-models/utils";
import { TOpenFrame } from "../../../../../store/main/openFrameTypes";
import { createProcessAction } from "../../../../../store/process/actions";
import { createPSSAction } from "../../../../../store/pss/actions";
import { DataState } from "../../../../../store/data/types";

export function LayoutBar() {
  const [id, setId] = useState(0);
  const [apiData, setApiData] = useState<{ type: string; layout: string }[]>([]);
  const [isDlgVisible, setIsDlgVisible] = useState(false);
  const [newItem, setNewItem] = useState("");
  const [newId, setNewId] = useState<number>();
  const [newType, setNewType] = useState("");
  const [newName, setNewName] = useState("");

  const projects = useSelector((state: ApplicationState) => state.main.projects);
  const currentProject = useSelector((state: ApplicationState) => state.main.currentProject);
  const project = useMemo(() => getElementByName(projects, currentProject), [projects, currentProject]);
  const ui = useSelector((state: ApplicationState) => getCurrentUI(state));
  const userDefinedElbows = useSelector((state: ApplicationState) => state.main.userDefinedElbows);
  const fabricatedSections = useSelector((state: ApplicationState) => state.main.fabricatedSections);
  const rolledSections = useSelector((state: ApplicationState) => state.main.rolledSections);
  const combinedSections = useSelector((state: ApplicationState) => state.main.combinedSections);
  const processs = useSelector((state: ApplicationState) => getCurrentProcess(state));
  const resoures = useSelector((state: ApplicationState) => state.data);
  const dispatch = useDispatch();
  const auth = useSelector((state: ApplicationState) => state.auth);
  const tabs = useMemo(() => [
    { id: 0, name: "Process" },
    { id: 1, name: "Piping" },
    { id: 2, name: "Structure" }
  ], []);
  const projectResponseState = useSelector((state: ApplicationState) => state.projectResponse);
  const projectName_orig = useSelector((state: ApplicationState) => state.projectName);
  const projectResp = projectResponseState.projectResponse;
  const piping = projectResp.Piping || [];
  const structural = projectResp.Structural || [];
  const process = projectResp.Process || [];

  const projectsRef = useRef(projects);
  const projectNameOrigRef = useRef(projectName_orig.projectName);

  useEffect(() => {
    projectsRef.current = projects;
  }, [projects]);

  useEffect(() => {
    projectNameOrigRef.current = projectName_orig.projectName;
  }, [projectName_orig.projectName]);

  useEffect(() => {
    if (projects) {
      console.log('Projects state for project:', projects);
    }
  }, [projects, newItem]);

  useEffect(() => {
    if (currentProject) {
      console.log('currentProject state for project:', currentProject);
    }
  }, [currentProject, newItem]);

  useEffect(() => {
    if (project) {
      console.log('Project state for project:', project);
    }
  }, [project, newItem]);

  useEffect(() => {
    if (ui) {
      console.log('UI state for project:', ui);
    }
  }, [ui]);

  const getAllProfilesFromXCH = useCallback((json: any) => {
    const newFabricatedSections =
      concatUnique(fabricatedSections, json.fabricatedSections, (a, b) =>
        a.designation === b.designation &&
        a.shape === b.shape &&
        a.d_global === b.d_global &&
        a.bf_global === b.bf_global &&
        a.tf_global === b.tf_global &&
        a.tfb_global === b.tfb_global &&
        a.tw_global === b.tw_global
      ) ?? [];

    const newRolledSections =
      concatUnique(rolledSections, json.rolledSections, (a, b) =>
        a.designation === b.designation &&
        a.baseLib === b.baseLib &&
        a.baseProfile === b.baseProfile &&
        a.tpWidth === b.tpWidth &&
        a.tpThickness === b.tpThickness &&
        a.bpWidth === b.bpWidth &&
        a.bpThickness === b.bpThickness
      ) ?? [];

    const newCombinedSections =
      concatUnique(combinedSections, json.combinedSections, (a, b) =>
        a.CSLibrary === b.CSLibrary &&
        a.baseProfile === b.baseProfile &&
        a.designation === b.designation &&
        a.combination === b.combination &&
        a.type === b.type &&
        a.gap === b.gap
      ) ?? [];

    const allProfiles = [
      ...resoures.profileSectionData,
      ...newFabricatedSections,
      ...newRolledSections,
      ...newCombinedSections,
    ];
    return {
      allProfiles,
      newFabricatedSections,
      newRolledSections,
      newCombinedSections,
    };
  }, [fabricatedSections, rolledSections, combinedSections, resoures.profileSectionData]);

  const handleButtonClick = useCallback((item: any, type: string, id?: number, projectName?: string) => {
    console.log("Projects item in the function", projectsRef.current);
    const project1 = getElementByName(projectsRef.current, projectName);
    console.log("Project 1", project1);
    console.log("this is the item", item)
    Axios.get(`${secondServerAPI}rest/api/v1/layouts/getLayoutFile`, {
      headers: {
        //"user-id": 1,
        "user-id": auth.User_id,
        "layout-id": id,
      }
    }).then(response => {
      setIsDlgVisible(false);
      console.log("tHIS IS THE PROJECT", project);
      let triggeredProject;
      if(projectName){
        triggeredProject = { ...project1, name: projectName || project1.name };
      }else{
        triggeredProject = { ...project, name: project.name};
      }
      console.log("Project triggered", triggeredProject);
      if (type === "Piping") {
        const json = response.data;
        handleCombineAvailableData(dispatch, ui, json.data.availableData);

        const newUserDefinedElbows = concatUnique(
          userDefinedElbows,
          json.data.userDefinedElbows,
          (a, b) => a.nps === b.nps && a.schedule === b.schedule && a.material === b.material && a.d === b.d && a.t === b.t && a.a === b.a && a.degree === b.degree
        ) ?? [];

        dispatch(changeUserDefinedElbows(newUserDefinedElbows));

        let pipes = triggeredProject.freePipes ?? [];
        const newPipes: FreePipe[] = [];
        const namesMap = new Map<string, string>();
        let maxNumber = largestPipeNumber(pipes);

        for (const item of fixPipes(json.data.items as FreePipe[])) {
          if (pipes.length > 0) {
            const newName = `PP${getIndexName_1(pipes, "PP", maxNumber, "pipe")}`;
            maxNumber = maxNumber + 1;
            namesMap.set(item.pipe, newName);

            const changed: FreePipe = {
              ...item,
              id: getNextId(pipes),
              pipe: newName,
              preceding: item.preceding
            };

            pipes.push(changed);
            newPipes.push(changed);
          } else {
            newPipes.push(item);
          }
        }
        for (const pipe of newPipes) {
          const newPrecedingName = namesMap.get(pipe.preceding);
          if (newPrecedingName) {
            pipe.preceding = newPrecedingName;
          }
        }
        pipes = [...new Set([...pipes, ...newPipes])];
        dispatch(changeProjectAction({ ...triggeredProject, freePipes: pipes }));
      } else if (type === "Structural") {
        const json = response.data;
        handleCombineAvailableData(dispatch, ui, json.data.availableData);
        const { newCombinedSections, newFabricatedSections, newRolledSections } = getAllProfilesFromXCH(json);

        let models = triggeredProject.models ?? [];
        let ofUI: OpenFrameUI = { ...ui.openFrameUI };
        let newOFUI: OpenFrameUI = json.data.ui && { ...json.data.ui };
        const type: ModelType = json.data.type;

        for (const item of json.data.items) {
          const fixed = fixOpenFrame(item);
          if (models.some((m) => m.name === fixed.name)) {
            const generalName = type === "Factory Shed" ? "FS" : "OF";
            const newName = `${generalName}${getIndexName(models, generalName)}`;
            const changeModel = (arr: any[]) => {
              return arr ? arr.map((el) => ({ ...el, model: newName })) : [];
            };
            const changed: TOpenFrame = {
              ...fixed,
              name: newName,
              project: triggeredProject.name,
            };
            newOFUI = newOFUI && {
              ...newOFUI,
              accessories: changeModel(newOFUI.accessories),
              additionalBeams: {
                ...newOFUI.additionalBeams,
                beamToBeam: changeModel(newOFUI.additionalBeams.beamToBeam),
                cantilever: changeModel(newOFUI.additionalBeams.cantilever),
                columnToBeam: changeModel(newOFUI.additionalBeams.columnToBeam),
                columnToColumn: changeModel(newOFUI.additionalBeams.columnToColumn),
                columns: changeModel(newOFUI.additionalBeams.columns),
                kneeBracings: changeModel(newOFUI.additionalBeams.kneeBracings),
                planBracings: changeModel(newOFUI.additionalBeams.planBracings),
                staircases: changeModel(newOFUI.additionalBeams.staircases),
                verticalBracings: changeModel(newOFUI.additionalBeams.verticalBracings),
              },
              basePlates: {
                ...newOFUI.basePlates,
                circular: changeModel(newOFUI.basePlates.circular),
                rectangular: changeModel(newOFUI.basePlates.rectangular),
              },
              spliceFlanges: {
                circular: changeModel(newOFUI.spliceFlanges.circular),
                rectangular: changeModel(newOFUI.spliceFlanges.rectangular),
              },
              platforms: changeModel(newOFUI.platforms),
              pipes: {
                ...newOFUI.pipes,
                items: changeModel(newOFUI.pipes.items),
                supports: changeModel(newOFUI.pipes.supports),
              },
              ladders: {
                ...newOFUI.ladders,
                ladders: changeModel(newOFUI.ladders.ladders),
              },
              members: {
                beams: changeModel(newOFUI.members.beams),
                columns: changeModel(newOFUI.members.columns),
                releases: changeModel(newOFUI.members.releases),
              },
              frames: {
                ...newOFUI.frames,
                parameters: changeModel(newOFUI.frames.parameters),
                relocations: changeModel(newOFUI.frames.relocations),
              },
              masonryCladdings: changeModel(newOFUI.masonryCladdings),
              metalCladdings: changeModel(newOFUI.metalCladdings),
              runners: changeModel(newOFUI.runners),
              truss: changeModel(newOFUI.truss),
              loadingsUI: {
                ...initialStateProjectUI.openFrameUI.loadingsUI,
              },
            };
            models.push(changed);
          } else models.push({ ...fixed, project: triggeredProject.name });
        }

        const getNextIds = (oldArr: any[], newArr: any[]) => {
          let arr = [...oldArr];
          for (const el of newArr) {
            arr = [...arr, { ...el, id: getNextId(arr) }];
          }
          return arr;
        };

        if (newOFUI) {
          if (newOFUI.additionalBeams) {
            if (newOFUI.additionalBeams.columns) {
              ofUI = {
                ...ofUI,
                additionalBeams: {
                  ...ofUI.additionalBeams,
                  columns: newOFUI.additionalBeams.columns.reduce((acc, item) => {
                    const changed = { ...item, id: getNextId(acc) };
                    let model = models.find((model) => model.name === item.model);
                    if (model) {
                      model = {
                        ...model,
                        columns: (model as TOpenFrame).columns.map((c) => c.uiId === item.id ? { ...c, uiId: changed.id } : c),
                      } as TOpenFrame;
                      models = models.map((m) => m.name === model!.name ? (model as Model) : m);
                    }
                    return [...acc, changed];
                  }, ofUI.additionalBeams.columns),
                },
              };
            }
            // Other if conditions for additionalBeams
          }
          // Other if conditions for newOFUI properties
        }

        dispatch(changeFabracatedSections(newFabricatedSections));
        dispatch(changeRolledSections(newRolledSections));
        dispatch(changeCombinedSections(newCombinedSections));
        dispatch(changeProjectAction({
          ...triggeredProject,
          models,
          modelType: triggeredProject.modelType || type,
        }));
        dispatch(changeOFUIAction(ofUI));
      } else if (type === "Process") {
        const json = response.data;
        handleCombineAvailableData(dispatch, ui, json.data.availableData);
        const imported: TProcess = fixImportedProcess(json.data);
        const changed: TProcess = { ...processs };
        const namesMap = new Map<string, string>();
        for (const [key, value] of Array.from(imported.elements.entries())) {
          if (changed.elements.has(key)) {
            const newName = `${value.type}${getIndexName(Array.from(changed.elements.values()), value.type)}`;
            namesMap.set(key, newName);
            changed.elements.set(newName, {
              ...value,
              points: value.points.map((p) => ({
                ...p,
                element: p.element ? namesMap.get(p.element) ?? p.element : undefined,
              })),
            });
          } else changed.elements.set(key, value);
        }
        if (!changed.lines) changed.lines = [];
        for (const line of imported.lines ?? []) {
          const id = getNextId(changed.lines);
          const changedLine: TProcessLine = {
            ...line,
            id,
            from: line.from ? namesMap.get(line.from) ?? line.from : line.from,
            to: line.to ? namesMap.get(line.to) ?? line.to : line.to,
            processLineNo: id,
            segments: line.segments.map((s) => ({
              ...s,
              instrumentationIDs: undefined,
            })),
          };
          changed.lines.push(changedLine);
        }
        dispatch(setProcessAction(triggeredProject.name, changed));
      }
    }).catch(error => {
      dispatch(addEventAction(`${error} There is an Errror`, "warning"));
    });
  }, [dispatch, projects, currentProject, ui, project, projectName_orig.projectName, userDefinedElbows, fabricatedSections, rolledSections, combinedSections, resoures.profileSectionData, processs]);

  const handleButtonClick_1 = (item: string, type: string, id: number) => {
    setIsDlgVisible(true);
    setNewItem(item);
    setNewId(id);
    setNewType(type);
  };

  const handleNewWindow = () => {
    const projectName = newItem;
    dispatch(createProject(projectName));
    dispatch(createUIAction(projectName));
    dispatch(createProcessAction(projectName));
    dispatch(createPSSAction(projectName));
    setNewName(projectName);
    setTimeout(() => {
      handleButtonClick(newItem, newType, newId,projectName);
    }, 1000);
  };

  const handleCloseDlg = () => {
    setIsDlgVisible(false);
  };

  const renderTabContent = () => {
    const renderButton = (item: any, index: number, type: string, id: number) => (
      <button
        key={index}
        className="bp3-intent-primary"
        style={{ margin: 5, padding: 5, minWidth: '150px' }}
        title={item}
        onClick={() => handleButtonClick_1(item, type, id)}
      >
        {item}
      </button>
    );
    switch (id) {
      case 0:
        return process.map((item, index) => renderButton(item.layoutName, index, "Process", item.layoutId));
      case 1:
        return piping.map((item, index) => renderButton(item.layoutName, index, "Piping", item.layoutId));
      case 2:
        return structural.map((item, index) => renderButton(item.layoutName, index, "Structural", item.layoutId));
      default:
        return null;
    }
  };

  return (
    <>
      <CustomDlg
        title={"Layouts"}
        isMinimize={true}
        zIndex={5}
        idText="structure-tools-dialog"
        body={
          <>
            <CustomTabsDlg tabs={tabs} selected={id} onSelect={setId} />
            <div className={"tools-body"}>
              {renderTabContent()}
            </div>
          </>
        }
      />
      {isDlgVisible && (
        <CustomDlg
          zIndex={10}
          isMinimize={true}
          position={"center"}
          title={"Open Layout"}
          body={
            <>
              <div>Open In:</div>
              <Button
                text="Existing Window"
                className="w-120 bp3-intent-success"
                style={{ margin: 5, padding: 5 }}
                onClick={() => { handleButtonClick(newItem, newType, newId) }}
              />
              <Button
                text="New Window"
                className="w-120 bp3-intent-primary"
                style={{ margin: 5, padding: 5 }}
                onClick={handleNewWindow}
              />
            </>
          }
          onClose={handleCloseDlg}
        />
      )}
    </>
  );
}

export function largestPipeNumber(pipes: FreePipe[]): number {
  if (!pipes.length) return 1;
  return Math.max(...pipes.map(pipe => {
    const match = pipe.pipe.match(/\d+$/);
    return match ? parseInt(match[0], 10) : 0;
  }));
}

export function handleCombineAvailableData(
  dispatch: Dispatch<any>,
  ui?: ProjectUI,
  availableData?: DataState
) {
  if (!ui || !availableData) return;
  const changed: ProjectUI = {
    ...ui,
    availableData: ui.availableData
      ? {
        ...ui.availableData,
        profileSectionData:
          concatUnique(ui.availableData.profileSectionData, availableData.profileSectionData, (a, b) => a.profile_section_id === b.profile_section_id) ?? [],
        pipingCS:
          concatUnique(ui.availableData.pipingCS, availableData.pipingCS, (a, b) => a.piping_details_id === b.piping_details_id) ?? [],
        pipingSS:
          concatUnique(ui.availableData.pipingSS, availableData.pipingSS, (a, b) => a.piping_details_id === b.piping_details_id) ?? [],
        pipingCaps:
          concatUnique(ui.availableData.pipingCaps, availableData.pipingCaps, (a, b) => a.id === b.id || a.piping_caps_id === b.piping_caps_id) ?? [],
        pipingCollets:
          concatUnique(ui.availableData.pipingCollets, availableData.pipingCollets, (a, b) => a.id === b.id || a.piping_collets_id === b.piping_collets_id) ?? [],
        pipingElbows:
          concatUnique(ui.availableData.pipingElbows, availableData.pipingElbows, (a, b) => a.id === b.id || a.piping_elbows_id === b.piping_elbows_id) ?? [],
        pipingReducers:
          concatUnique(ui.availableData.pipingReducers, availableData.pipingReducers, (a, b) => a.id === b.id || a.piping_reducers_id === b.piping_reducers_id) ?? [],
        pipingTees:
          concatUnique(ui.availableData.pipingTees, availableData.pipingTees, (a, b) => a.id === b.id || a.piping_tees_id === b.piping_tees_id) ?? [],
        pipingReturns:
          concatUnique(ui.availableData.pipingReturns, availableData.pipingReturns, (a, b) => a.id === b.id || a.piping_returns_id === b.piping_returns_id) ?? [],
        pipingFlangesAllPresRating:
          concatUnique(ui.availableData.pipingFlangesAllPresRating, availableData.pipingFlangesAllPresRating, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingFlangesBlind:
          concatUnique(ui.availableData.pipingFlangesBlind, availableData.pipingFlangesBlind, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingFlangesLapped:
          concatUnique(ui.availableData.pipingFlangesLapped, availableData.pipingFlangesLapped, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingFlangesRingJointFacing:
          concatUnique(ui.availableData.pipingFlangesRingJointFacing, availableData.pipingFlangesRingJointFacing, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingFlangesSlipon:
          concatUnique(ui.availableData.pipingFlangesSlipon, availableData.pipingFlangesSlipon, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingFlangesSocketWelding:
          concatUnique(ui.availableData.pipingFlangesSocketWelding, availableData.pipingFlangesSocketWelding, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingFlangesThreaded:
          concatUnique(ui.availableData.pipingFlangesThreaded, availableData.pipingFlangesThreaded, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingFlangesWeldingneck:
          concatUnique(ui.availableData.pipingFlangesWeldingneck, availableData.pipingFlangesWeldingneck, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingLongWeldingNeckFlanges:
          concatUnique(ui.availableData.pipingLongWeldingNeckFlanges, availableData.pipingLongWeldingNeckFlanges, (a, b) => a.piping_flange_id === b.piping_flange_id) ?? [],
        pipingValveActuators:
          concatUnique(ui.availableData.pipingValveActuators, availableData.pipingValveActuators, (a, b) => a.id === b.id) ?? [],
        pipingValveControls:
          concatUnique(ui.availableData.pipingValveControls, availableData.pipingValveControls, (a, b) => a.id === b.id) ?? [],
        pipingValves:
          concatUnique(ui.availableData.pipingValves, availableData.pipingValves, (a, b) => a.id === b.id) ?? [],
      }
      : availableData,
  };
  dispatch(changeUIAction(changed));
}
