import React, { useState, useEffect, useMemo } from 'react';
import { CustomDlg } from '../../../common/CustomDlg';
import { exportToCSV, fixNumberToStr, getElementByName, getGLTFItems2 } from '../../../3d-models/utils';
import { Button } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import Item from 'antd/es/list/Item';

type Props = {
  onClose: () => any;
}

export default function AssetMTO({ onClose }: Props) {
  const [activeTab, setActiveTab] = useState<string>("wall");
  // const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [GLTFItemsState, setGLTFItemsState] = useState<any[]>([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);

  const scene = useSelector(
    (state: ApplicationState) => state.main.scene
  );

  const allProjects = useSelector(
    (state: ApplicationState) => state.main.projects
  );

  const currentProject = useSelector(
    (state: ApplicationState) => state.main.currentProject
  );

  const project_101 = useMemo(() => {
    return getElementByName(allProjects, currentProject);
  }, [allProjects, currentProject]);

  useEffect(() => {
    const handleSceneChange = () => {
      const updatedItems = getGLTFItems2(scene, project_101);
      setGLTFItemsState(updatedItems);
    };
  
    scene.addEventListener('objectChange', handleSceneChange);
  
    return () => {
      scene.removeEventListener('objectChange', handleSceneChange);
    };
  }, [scene, project_101]);
  

  // useEffect(() => {
  //   const items = getGLTFItems2(scene, project_101);
  //   const nameCountMap = new Map<string, number>();
  //   const itemsWithUniqueIds = items.map((item) => {
  //     const itemName = item.name || "Unnamed";
  //     const count = nameCountMap.get(itemName) || 0;
  //     nameCountMap.set(itemName, count + 1);
  //     const uniqueId = count > 0 ? `${itemName}_${count + 1}` : itemName;
  
  //     return { ...item, uniqueId };
  //   });
  
  //   console.log("These are the GLTF items with unique IDs:", itemsWithUniqueIds);
  //   setGLTFItemsState(itemsWithUniqueIds);
  // }, [scene, project_101]);

  useEffect(() => {
    const items = getGLTFItems2(scene, project_101);
    const nameCountMap = new Map<string, number>();
  
    const itemsWithUniqueIds = items.map((item) => {
      if (item.id) {
        return { ...item, uniqueId: item.id };
      } else {
        const itemName = item.name || "Unnamed";
        const count = nameCountMap.get(itemName) || 0;
        nameCountMap.set(itemName, count + 1);
        const uniqueId = count > 0 ? `${itemName}_${count + 1}` : itemName;
  
        return { ...item, uniqueId };
      }
    });
  
    console.log("These are the GLTF items with unique IDs:", itemsWithUniqueIds);
    setGLTFItemsState(itemsWithUniqueIds);
  }, [scene, project_101]);
  

  const filteredGLTFItems = useMemo(() => {
    return GLTFItemsState
      .map((item, index) => ({ ...item, originalIndex: index })) 
      .filter(item => item.Type?.toLowerCase() === activeTab);  
  }, [GLTFItemsState, activeTab]);

  useEffect(() => {
    const total = filteredGLTFItems.reduce((acc, item) => {
      const weight = parseFloat(item.Weight) || 0;
      return acc + weight;
    }, 0);
    setTotalWeight(total);
  }, [filteredGLTFItems]);

  const handleExportToCSV = () => {
    const selectedData = selectedItems.map(index => GLTFItemsState[index]);
    exportToCSV(selectedData, `export_${activeTab}.csv`);
  };

  const handleImportFromCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result;
      if (content) {
        const parsedData = parseCSV(content);
        setGLTFItemsState(prevItems => [...prevItems, ...parsedData]);
      }
    };
    reader.readAsText(file);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedItems(filteredGLTFItems.map((item) => item.uniqueId));
    } else {
      setSelectedItems([]);
    }
  };
  
  const handleSelectItem = (uniqueId: string) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(uniqueId)) {
        return prevSelected.filter(id => id !== uniqueId);
      } else {
        return [...prevSelected, uniqueId];
      }
    });
  };
  
  const handleDeleteSelected = () => {
    console.log("Deleting selected items:", selectedItems);
  
    setGLTFItemsState(prevItems => {
      const remainingItems = prevItems.filter(item => !selectedItems.includes(item.uniqueId));
      selectedItems.forEach(uniqueId => {
        const itemToRemove = prevItems.find(item => item.uniqueId === uniqueId);
        if (itemToRemove) {
          console.log("Attempting to remove item from scene:", itemToRemove);
          const removedItems = new Set(); 
          const removeItemFromScene = (parent) => {
            parent.children.forEach(child => {
              if (child.userData && child.userData.isGLTFObject) {
                const matchById = child.userData.id === itemToRemove.id;
                const matchByName = child.userData.name === itemToRemove.name && child.userData.projectname === itemToRemove.projectname;
  
                if ((matchById) && !removedItems.has(child)) {
                  console.log("Removing child from scene:", child.userData);
                  parent.remove(child); 
                  removedItems.add(child); 
                } else if (child.children.length > 0) {
                  removeItemFromScene(child); 
                }
              }
            });
          };
  
          removeItemFromScene(scene); 
        } else {
          console.log("Item not found in previous GLTF items:", uniqueId);
        }
      });
  
      return remainingItems;
    });
  
    setSelectedItems([]); 
  };
  
  
  
  const handleInputChange = (uniqueId: string, key: string, value: string) => {
    setGLTFItemsState(prevItems => {
      const updatedItems = prevItems.map(item => 
        item.uniqueId === uniqueId ? { ...item, [key]: value } : item
      );
      const updatedItem = updatedItems.find(item => item.uniqueId === uniqueId);
      if (updatedItem) {
        updateSceneWithNewItem(updatedItem); 
      }
      return updatedItems;
    });
  };
  
  const handlePositionChange = (uniqueId: string, axis: 'x' | 'y' | 'z', value: number) => {
    setGLTFItemsState(prevItems => {
      const updatedItems = prevItems.map(item => 
        item.uniqueId === uniqueId ? { ...item, position: { ...item.position, [axis]: value } } : item
      );
      const updatedItem = updatedItems.find(item => item.uniqueId === uniqueId);
      if (updatedItem) {
        updateSceneWithNewItem(updatedItem); 
      }
      return updatedItems;
    });
  };
  
  
  // const handlePositionChange = (index: number, axis: 'x' | 'y' | 'z', value: number) => {
  //   setGLTFItemsState(prevItems => {
  //     const updatedItems = [...prevItems];
  //     const updatedPosition = { ...updatedItems[index].position, [axis]: value };
  //     updatedItems[index] = { ...updatedItems[index], position: updatedPosition };
  //     updateSceneWithNewItem(index, updatedItems[index]); 
  //     return updatedItems;
  //   });
  // };
  
  // const handlePositionChange = (index: number, axis: 'x' | 'y' | 'z', value: number) => {
  //   setGLTFItemsState(prevItems => {
  //     const updatedItems = prevItems.map((item, i) => i === index ? { ...item, position: { ...item.position, [axis]: value } } : item);
  //     updateSceneWithNewItem(index, updatedItems[index]);
  //     return updatedItems;
  //   });
  // };
  
  const updateSceneWithNewItem = (newItem: any) => {
    const updateItemInScene = (parent: any) => {
        parent.children.forEach((child: any) => {
            if (child.userData && child.userData.isGLTFObject) {
                const matchById = child.userData.id === newItem.uniqueId; 
                const matchByName = child.userData.name === newItem.name && child.userData.projectname === newItem.projectname; 
                
                if (matchById) {
                    console.log("Updating position for item in scene:", child.userData);
                    child.position.set(newItem.position.x, newItem.position.y, newItem.position.z);
                } else if (child.children.length > 0) {
                    updateItemInScene(child);
                }
            }
        });
    };

    updateItemInScene(scene); 
};


  // const updateSceneWithNewItem = (index: number, newItem: any) => {
  //   const updateItemInScene = (parent: any) => {
  //       parent.children.forEach((child: any) => {
  //           if (child.userData && child.userData.isGLTFObject) {
  //               if (child.userData.name === newItem.name && child.userData.projectname === newItem.projectname) {
  //                   child.position.set(newItem.position.x, newItem.position.y, newItem.position.z);
  //               } else if (child.children.length > 0) {
  //                   updateItemInScene(child);
  //               }
  //           }
  //       });
  //   };
  //   updateItemInScene(scene);
  // };

  const getTableHeader = () => {
    return (
      <tr>
        <th><input type="checkbox" onChange={handleSelectAll} /></th>
        <th>ID</th><th>Name</th><th>Material</th>
        <th>Position X</th><th>Position Y</th><th>Position Z</th>
        {activeTab === 'wall' && (
          <>
            <th>Height(m)</th><th>Width(m)</th><th>Length(m)</th><th>Density</th><th>Weight(Kg)</th>
          </>
        )}
        {activeTab === 'slab' && (
          <>
            <th>Thickness(mm)</th><th>Width(m)</th><th>Length(m)</th><th>Density</th><th>Weight(Kg)</th>
          </>
        )}
        {activeTab === 'door' && (
          <>
            <th>Width(m)</th><th>Height(m)</th>
          </>
        )}
        {activeTab === 'window' && (
          <>
            <th>Glass Type</th><th>Height(m)</th><th>Length(m)</th>
          </>
        )}
        {activeTab === 'equipment' && (
          <>
            <th>Height(m)</th><th>Length(m)</th><th>Width(m)</th><th>Weight(Kg)</th>
          </>
        )}
      </tr>
    );
  };

  const getTableBody = () => {
    return filteredGLTFItems.map((item) => {
      const index = item.uniqueId;
      return (
        <tr key={index}>
          <td>
            <input
              className='w-60'
              type="checkbox"
              checked={selectedItems.includes(index)}
              onChange={() => handleSelectItem(index)}
            />
          </td>
          <td className='w-150'>{item.uniqueId || index}</td> 
          <td>
            <input
              className='w-150'
              type="text"
              value={item.name || ''}
              onChange={(e) => handleInputChange(index, 'name', e.target.value)}
            />
          </td>
          <td>
            <input
              className='w-150'
              type="text"
              value={item.Material || ''}
              onChange={(e) => handleInputChange(index, 'Material', e.target.value)}
            />
          </td>
          <td>
            <input
              className='w-90'
              type="number"
              step="0.01"
              value={item.position?.x.toFixed(2) || 0}
              onChange={(e) => handlePositionChange(index, 'x', parseFloat(e.target.value))}
            />
          </td>
          <td>
            <input
              className='w-90'
              type="number"
              step="0.01"
              value={item.position?.y.toFixed(2) || 0}
              onChange={(e) => handlePositionChange(index, 'y', parseFloat(e.target.value))}
            />
          </td>
          <td>
            <input
              className='w-90'
              type="number"
              step="0.01"
              value={item.position?.z.toFixed(2) || 0}
              onChange={(e) => handlePositionChange(index, 'z', parseFloat(e.target.value))}
            />
          </td>
          {activeTab === 'wall' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.length || ''}
                  onChange={(e) => handleInputChange(index, 'length', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Density || ''}
                  onChange={(e) => handleInputChange(index, 'Density', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Weight || ''}
                  onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'slab' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Thickness || ''}
                  onChange={(e) => handleInputChange(index, 'Thickness', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.length || ''}
                  onChange={(e) => handleInputChange(index, 'length', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Density || ''}
                  onChange={(e) => handleInputChange(index, 'Density', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Weight || ''}
                  onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'door' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'window' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.GlassType || ''}
                  onChange={(e) => handleInputChange(index, 'GlassType', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.length || ''}
                  onChange={(e) => handleInputChange(index, 'length', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'equipment' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Length || ''}
                  onChange={(e) => handleInputChange(index, 'Length', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Weight || ''}
                  onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                />
              </td>
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <CustomDlg
      title="Asset Manager"
      onClose={onClose}
      body={
        <div className="d-flex f-column f-grow">
          <div className='d-flex f-ai-center bg-dark label-light'>
            <Button text="Wall" onClick={() => setActiveTab('wall')} />
            <Button text="Slab" onClick={() => setActiveTab('slab')} />
            <Button text="Door" onClick={() => setActiveTab('door')} />
            <Button text="Window" onClick={() => setActiveTab('window')} />
            <Button text="Equipment" onClick={() => setActiveTab('equipment')} />
          </div>
          <div className='d-flex f-ai-center bg-dark label-light'>
            <Button small intent="danger" icon="delete" text="Delete Selected" onClick={handleDeleteSelected} disabled={selectedItems.length === 0} />
            <Button small intent="primary" icon="export" text="Export to CSV" onClick={handleExportToCSV} disabled={selectedItems.length === 0} />
            <Button small intent="success" icon="import" text="Import from CSV">
              <input type="file" accept=".csv" onChange={handleImportFromCSV} style={{ display: 'none' }} />
            </Button>
          </div>
          <div className="hr" />
          <div className={"p-5 bg-dark"}>
            <div className={"table-container"}>
              <table className="table bg-gray">
                <thead>
                  {getTableHeader()}
                </thead>
                <tbody>
                  {getTableBody()}
                  <tr style={{ backgroundColor: '#444', color: '#fff', fontWeight: 'bold' }}> 
                    <td colSpan={activeTab === 'equipment' ? 10 : activeTab === 'wall' ? 11 : activeTab === 'slab' ? 11 : 8} style={{ textAlign: 'right' }}>
                      Total Weight:
                    </td>
                    <td>
                      {totalWeight.toFixed(2)} Kg
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
        </div>
      }
    />
  );
}
