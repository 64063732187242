import GLTFLoader from "three-gltf-loader";
import * as THREE from "three";
import { OBJLoader } from "../../../pages/utils/OBJLoader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";
import { PLYExporter } from "three/examples/jsm/exporters/PLYExporter.js";
import { ColladaExporter } from "three/examples/jsm/exporters/ColladaExporter.js";
import { Color, Group, Mesh, MeshBasicMaterial, Object3D, Scene } from "three";
import saveAs from "file-saver";
import { MeshStandardMaterial } from "three";
import ConcreteFloorTexturePath from "../../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_BaseColor.jpg";
import ConcreteFloorNormalMapPath from "../../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_Normal.png";
import ConcreteFloorRoughnessMapPath from "../../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_Roughness.jpg";
import ConcreteFloorMetalnessMapPath from "../../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_Metallic.jpg";
import RoughWallTexturePath from "../../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_COL_1K_METALNESS.png";
import RoughWallNormalMapPath from "../../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_NRM_1K_METALNESS.png";
import RoughWallBumpMapPath from "../../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_BUMP_1K_METALNESS.png";
import RoughWallDisplacementMapPath from "../../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_DISP_1K_METALNESS.png";
import RoughWallRoughnessMapPath from "../../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_ROUGHNESS_1K_METALNESS.png";
import RoughWallMetalnessMapPath from "../../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_ROUGHNESS_1K_METALNESS.png";
import BrickWallTexturePath from "../../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_BaseColor.jpg";
import BrickWallNormalMapPath from "../../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_Normal.png";
import BrickWallDisplacementMapPath from "../../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_AmbientOcclusion.jpg";
import BrickWallRoughnessMapPath from "../../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_Roughness.jpg";
import BrickWallMetalnessMapPath from "../../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_Metallic.jpg";
import asphaltTexturePath from "../../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_COL_1K.jpg";
import asphaltNormalMapPath from "../../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_NRM_1K.jpg";
import asphaltBumpMapPath from "../../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_AO_1K.jpg";
import asphaltDisplacementMapPath from "../../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_DISP_1K.jpg";
import asphaltRoughnessMapPath from "../../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_REFL_1K.jpg";
import asphaltMetalnessMapPath from "../../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_REFL_1K.jpg";
import ConcreteTexturePath from "../../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_BaseColor.jpg";
import ConcreteNormalMapPath from "../../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_Normal.png";
import ConcreteRoughnessMapPath from "../../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_Roughness.jpg";
import ConcreteMetalnessMapPath from "../../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_Metallic.jpg";
import GrassTexturePath from "../../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_BaseColor.jpg";
import GrassNormalMapPath from "../../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_Normal.png";
import GrassRoughnessMapPath from "../../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_Roughness.jpg";
import GrassMetalnessMapPath from "../../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_Metallic.jpg";

import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { calculateShortestPath } from "../3d-modeling/product-sorting-simulation/helper/shortestPossiblePath";
import { TPSSState } from "../../../store/pss/types";
import {JsonStreamStringify} from "json-stream-stringify";

const glTF = new GLTFExporter();
const ply = new PLYExporter();
const collada = new ColladaExporter();

const UserDataType = {
	Type: String,
	texture: String,
};

function removeElement(scene: Scene, name: string) {
	const element = scene.getObjectByName(name);
	while (element && element.parent) {
		element.parent.remove(element);
	}
}

export function fixSceneForConverting(scene: Scene) {
	const fixed = scene.clone();
	removeElement(fixed, "AxesBoxHelper");
	removeElement(fixed, "DROP-CIRCLE");
	removeElement(fixed, "DROP-CYLINDER-X");
	removeElement(fixed, "DROP-CYLINDER-Y");
	removeElement(fixed, "DROP-CYLINDER-Z");
	removeElement(fixed, "PIPE-ELEMENT-ANCHOR");
	removeElement(fixed, "COORDS");
	removeElement(fixed, "CENTER-COORDS");
	removeElement(fixed, "DISTANCE-HELPER");
	removeElement(fixed, "SkyBox");
	removeElement(fixed, "Concrete_Plane");
	removeElement(fixed, "GridHelper");
	removeElement(fixed, "DirectionalLight");
	removeElement(fixed, "PointLight");
	// fixed.scale.multiplyScalar(1000);
	return fixed;
}

function pointsAreEqual(p1, p2, epsilon = 1e-6) {
	return (
		Math.abs(p1.x - p2.x) < epsilon &&
		Math.abs(p1.y - p2.y) < epsilon &&
		Math.abs(p1.z - p2.z) < epsilon
	);
}

let currentId = 0;

function getNextUniqueId() {
	currentId += 1;
	return currentId;
}

// export function exportToGLTF(scene: Scene, name: string, pss: TPSSState) {
// 	try {
// 		const { factoryPaths, humans, routes } = pss.simulations[0];
// 		const factoryPathPoints = factoryPaths.map((fp) => ({
// 			id: fp.id,
// 			description: fp.description,
// 			x: fp.x,
// 			y: fp.y,
// 			z: fp.z,
// 		}));

// 		try {
// 			for (const route of routes) {
// 				route.operator = route.operator || [];
// 				route.waypoints = route.waypoints || [];

// 				if (route.operator.length === 0 || route.waypoints.length === 0) {
// 					console.warn(
// 						`Route ${route.id} has no operator or waypoints assigned.`
// 					);
// 					continue;
// 				}

// 				const visitPoints = route.waypoints.map((wp) => ({
// 					x: wp.x,
// 					y: wp.y,
// 					z: wp.z,
// 				}));

// 				// // API Call to calculate shortest path
// 				// const response = await axios.post(`${secondServerAPI}/short/shortest_path`, {
// 				//   factoryPoints: factoryPathPoints,
// 				//   visitPoints: visitPoints,
// 				// });

// 				const result = calculateShortestPath(factoryPathPoints, visitPoints);

// 				console.log('shortest path result', result);

// 				route.operator.forEach((operatorId) => {
// 					const operator = humans.find((op) => op.id === operatorId);
// 					if (!operator) {
// 						console.warn(`Operator with id ${operatorId} not found.`);
// 						return;
// 					}

// 					const updatedPath = result.path.map((point) => {
// 						let waitTime = 0;

// 						for (const wp of route.waypoints) {
// 							const waypointPoint = { x: wp.x, y: wp.y, z: wp.z };

// 							if (pointsAreEqual(point, waypointPoint)) {
// 								waitTime = wp.duration || 0;
// 								break;
// 							}
// 						}

// 						return {
// 							id: getNextUniqueId(),
// 							x: point.x,
// 							y: point.y,
// 							z: point.z,
// 							waitTime,
// 						};
// 					});

//           console.log(updatedPath);

// 					const updatedHuman = {
// 						...operator,
// 						path: updatedPath,
// 					};

// 					pss.simulations[0].humans = pss.simulations[0].humans.map((h) =>
// 						h.id === updatedHuman.id ? updatedHuman : h
// 					);
// 				});

// 				console.log(
// 					`Shortest path for Route ${route.id} calculated with cost: ${result.cost}`
// 				);
// 			}

// 			// Reset before starting animations
// 			alert("Shortest paths calculated for all routes.");
// 		} catch (error) {
// 			console.error("Error calculating shortest path:", error);
// 			alert("An error occurred while calculating the shortest path.");
// 		}

// 		const fixed = fixSceneForConverting(scene);

//     fixed.userData = {
//       pss
//     }
//     console.log(pss)
// 		glTF.parse(
// 			fixed,
// 			(obj) => {
// 				console.log(obj);
// 				const data = new Blob([JSON.stringify(obj)]);
// 				saveAs(data, `${name}.idsv`);
// 			},
// 			{}
// 		);
// 	} catch (error) {
// 		console.error(error);
// 	}
// }

function createSceneWithoutObjects(
	originalScene: THREE.Scene,
	excludeNames: string[]
): THREE.Scene {
	const newScene = new THREE.Scene();

	originalScene.children.forEach((child) => {
		if (!excludeNames.includes(child.name)) {
			newScene.add(child.clone());
		}
	});

	excludeNames.forEach((name) => {
		const element = originalScene.getObjectByName(name);
		if (element && element instanceof THREE.Mesh) {
			element.geometry?.dispose();
			if (Array.isArray(element.material)) {
				element.material.forEach((mat) => mat.dispose());
			} else {
				element.material?.dispose();
			}
		}
	});

	return newScene;
}

// export function exportToGLTF(scene: Scene, name: string, pss: TPSSState) {
// 	try {
// 		const exlusiveObjects = [
// 			"HUMAN",
// 			"AxesBoxHelper",
// 			"DROP-CIRCLE",
// 			"DROP-CYLINDER-X",
// 			"DROP-CYLINDER-Y",
// 			"DROP-CYLINDER-Z",
// 			"PIPE-ELEMENT-ANCHOR",
// 			"COORDS",
// 			"CENTER-COORDS",
// 			"DISTANCE-HELPER",
// 			"SkyBox",
// 			"Concrete_Plane",
// 			"GridHelper",
// 			"DirectionalLight",
// 			"PointLight",
// 		];
// 		const fixedScene = createSceneWithoutObjects(scene, exlusiveObjects);
// 		fixedScene.userData = {
// 			pss,
// 		};
// 		console.log(fixedScene);
// 		glTF.parse(
// 			fixedScene,
// 			(obj) => {
// 				console.log(obj);
// 				const data = new Blob([JSON.stringify(obj)]);
// 				saveAs(data, `${name}.idsv`);
// 			},
// 			{}
// 		);
// 	} catch (error) {
// 		console.error(error);
// 	}
// }

export function exportToGLTF(scene: Scene, name: string, pss: TPSSState) {
    try {
        const exclusiveObjects = [
            "HUMAN",
            "AxesBoxHelper",
            "DROP-CIRCLE",
            "DROP-CYLINDER-X",
            "DROP-CYLINDER-Y",
            "DROP-CYLINDER-Z",
            "PIPE-ELEMENT-ANCHOR",
            "COORDS",
            "CENTER-COORDS",
            "DISTANCE-HELPER",
            "SkyBox",
            "Concrete_Plane",
            "GridHelper",
            "DirectionalLight",
            "PointLight",
        ];

        // Remove unwanted objects from the scene
        const fixedScene = createSceneWithoutObjects(scene, exclusiveObjects);
        fixedScene.userData = {
            pss,
        };

        // Export the scene
        glTF.parse(
            fixedScene,
            (result) => {
                console.log("GLTF Exporter Result", result);

                // Use JSONStreamStringify for incremental stringification
                const stream = new JsonStreamStringify(result); 

                const chunks = [];
                stream.on("data", (chunk) => {
                    chunks.push(chunk);
                });

                stream.on("end", () => {
                    // Combine all chunks and create a Blob
                    const blob = new Blob(chunks, { type: "application/json" });
                    saveAs(blob, `${name}.idsv`);
                });

                stream.on("error", (error) => {
                    console.error("Error during streaming JSON:", error);
                });
            },
            {}
        );
    } catch (error) {
        console.error("Error in exportToGLTF:", error);
    }
}



// export function exportToGLTF(scene: Scene, name: string, pss: TPSSState) {
// 	try {
// 		const { factoryPaths, humans, routes } = pss.simulations[0];
// 		const factoryPathPoints = factoryPaths.map((fp) => ({
// 			id: fp.id,
// 			description: fp.description,
// 			x: fp.x,
// 			y: fp.y,
// 			z: fp.z,
// 		}));

// 		try {
// 			for (const route of routes) {
// 				route.operator = route.operator || [];
// 				route.waypoints = route.waypoints || [];

// 				if (route.operator.length === 0 || route.waypoints.length === 0) {
// 					console.warn(
// 						`Route ${route.id} has no operator or waypoints assigned.`
// 					);
// 					continue;
// 				}

// 				const visitPoints = route.waypoints.map((wp) => ({
// 					x: wp.x,
// 					y: wp.y,
// 					z: wp.z,
// 				}));

// 				const result = calculateShortestPath(factoryPathPoints, visitPoints);

// 				console.log('shortest path result', result);

// 				route.operator.forEach((operatorId) => {
// 					const operator = humans.find((op) => op.id === operatorId);
// 					if (!operator) {
// 						console.warn(`Operator with id ${operatorId} not found.`);
// 						return;
// 					}

// 					const updatedPath = result.path.map((point) => {
// 						let waitTime = 0;

// 						for (const wp of route.waypoints) {
// 							const waypointPoint = { x: wp.x, y: wp.y, z: wp.z };

// 							if (pointsAreEqual(point, waypointPoint)) {
// 								waitTime = wp.duration || 0;
// 								break;
// 							}
// 						}

// 						return {
// 							id: getNextUniqueId(),
// 							x: point.x,
// 							y: point.y,
// 							z: point.z,
// 							waitTime,
// 						};
// 					});

// 					console.log(updatedPath);

// 					const updatedHuman = {
// 						...operator,
// 						path: updatedPath,
// 					};

// 					pss.simulations[0].humans = pss.simulations[0].humans.map((h) =>
// 						h.id === updatedHuman.id ? updatedHuman : h
// 					);
// 				});

// 				console.log(
// 					`Shortest path for Route ${route.id} calculated with cost: ${result.cost}`
// 				);
// 			}

// 			// Reset before starting animations
// 			alert("Shortest paths calculated for all routes.");
// 		} catch (error) {
// 			console.error("Error calculating shortest path:", error);
// 			alert("An error occurred while calculating the shortest path.");
// 		}

// 		// Filter out objects with "HUMAN" from the scene before export
// 		const filteredScene = scene.clone(); // Clone the scene to avoid modifying the original
// 		filteredScene.traverse((object) => {
// 			if (object.name.includes("HUMAN")) {
// 				if (object.parent) {
// 					object.parent.remove(object);
// 				}
// 			}
// 		});

// 		// Custom safe stringify to handle circular references
// 		function safeStringify(obj) {
// 			const seen = new WeakSet();
// 			return JSON.stringify(obj, (key, value) => {
// 				if (typeof value === "object" && value !== null) {
// 					if (seen.has(value)) {
// 						return; // Skip circular references
// 					}
// 					seen.add(value);
// 				}
// 				return value;
// 			});
// 		}

// 		const fixed = fixSceneForConverting(filteredScene);

// 		fixed.userData = {
// 			pss,
// 		};
// 		console.log(pss);
// 		glTF.parse(
// 			fixed,
// 			(obj) => {
// 				try {
// 					const jsonString = safeStringify(obj); // Use safe stringify
// 					console.log(jsonString);
// 					const data = new Blob([jsonString]);
// 					saveAs(data, `${name}.idsv`);
// 				} catch (error) {
// 					console.error("Error stringifying JSON:", error);
// 					alert("An error occurred during export.");
// 				}
// 			},
// 			{}
// 		);
// 	} catch (error) {
// 		console.error(error);
// 	}
// }



export function exportToPLY(scene: Scene, name: string) {
	const fixed = fixSceneForConverting(scene);
	ply.parse(
		fixed,
		(obj) => {
			console.log(obj);
			const data = new Blob([obj]);
			saveAs(data, `${name}.ply`);
		},
		{}
	);
}

export function exportToCollada(scene: Scene, name: string) {
	const fixed = fixSceneForConverting(scene);
	collada.parse(
		fixed,
		(obj) => {
			console.log(obj);
			const data = new Blob([JSON.stringify(obj)]);
			saveAs(data, `${name}.dae`);
		},
		{}
	);
}

export function loadGLTF(file: File, callback: (scene: Scene) => any) {
	console.log("loadgltf started");
	const loader = new GLTFLoader();

	file
		.arrayBuffer()
		.then((buffer) => {
			const isGLB = new DataView(buffer).getUint32(0, true) === 0x46546c67;
			const parseFunction = isGLB
				? loader.parse.bind(loader)
				: loader.parse.bind(
						loader,
						JSON.parse(new TextDecoder().decode(buffer)),
						""
				  );

			parseFunction(buffer, "", (gltf) => {
				console.log("load gltf inside the loop");
				gltf.scene.traverse((node: Object3D) => {
					if (node instanceof Mesh) {
						const mesh = node as Mesh;
						const materials: Material[] = Array.isArray(mesh.material)
							? mesh.material
							: [mesh.material];
						materials.forEach((material) => {
							if (material instanceof MeshStandardMaterial) {
								material.color = material.color || new THREE.Color(0xffffff);
								material.map = material.map || null;
								material.metalness =
									material.metalness !== undefined ? material.metalness : 0.5;
								material.roughness =
									material.roughness !== undefined ? material.roughness : 0.5;
								material.emissive =
									material.emissive || new THREE.Color(0x000000);
								material.emissiveIntensity =
									material.emissiveIntensity !== undefined
										? material.emissiveIntensity
										: 1;
								material.envMap = material.envMap || null;
								material.color = new Color(
									material.color.getHex()
								).multiplyScalar(1.5);
								material.emissive = material.emissive
									.clone()
									.multiplyScalar(1.1);
								material.emissiveIntensity = Math.min(
									material.emissiveIntensity * 1.1,
									1
								);
								material.needsUpdate = true;
							}
						});
					}
				});
				callback(gltf.scene);
			});
		})
		.catch((error) => {
			console.error("Error loading GLTF/GLB file:", error);
		});
}

// export function loadGLTF2(file: File, project: string, callback: (scene: Scene) => any) {
//   const loader = new GLTFLoader();
//   file.arrayBuffer().then((buffer) => {
//     const isGLB = new DataView(buffer).getUint32(0, true) === 0x46546c67;
//     const parseFunction = isGLB ? loader.parse.bind(loader) : loader.parse.bind(loader, JSON.parse(new TextDecoder().decode(buffer)), "");

//     parseFunction(buffer, "", (gltf) => {
//       const filteredScene = new Scene();

//       gltf.scene.traverse((child: Object3D) => {
//         if ((child as Mesh).isMesh) {
//           const materials = Array.isArray(child.material) ? child.material : [child.material];
//           materials.forEach((material) => {
//             if (material instanceof MeshStandardMaterial) {
//               material.color = new Color(material.color.getHex()).multiplyScalar(1.5);
//               material.emissive = material.emissive.clone().multiplyScalar(1.1);
//               material.emissiveIntensity = Math.min(material.emissiveIntensity * 1.1, 1);
//               material.needsUpdate = true;
//             }
//           });

//           (child as Mesh).userData = {
//             ...child.userData,
//             isGLTFObject: true,
//             name: file.name,
//             projectname: project,
//             ClippedAsset: child.userData.ClippedAsset !== undefined ? child.userData.ClippedAsset : false,
//             Type: child.userData.Type !== undefined ? child.userData.Type : undefined,
//             Height : "",
//             Width : "",
//             length : "",
//             Volume : "",
//             SurfaceArea : "",
//             Weight : "",
//             Density : "",
//           };
//           filteredScene.add(child);
//         }
//       });

//       callback(filteredScene);
//     });
//   }).catch((error) => {
//     console.error('Error loading GLTF/GLB file:', error);
//   });
// }

export function loadGLTF2(
	file: File,
	project: string,
	callback: (scene: Scene) => any,
	userData: any = {}
) {
	const loader = new GLTFLoader();

	const reader = new FileReader();

	reader.onload = function(event) {
		const contents = event.target?.result as string | ArrayBuffer;

		if (typeof contents === "string") {
			loader.parse(
				contents,
				"",
				(gltf) => {
					processGLTFScene(gltf.scene);
				},
				(error) => {
					console.error("Error parsing GLTF file:", error);
				}
			);
		} else if (contents instanceof ArrayBuffer) {
			loader.parse(
				contents,
				"",
				(gltf) => {
					processGLTFScene(gltf.scene);
				},
				(error) => {
					console.error("Error parsing IDSV file:", error);
				}
			);
		}
	};

	reader.onerror = function(error) {
		console.error("Error reading the IDSV file:", error);
	};
	reader.readAsArrayBuffer(file);

	function processGLTFScene(scene: Scene) {
		const filteredScene = new Scene();

		scene.traverse((child: Object3D) => {
			if ((child as Mesh).isMesh) {
				const meshChild = child as Mesh;
				const materials = Array.isArray(meshChild.material)
					? meshChild.material
					: [meshChild.material];
				materials.forEach((material) => {
					if (material instanceof MeshStandardMaterial) {
						material.color = new Color(material.color.getHex()).multiplyScalar(
							1.5
						);
						material.emissive = material.emissive.clone().multiplyScalar(1.1);
						material.emissiveIntensity = Math.min(
							material.emissiveIntensity * 1.1,
							1
						);
						material.needsUpdate = true;
					}
				});

				if (userData.Type && userData.texture) {
					applyTextureToMesh(meshChild, userData.texture);
				}
				meshChild.userData = {
					...meshChild.userData,
					isGLTFObject: true,
					name: file.name,
					projectname: project,
					ClippedAsset:
						meshChild.userData.ClippedAsset !== undefined
							? meshChild.userData.ClippedAsset
							: false,
					Type: userData.Type !== undefined ? userData.Type : undefined,
					texture:
						userData.texture !== undefined ? userData.texture : undefined,
					Height: userData.Height !== undefined ? userData.Height : undefined,
					Width: userData.Width !== undefined ? userData.Width : undefined,
					length: userData.length !== undefined ? userData.length : undefined,
					Volume: userData.Volume !== undefined ? userData.Volume : undefined,
					SurfaceArea:
						userData.SurfaceArea !== undefined
							? userData.SurfaceArea
							: undefined,
					Weight: userData.Weight !== undefined ? userData.Weight : undefined,
					Density:
						userData.Density !== undefined ? userData.Density : undefined,
				};
				filteredScene.add(meshChild);
			}
		});
		callback(filteredScene);
	}

	function applyTextureToMesh(mesh: THREE.Mesh, textureType: string) {
		const textureLoader = new THREE.TextureLoader();

		let texturePath: string | null = null;
		let normalMapPath: string | null = null;
		let roughnessMapPath: string | null = null;
		let metalnessMapPath: string | null = null;
		let bumpMapPath: string | null = null;
		let displacementMapPath: string | null = null;

		// Clone the material to prevent affecting other meshes that share the same material
		if (mesh.material.isMaterial) {
			mesh.material = mesh.material.clone();
		}

		// Define texture paths based on the selected texture type
		switch (textureType) {
			case "concrete_wall":
				texturePath = ConcreteFloorTexturePath;
				normalMapPath = ConcreteFloorNormalMapPath;
				roughnessMapPath = ConcreteFloorRoughnessMapPath;
				metalnessMapPath = ConcreteFloorMetalnessMapPath;
				break;
			case "Rough_Wall":
				texturePath = RoughWallTexturePath;
				normalMapPath = RoughWallNormalMapPath;
				bumpMapPath = RoughWallBumpMapPath;
				displacementMapPath = RoughWallDisplacementMapPath;
				roughnessMapPath = RoughWallRoughnessMapPath;
				metalnessMapPath = RoughWallMetalnessMapPath;
				break;
			case "brick_wall":
				texturePath = BrickWallTexturePath;
				normalMapPath = BrickWallNormalMapPath;
				displacementMapPath = BrickWallDisplacementMapPath;
				roughnessMapPath = BrickWallRoughnessMapPath;
				metalnessMapPath = BrickWallMetalnessMapPath;
				break;
			case "asphalt":
				texturePath = asphaltTexturePath;
				normalMapPath = asphaltNormalMapPath;
				bumpMapPath = asphaltBumpMapPath;
				displacementMapPath = asphaltDisplacementMapPath;
				roughnessMapPath = asphaltRoughnessMapPath;
				metalnessMapPath = asphaltMetalnessMapPath;
				break;
			case "concrete":
				texturePath = ConcreteTexturePath;
				normalMapPath = ConcreteNormalMapPath;
				roughnessMapPath = ConcreteRoughnessMapPath;
				metalnessMapPath = ConcreteMetalnessMapPath;
				break;
			case "grass":
				texturePath = GrassTexturePath;
				normalMapPath = GrassNormalMapPath;
				roughnessMapPath = GrassRoughnessMapPath;
				metalnessMapPath = GrassMetalnessMapPath;
				break;
			default:
				return; // Exit if no valid texture type is selected
		}

		// Load and apply the base texture map
		if (texturePath) {
			textureLoader.load(texturePath, (texture) => {
				if (mesh.material.map) mesh.material.map.dispose(); // Dispose of the previous map
				mesh.material.map = texture;
				mesh.material.needsUpdate = true;
			});
		}

		// Load and apply the normal map
		if (normalMapPath) {
			textureLoader.load(normalMapPath, (normalMap) => {
				if (mesh.material.normalMap) mesh.material.normalMap.dispose();
				mesh.material.normalMap = normalMap;
				mesh.material.needsUpdate = true;
			});
		}

		// Load and apply the roughness map
		if (roughnessMapPath) {
			textureLoader.load(roughnessMapPath, (roughnessMap) => {
				if (mesh.material.roughnessMap) mesh.material.roughnessMap.dispose();
				mesh.material.roughnessMap = roughnessMap;
				mesh.material.needsUpdate = true;
			});
		}

		// Load and apply the metalness map
		if (metalnessMapPath) {
			textureLoader.load(metalnessMapPath, (metalnessMap) => {
				if (mesh.material.metalnessMap) mesh.material.metalnessMap.dispose();
				mesh.material.metalnessMap = metalnessMap;
				mesh.material.needsUpdate = true;
			});
		}

		// Load and apply the bump map
		if (bumpMapPath) {
			textureLoader.load(bumpMapPath, (bumpMap) => {
				if (mesh.material.bumpMap) mesh.material.bumpMap.dispose();
				mesh.material.bumpMap = bumpMap;
				mesh.material.bumpScale = 0.1; // Adjust bump scale as needed
				mesh.material.needsUpdate = true;
			});
		}

		// Load and apply the displacement map
		if (displacementMapPath) {
			textureLoader.load(displacementMapPath, (displacementMap) => {
				if (mesh.material.displacementMap)
					mesh.material.displacementMap.dispose();
				mesh.material.displacementMap = displacementMap;
				mesh.material.displacementScale = 0.2; // Adjust displacement scale as needed
				mesh.material.needsUpdate = true;
			});
		}
	}

	// function processGLTFScene(scene: Scene) {
	//   const filteredScene = new Scene();

	//   scene.traverse((child: Object3D) => {
	//     if ((child as Mesh).isMesh) {
	//       const meshChild = child as Mesh;
	//       const materials = Array.isArray(meshChild.material) ? meshChild.material : [meshChild.material];
	//       materials.forEach((material) => {
	//         if (material instanceof MeshStandardMaterial) {
	//           material.color = new Color(material.color.getHex()).multiplyScalar(1.5);
	//           material.emissive = material.emissive.clone().multiplyScalar(1.1);
	//           material.emissiveIntensity = Math.min(material.emissiveIntensity * 1.1, 1);
	//           material.needsUpdate = true;
	//         }
	//       });
	//       meshChild.userData = {
	//         ...meshChild.userData,
	//         isGLTFObject: true,
	//         name: file.name,
	//         projectname: project,
	//         ClippedAsset: meshChild.userData.ClippedAsset !== undefined ? meshChild.userData.ClippedAsset : false,
	//         Type: meshChild.userData.Type !== undefined ? meshChild.userData.Type : undefined,
	//         texture: meshChild.userData.Texture !== undefined ? meshChild.userData.Texture : undefined,
	//         Height: "",
	//         Width: "",
	//         length: "",
	//         Volume: "",
	//         SurfaceArea: "",
	//         Weight: "",
	//         Density: "",
	//       };
	//       filteredScene.add(meshChild);
	//     }
	//   });
	//   callback(filteredScene);
	// }
}

export function loadOBJ(
	file: File,
	project: string,
	callback: (scene: Scene) => any
) {
	const loader = new OBJLoader();
	const filteredScene = new Scene();
	loader.load(
		URL.createObjectURL(file),
		(object: THREE.Group) => {
			const material = new MeshBasicMaterial({ color: 0x797979 });
			const meshes: THREE.Mesh[] = [];
			object.traverse((child) => {
				if ((child as THREE.Mesh).isMesh) {
					const mesh = child as THREE.Mesh;
					meshes.push(mesh);
				}
			});
			meshes.forEach((mesh) => {
				mesh.material = material;
				filteredScene.add(mesh);
			});
			console.log("this is a scene", filteredScene);
		},
		callback(filteredScene),
		(error: Error) => {
			console.error("An error happened", error);
		}
	);
}
