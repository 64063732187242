import React, { useEffect, useMemo, useRef } from "react";
import { Project } from "../../../../../store/main/types";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { getOFModels } from "../../../../3d-models/openFrame";
import { changeOFUIAction } from "../../../../../store/ui/actions";
import { GeneralCheckBoxCell } from "../../../../common/GeneralCheckBoxCell";
import { Button } from "@blueprintjs/core";
import { CheckBoxCell } from "../../../../common/CheckBoxCell";
import { OFTrussUI } from "../../../../../store/ui/types";
import { SelectorCell } from "../../../../common/SelectorCell";
import {
  convertToNamesArray,
  getElementByName,
  getNextId,
  importFromCSV,
  getCurrentUI,
  exportToCSV,
} from "../../../../3d-models/utils";
import { NumericCell } from "../../../../common/NumericCell";
import { changeModel } from "../../../../../store/main/actions";
import {
  TOpenFrame,
  TTrussOF,
  TTrussType,
} from "../../../../../store/main/openFrameTypes";
import { Section } from "../../../../../store/data/types";

type Props = {
  project?: Project;
  profiles: Section[];
  libs: string[];
};

export function Truss(props: Props) {
  const { project, profiles, libs } = props;

  const openFrameUI = useSelector(
    (state: ApplicationState) => getCurrentUI(state)?.openFrameUI
  );

  const dispatch = useDispatch();

  const tableRef = useRef<HTMLTableElement>(null);

  const models = useMemo(() => {
    return getOFModels(project);
  }, [project]);

  const data = useMemo(() => {
    return (openFrameUI?.truss ?? []).filter(
      (el) => !el.model || models.some((m) => m.name === el.model)
    );
  }, [openFrameUI, models]);

  function handleChangeModel(model: TOpenFrame) {
    dispatch(changeModel(model));
  }

  function handleChangeData(truss: OFTrussUI[]) {
    if (!openFrameUI) return;
    dispatch(changeOFUIAction({ ...openFrameUI, truss }));
  }

  // function handleAddRow() {
  //   handleChangeData([
  //     ...data,
  //     {
  //       id: getNextId(data),
  //       selected: false,
  //       offset: 0,
  //       slope: 30,
  //       span: 0,
  //       spacing: 0,
  //       numbers: 1,
  //     },
  //   ]);
  // }

  function handleAddRow() {
    handleChangeData([
      ...data,
      {
        id: getNextId(data),
        selected: false,
        offset: 0,
        slope: 30,
        span: 0,
        spacing: 0,
        numbers: 1,
        tieMember: false,
        verticalPost: 0,
        inclinedMember: 0,
      },
    ]);
  }
  

  // function handleCreateElements(row: OFTrussUI, model: TOpenFrame) {
  //   if (
  //     !row.type ||
  //     !row.from ||
  //     !row.inclinedProfile ||
  //     !row.tieProfile ||
  //     !row.rafterProfile ||
  //     !row.verticalProfile
  //   )
  //     return model;
  //   const id = getNextId(model.truss ?? []);
  //   const el: TTrussOF = {
  //     id,
  //     uiId: row.id,
  //     from: row.from,
  //     type: row.type,
  //     slope: row.slope,
  //     span: row.span,
  //     spacing: row.spacing,
  //     offset: row.offset,
  //     numbers: row.numbers,
  //     tie: row.tieProfile,
  //     rafter: row.rafterProfile,
  //     inclined: row.inclinedProfile,
  //     vertical: row.verticalProfile,
  //   };
  //   const changedModel: TOpenFrame = {
  //     ...model,
  //     truss: model.truss ? [...model.truss, el] : [el],
  //   };
  //   return changedModel;
  // }


  function handleCreateElements(row: OFTrussUI, model: TOpenFrame) {
    if (!row.type || !row.from || !row.inclinedProfile || !row.tieProfile || !row.rafterProfile || !row.verticalProfile)
      return model;
  
    const id = getNextId(model.truss ?? []);
    const el: TTrussOF = {
      id,
      uiId: row.id,
      from: row.from,
      type: row.type,
      slope: row.slope,
      span: row.span,
      spacing: row.spacing,
      offset: row.offset,
      numbers: row.numbers,
      tie: row.tieProfile,
      rafter: row.rafterProfile,
      inclined: row.inclinedProfile,
      vertical: row.verticalProfile,
      tieMember: row.tieMember,
      verticalPost: row.verticalPost,
      inclinedMember: row.inclinedMember,
      verticalPostDistance: row.verticalPostDistance, 
      struttingBeamCount: row.struttingBeamCount,
    };
    const changedModel: TOpenFrame = {
      ...model,
      truss: model.truss ? [...model.truss, el] : [el],
    };
  
    return changedModel;
  }
  
  


  // function handleChangeRow(
  //   row: OFTrussUI,
  //   field: string,
  //   value: any,
  //   isHard?: boolean
  // ) {
  //   const changedRow = { ...row, [field]: value };
  //   handleChangeData(
  //     data.map((element) => {
  //       if (element.id === changedRow.id) {
  //         return changedRow;
  //       } else return element;
  //     })
  //   );
  //   if (field === "selected" || field === "library") return;
  //   let model = getElementByName(models, row.model);
  //   if (
  //     !changedRow.model ||
  //     !changedRow.from ||
  //     !changedRow.type ||
  //     !changedRow.rafterProfile ||
  //     !changedRow.tieProfile ||
  //     !changedRow.verticalProfile ||
  //     !changedRow.inclinedProfile
  //   )
  //     return;
  //   if (row.model !== changedRow.model) {
  //     model = getElementByName(models, changedRow.model);
  //   }
  //   if (!model) return;
  //   if (isHard) {
  //     const cp = handleDeleteModels([changedRow], project);
  //     // @ts-ignore
  //     model = cp?.models.find((m) => m.name === model?.name);
  //     model && handleChangeModel(handleCreateElements(changedRow, model));
  //   } else if (model.truss?.some((item) => item.uiId === changedRow.id)) {
  //     const changedModel: TOpenFrame = {
  //       ...model,
  //       truss:
  //         model.truss?.map((item) => {
  //           if (item.uiId === row.id) {
  //             return {
  //               ...item,
  //               rafter: changedRow.rafterProfile!,
  //               tie: changedRow.tieProfile!,
  //               vertical: changedRow.verticalProfile!,
  //               inclined: changedRow.inclinedProfile!,
  //             };
  //           }
  //           return item;
  //         }) ?? [],
  //     };
  //     handleChangeModel(changedModel);
  //   } else {
  //     handleChangeModel(handleCreateElements(changedRow, model));
  //   }
  // }

  function handleChangeRow(row: OFTrussUI, field: string, value: any, isHard?: boolean) {
    const changedRow = { ...row, [field]: value };
    if (field === 'verticalPost' || field === 'verticalPostDistance' || field === 'span') {
      if (field === 'verticalPost') {
        const numOfPosts = value ?? 0;
        if (numOfPosts > 0 && (!changedRow.verticalPostDistance || changedRow.verticalPostDistance.length !== numOfPosts - 1)) {
          changedRow.verticalPostDistance = Array(numOfPosts - 1).fill(0);
        }
      }
      const totalDistance = (changedRow.verticalPostDistance ?? []).reduce((sum, dist) => sum + dist, 0);
      // if (totalDistance > (changedRow.span ?? 0)) {
      //   alert("The total distance between vertical posts cannot exceed the span.");
      //   return; 
      // }
    }
    handleChangeData(
      data.map((element) => {
        return element.id === changedRow.id ? changedRow : element;
      })
    );
    if (field !== "selected" && field !== "library") {
      let model = getElementByName(models, row.model);
      if (
        !changedRow.model ||
        !changedRow.from ||
        !changedRow.type ||
        !changedRow.rafterProfile ||
        !changedRow.tieProfile ||
        !changedRow.verticalProfile ||
        !changedRow.inclinedProfile
      )
        return;
      if (row.model !== changedRow.model) {
        model = getElementByName(models, changedRow.model);
      }
  
      if (!model) return;
      if (isHard) {
        const cp = handleDeleteModels([changedRow], project);
        model = cp?.models.find((m) => m.name === model?.name);
        if (model) {
          handleChangeModel(handleCreateElements(changedRow, model));
        }
      } else if (model.truss?.some((item) => item.uiId === changedRow.id)) {
        const changedModel: TOpenFrame = {
          ...model,
          truss: model.truss?.map((item) => {
            if (item.uiId === row.id) {
              return {
                ...item,
                rafter: changedRow.rafterProfile!,
                tie: changedRow.tieProfile!,
                vertical: changedRow.verticalProfile!,
                inclined: changedRow.inclinedProfile!,
                tieMember: changedRow.tieMember,
                verticalPost: changedRow.verticalPost,
                inclinedMember: changedRow.inclinedMember,
                verticalPostDistance: changedRow.verticalPostDistance,
                struttingBeamCount: changedRow.struttingBeamCount,
              };
            }
            return item;
          }) ?? [],
        };
        handleChangeModel(changedModel);
      } else {
        handleChangeModel(handleCreateElements(changedRow, model));
      }
    }
  }
  
  

  function handleDeleteRows() {
    handleChangeData(data.filter((item) => !item.selected));
    handleDeleteModels(data.filter((item) => item.selected));
  }

  function handleDeleteModels(elements: OFTrussUI[], project?: Project) {
    const map = new Map<string, number[]>();
    let changedProject = project ? { ...project } : undefined;
    for (const element of elements) {
      if (!element.model) continue;
      const ids = map.get(element.model);
      map.set(element.model, ids ? [...ids, element.id] : [element.id]);
    }
    for (const [key, ids] of Array.from(map.entries())) {
      const model = getElementByName(models, key);
      if (!model) continue;
      let newModel = { ...model };
      for (const id of ids) {
        newModel = {
          ...newModel,
          truss: newModel.truss?.filter((item) => item.uiId !== id) ?? [],
        };
      }
      if (changedProject) {
        changedProject = {
          ...changedProject,
          models: changedProject.models.map((mItem) =>
            mItem.name === newModel.name ? newModel : mItem
          ),
        };
      } else handleChangeModel(newModel);
    }
    return changedProject;
  }
  

  function getRow(row: OFTrussUI) {
    const model = getElementByName(models, row.model);
    const beams = model?.beams ?? [];
    const isPitchedRoof = row.type === "Pitched Roof";
    return (
      <tr key={row.id}>
        <CheckBoxCell
          key={row.id}
          value={row.selected}
          onChange={(value) => handleChangeRow(row, "selected", value)}
        />
        <SelectorCell<string>
          items={convertToNamesArray(models)}
          itemKey={(item) => item}
          itemLabel={(item) => item}
          selected={row.model}
          onSelect={(value) => handleChangeRow(row, "model", value, true)}
        />
        <SelectorCell<string>
          items={convertToNamesArray(beams)}
          itemKey={(item) => item}
          itemLabel={(item) => item}
          selected={row.from}
          onSelect={(value) => handleChangeRow(row, "from", value, true)}
          filter={(query, item) =>
            query ? item.includes(query.toUpperCase()) : true
          }
        />
        <NumericCell
          min={0}
          isDecimal={true}
          value={row.offset}
          onChange={(value) => handleChangeRow(row, "offset", value, true)}
        />
        <SelectorCell<TTrussType>
          items={["Double Howe","Pitched Roof"]}
          itemKey={(item) => item}
          itemLabel={(item) => item}
          selected={row.type}
          onSelect={(value) => handleChangeRow(row, "type", value, true)}
        />
        <NumericCell
          min={0}
          max={60}
          isDecimal={true}
          value={row.slope}
          onChange={(value) => handleChangeRow(row, "slope", value, true)}
        />
        <NumericCell
          min={0}
          isDecimal={true}
          value={row.span}
          onChange={(value) => handleChangeRow(row, "span", value, true)}
        />
        <NumericCell
          min={0}
          isDecimal={true}
          value={row.spacing}
          onChange={(value) => handleChangeRow(row, "spacing", value, true)}
        />
        <NumericCell
          min={0}
          isDecimal={true}
          value={row.numbers}
          onChange={(value) => handleChangeRow(row, "numbers", value, true)}
        />
        <SelectorCell<string>
          items={libs}
          selected={row.rafterLib}
          onSelect={(value) => handleChangeRow(row, "rafterLib", value)}
          itemKey={(item) => item}
          itemLabel={(item) => item}
        />
        <SelectorCell<Section>
          items={profiles.filter(
            (profile) => profile.country_code === row.rafterLib
          )}
          selected={row.rafterProfile}
          onSelect={(value) =>
            handleChangeRow(row, "rafterProfile", value, true)
          }
          itemKey={(item) => item.profile_section_id}
          itemLabel={(item) => item.designation}
          filter={(query, item) =>
            query
              ? item.designation
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase())
              : true
          }
        />
        <SelectorCell<string>
          items={libs}
          selected={row.tieLib}
          disabled={isPitchedRoof}
          onSelect={(value) => handleChangeRow(row, "tieLib", value)}
          itemKey={(item) => item}
          itemLabel={(item) => item}
        />
        <SelectorCell<Section>
          items={profiles.filter(
            (profile) => profile.country_code === row.tieLib
          )}
          selected={row.tieProfile}
          onSelect={(value) => handleChangeRow(row, "tieProfile", value, true)}
          itemKey={(item) => item.profile_section_id}
          itemLabel={(item) => item.designation}
          disabled={isPitchedRoof}
          filter={(query, item) =>
            query
              ? item.designation
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase())
              : true
          }
        />
        <SelectorCell<boolean>
          items={[true, false]}
          selected={row.tieMember ?? false}
          onSelect={(value) => handleChangeRow(row, "tieMember", value)}
          disabled={isPitchedRoof}
          itemKey={(item) => item.toString()}
          itemLabel={(item) => item ? "True" : "False"}
        />
        <SelectorCell<string>
          items={libs}
          selected={row.verticalLib}
          onSelect={(value) => handleChangeRow(row, "verticalLib", value)}
          disabled={isPitchedRoof}
          itemKey={(item) => item}
          itemLabel={(item) => item}
        />
        <SelectorCell<Section>
          items={profiles.filter(
            (profile) => profile.country_code === row.verticalLib
          )}
          selected={row.verticalProfile}
          disabled={isPitchedRoof}
          onSelect={(value) =>
            handleChangeRow(row, "verticalProfile", value, true)
          }
          itemKey={(item) => item.profile_section_id}
          itemLabel={(item) => item.designation}
          filter={(query, item) =>
            query
              ? item.designation
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase())
              : true
          }
        />
        <NumericCell
          min={0}
          isDecimal={true}
          value={row.verticalPost ?? 0}
          onChange={(value) => handleChangeRow(row, "verticalPost", value, true)}
        />
        <td>
          {Array.from({ length: row.verticalPost ?? 0 }).map((_, index) => (
            <NumericCell
              key={index}
              min={0}
              isDecimal={true}
              value={row.verticalPostDistance?.[index] ?? 0}
              onChange={(value) => handleVerticalPostDistanceChange(row, index, value)}
            />
          ))}
        </td>
        <SelectorCell<string>
          items={libs}
          selected={row.inclinedLib}
          disabled={isPitchedRoof}
          onSelect={(value) => handleChangeRow(row, "inclinedLib", value)}
          itemKey={(item) => item}
          itemLabel={(item) => item}
        />
        <SelectorCell<Section>
          items={profiles.filter(
            (profile) => profile.country_code === row.inclinedLib
          )}
          selected={row.inclinedProfile}
          disabled={isPitchedRoof}
          onSelect={(value) =>
            handleChangeRow(row, "inclinedProfile", value, true)
          }
          itemKey={(item) => item.profile_section_id}
          itemLabel={(item) => item.designation}
          filter={(query, item) =>
            query
              ? item.designation
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase())
              : true
          }
        />
      <NumericCell
        min={0}
        isDecimal={true}
        value={row.inclinedMember ?? 0}
        onChange={(value) => handleChangeRow(row, "inclinedMember", value, true)}
      />
      <NumericCell
        min={0}
        isDecimal={true}
        value={row.struttingBeamCount ?? 0}
        onChange={(value) => handleChangeRow(row, "struttingBeamCount", value, true)}
      />

      </tr>
    );
  }


  function handleExport() {
    const exportData = data.map((item) => {
      return {
        id: item.id,
        model: item.model,
        from: item.from,
        offset: item.offset,
        type: item.type,
        slope: item.slope,
        span: item.span,
        spacing: item.spacing,
        numbers: item.numbers,
        rafterLib: item.rafterLib,
        rafterProfile: item.rafterProfile ? item.rafterProfile.designation : '',
        tieLib: item.tieLib,
        tieProfile: item.tieProfile ? item.tieProfile.designation : '',
        tieMember: item.tieMember,
        verticalLib: item.verticalLib,
        verticalProfile: item.verticalProfile ? item.verticalProfile.designation : '',
        verticalPost: item.verticalPost,
        verticalPostDistance: item.verticalPostDistance ? item.verticalPostDistance.join(';') : '',
        inclinedLib: item.inclinedLib,
        inclinedProfile: item.inclinedProfile ? item.inclinedProfile.designation : '',
        inclinedMember: item.inclinedMember,
        struttingBeamCount: item.struttingBeamCount,
      };
    });
    exportToCSV(exportData, "Truss_Data");
  }
  
  

  function handleImport() {
    if (!project) return;
    importFromCSV((imported, isCSV) => {
      if (!isCSV || !Array.isArray(imported)) return;
      const importedData = imported as any[];
      const newData: OFTrussUI[] = [];
  
      // Helper function to find the Section object by designation and library
      function getProfileByDesignationAndLib(designation, lib) {
        if (!designation || !lib) return undefined;
        return profiles.find(
          (profile) =>
            profile.designation.trim().toLowerCase() === designation.trim().toLowerCase() &&
            profile.country_code.trim().toLowerCase() === lib.trim().toLowerCase()
        );
      }
  
      importedData.forEach((item, index) => {
        try {
          const rafterProfile = getProfileByDesignationAndLib(item.rafterProfile, item.rafterLib);
          const tieProfile = getProfileByDesignationAndLib(item.tieProfile, item.tieLib);
          const verticalProfile = getProfileByDesignationAndLib(item.verticalProfile, item.verticalLib);
          const inclinedProfile = getProfileByDesignationAndLib(item.inclinedProfile, item.inclinedLib);
  
          const row: OFTrussUI = {
            id: item.id || getNextId(data),
            selected: false,
            model: item.model,
            from: item.from,
            offset: parseFloat(item.offset) || 0,
            type: item.type,
            slope: parseFloat(item.slope) || 0,
            span: parseFloat(item.span) || 0,
            spacing: parseFloat(item.spacing) || 0,
            numbers: parseInt(item.numbers) || 1,
            rafterLib: item.rafterLib,
            rafterProfile: rafterProfile,
            tieLib: item.tieLib,
            tieProfile: tieProfile,
            tieMember: item.tieMember.toString().toLowerCase() === 'true',
            verticalLib: item.verticalLib,
            verticalProfile: verticalProfile,
            verticalPost: parseInt(item.verticalPost) || 0,
            verticalPostDistance: item.verticalPostDistance
              ? item.verticalPostDistance.split(',').map(Number)
              : [],
            inclinedLib: item.inclinedLib,
            inclinedProfile: inclinedProfile,
            inclinedMember: parseInt(item.inclinedMember) || 0,
            struttingBeamCount: parseInt(item.struttingBeamCount) || 0,
          };
  
          newData.push(row);
  
          // Check if all required fields are present
          if (
            row.model &&
            row.from &&
            row.type &&
            row.rafterProfile &&
            row.tieProfile &&
            row.verticalProfile &&
            row.inclinedProfile
          ) {
            const model = getElementByName(models, row.model);
            if (model) {
              const updatedModel = handleCreateElements(row, model);
              handleChangeModel(updatedModel);
            } else {
              console.warn(`Model '${row.model}' not found for row ${index + 1}`);
            }
          } else {
            console.warn(
              `Incomplete data in row ${index + 1}, skipping 3D element creation. Missing profiles or other required fields.`
            );
          }
        } catch (error) {
          console.error(`Error processing row ${index + 1}:`, error);
        }
      });
  
      // Update the data state
      const updatedData = [...data, ...newData];
      handleChangeData(updatedData);
    });
  }
  

  

  function handleVerticalPostDistanceChange(row: OFTrussUI, index: number, value: number) {
    const updatedDistances = [...(row.verticalPostDistance || [])];
    updatedDistances[index] = value;
    const totalDistance = updatedDistances.reduce((sum, dist) => sum + dist, 0);
    // if (totalDistance <= (row.span ?? 0)) {
      handleChangeRow(row, "verticalPostDistance", updatedDistances, true);
    // } else {
      // alert("The total distance between vertical posts cannot exceed the span.");
    // }
  }
  

  return (
    <div className="d-flex f-column">
      <div className="hr" />
      <div className="label-light bg-dark">
        {/* <span>Truss</span> */}
        <span>Roof</span>
        <Button
          small
          icon="trash"
          text="Delete"
          intent="warning"
          onClick={handleDeleteRows}
        />
        <Button
          small
          icon="export"
          text="Export to CSV"
          intent="success"
          disabled={false}
          onClick={handleExport}
        />
        <Button
          small
          icon="import"
          text="Import from CSV"
          intent="success"
          disabled={false}
          onClick={handleImport}
        />
        <Button
          small
          icon="plus"
          text="Add Row"
          intent="primary"
          onClick={handleAddRow}
        />
      </div>
      <div className="hr" />
      <div className={"p-5"}>
        <div className={"table-container"}>
          <table ref={tableRef} className="table bg-gray">
            <thead>
              <tr>
                <GeneralCheckBoxCell
                  rowSpan={3}
                  data={data}
                  onChange={handleChangeData}
                />
                <th rowSpan={3}>OF No.</th>
                <th rowSpan={3}>Corner Beam along roof length</th>
                <th rowSpan={3}>1st Truss from Beam Start point</th>
                <th colSpan={5}>Roof Details</th>
                <th colSpan={13}>Roof Element Sizes</th>
              </tr>
              <tr>
                <th>Type</th>
                <th>Slope</th>
                <th>Span</th>
                <th>Spacing</th>
                <th>Numbers</th>
                <th colSpan={2}>Rafter</th>
                <th colSpan={3}>Tie member</th>
                <th colSpan={4}>Vertical Post</th>
                <th colSpan={3}>Inclined member</th>
                <th colSpan={1}>Strutting Beam</th>
              </tr>
              <tr>
                <th></th>
                <th>Deg.</th>
                <th>m</th>
                <th>m</th>
                <th>Nos.</th>
                <th>C/S Lib</th>
                <th>Profile</th>
                <th>C/S Lib</th>
                <th>Profile</th>
                <th>Tie Member</th>
                <th>C/S Lib</th>
                <th>Profile</th>
                <th>Vertical Post</th>
                <th>Vertical Post Distance</th>
                <th>C/S Lib</th>
                <th>Profile</th>
                <th>Inclined Member</th>
                <th>Strutting Beam</th>
              </tr>
            </thead>
            <tbody>{data.map((item) => getRow(item))}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
