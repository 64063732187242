import { combineReducers, createStore, applyMiddleware } from "redux";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";

import { MainState } from "./main/types";
import { AuthState } from "./auth/types";
import { DataState } from "./data/types";
import { UIState } from "./ui/types";
import { TProcessState } from "./process/types";
import { TPSSState } from "./pss/types";
import { SelectionsState } from "./selections/types";
import { SceneState } from "./scene/types";
import { TLearningsState } from "./learnings/types";

import { mainReducer } from "./main/reducer";
import { authReducer } from "./auth/reducer";
import { UIReducer } from "./ui/reducer";
import { dataReducer } from "./data/reducer";
import { ProcessReducer } from "./process/reducer";
import { PSSReducer } from "./pss/reducer";
import { sceneReducer } from "./scene/reducer";
import { selectionsReducer } from "./selections/reducer";
import { LearningsReducer } from "./learnings/reducer";
// Import createLogger from redux-logger
import { createLogger } from "redux-logger";
import { projectResponseReducer } from "../components/menu-bar/3d-modeling/open-frame/creation-bar/LayoutReducer";
import { projectNameReducer } from "../components/menu-bar/3d-modeling/open-frame/creation-bar/ProjectNameReducer";
import { userRoleReducer } from "../components/menu-bar/3d-modeling/open-frame/creation-bar/UserRoleReducer";

type ProjectResponse = {
	Piping: string[];
	Structural: string[];
	Process: string[];
};

export type ProjectResponseState = {
	projectResponse: ProjectResponse;
};

export type ProjectNameState = {
	projectName: string;
};

export type UserRoleState = {
	userRole: string;
};

export type ApplicationState = {
	selections: SelectionsState;
	scene: SceneState;
	main: MainState;
	data: DataState;
	auth: AuthState;
	ui: UIState;
	process: TProcessState;
	pss: TPSSState;
	learnings: TLearningsState;
	router: any;
	projectResponse: ProjectResponseState;
	projectName: ProjectNameState;
	userRole: UserRoleState;
};

export const history = createBrowserHistory();

const createRootReducer = () =>
	combineReducers<ApplicationState>({
		selections: selectionsReducer,
		scene: sceneReducer,
		main: mainReducer,
		data: dataReducer,
		auth: authReducer,
		ui: UIReducer,
		process: ProcessReducer,
		pss: PSSReducer,
		learnings: LearningsReducer,
		router: connectRouter(history),
		projectResponse: projectResponseReducer,
		projectName: projectNameReducer,
		userRole: userRoleReducer,
	});

const configureStore = (preloadedState?: any) => {
	const logger = createLogger({
		predicate: (getState: any, action: any) =>
			action.type !== "UNSELECT_HOVERED" &&
			action.type !== "CHANGE_REQUEST_PROGRESS",
	});
	return createStore(
		createRootReducer(),
		preloadedState,
		applyMiddleware(logger)
	);
};

export default configureStore;
