import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { secondServerAPI, secondServerAPILearning } from "../../utils/agent";
import "./dashboardHome.css";
import { ApplicationState } from "../../../store";

interface Project {
    projectId: number;
    projectName: string;
    companyName: string;
    projectPhase: string;
}

interface TeamMember {
    userId: number;
    userEmail: string;
    username: string;
    companyName: string;
    role: string;  
    profilePhoto: string;
}

export default function DashboardHome() {
    const auth = useSelector((state: ApplicationState) => state.auth);
    const [projects, setProjects] = useState<Project[]>([]);
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
    const [userName, setUserName] = useState<string>("");
    const [selectedProjectName, setSelectedProjectName] = useState<string>("");
    const [layouts, setLayouts] = useState<{ [key: string]: string[] }>({});

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/projects`, {
                    headers: {
                        "user-id": auth.User_id
                        // "user-id": 1,
                    },
                });
                setProjects(response.data);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjects();
    }, []);

    const handleDeleteProject = async (projectId: number) => {
        try {
            const response = await Axios.delete(`${secondServerAPI}/rest/api/v1/layouts/deleteProjectFile`, {
                headers: {
                    "user-id" : auth.User_id,
                    // "user-id": 1,
                    "project-id": projectId,
                },
            });
            if (response.status === 200) {
                setProjects(projects.filter(project => project.projectId !== projectId));
                console.log("Project has been deleted");
            }
        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };

    const handleDeleteLayout = async (layoutId: number, layoutType: string) => {
        try {
            const response = await Axios.delete(`${secondServerAPI}/rest/api/v1/layouts/deleteLayout`, {
                headers: {
                    "user-id": auth.User_id,
                    // "user-id": 1,
                    "layout-id": layoutId,
                },
            });
            if (response.status === 200) {
                setLayouts(prevLayouts => ({
                    ...prevLayouts,
                    [layoutType]: prevLayouts[layoutType].filter(layout => layout.layoutId !== layoutId),
                }));
                console.log("Layout has been deleted");
            }
        } catch (error) {
            console.error("Error deleting layout:", error);
        }
    };

    const handleProjectClick = async (projectId: number) => {

        setTeamMembers([]);
        setLayouts({});

        try {
            const response = await Axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/project/team_member`, {
                headers: {
                    "user-id": auth.User_id,
                    // "user-id": 1,
                    "project-id": projectId.toString(),
                }
            });

            const teamMembersWithPhotos = response.data.map((member: TeamMember) => ({
                ...member,
                profilePhoto: "https://www.w3schools.com/howto/img_avatar.png",
            }));

            setTeamMembers(teamMembersWithPhotos);

            response.data.forEach((member: TeamMember) => {
                fetchProfilePhoto(member.userId);
            });

            // Fetch layouts for the selected project
            const project = projects.find(p => p.projectId === projectId);
            if (project) {
                setSelectedProjectName(project.projectName);
                await handleGetLayout(project.projectId);
            }

        } catch (error) {
            console.error("Error fetching team members:", error);
        }
    };

    const fetchProfilePhoto = (userId: any) => {
        fetch(`/rest/api/v1/dashboard/get/profile_photo`, {
            method: "GET",
            headers: {
                // "user-id": auth.User_id
                "user-id": userId,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.blob();
                } else {
                    throw new Error("Profile photo not found");
                }
            })
            .then(imageBlob => {
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setTeamMembers(prevMembers =>
                    prevMembers.map(member =>
                        member.userId === userId ? { ...member, profilePhoto: imageObjectURL } : member
                    )
                );
            })
            .catch(error => {
                console.error("Error fetching profile photo:", error);
            });
    };

    const handleGetLayout = async (projectId: number) => {
        try {
            const response = await Axios.get(
                `${secondServerAPI}/rest/api/v1/layouts/getAllLayouts`,
                {
                    headers: {
                        "project-id": projectId,
                        "user-id": auth.User_id
                        // "user-id": 1,
                    }
                }
            );

            const transformedLayouts = Object.keys(response.data).reduce((acc, layoutType) => {
                acc[layoutType] = response.data[layoutType].map(layout => ({ layoutId: layout.layoutId, layoutName: layout.layoutName }));
                return acc;
            }, {});

            setLayouts(transformedLayouts);
        } catch (error) {
            console.error("Error fetching layouts:", error);
        }
    };

    useEffect(() => {
        Axios.get(`${secondServerAPILearning}/api/v1/dashboard/userInformation`, {
            headers: {
                "user-id": auth.User_id
                // "user-id": 1,
            }
        })
            .then(userResponse => {
                if (userResponse.status === 200) {
                    setUserName(userResponse.data.userName);
                }
            })
            .catch(error => {
                console.error("Error fetching user information:", error);
            });
    }, []);

    function invokeReportIssue() {
        window.open("https://asets-tech-support.atlassian.net/servicedesk/customer/portal/1", "_blank");
    }

    return (
        <div className="primary-dashboard-home">
            {/* <h1>hello, <span style={{ color: "#F1C40F" }}>{userName}</span></h1> */}
            <div className="secondary-dashboard-home">
                <div className="secondary-dashboard-container-1">
                    <h2 className="header-with-icon">
                        projects
                        {/* <FontAwesomeIcon icon={faThumbtack} className="angled-icon" /> */}
                    </h2>
                    <div className="project-list-container">
                        <div className="project-list-container-header">
                            <span className="text-1" style={{ color: "#1F8EB7" }}>Project Name</span>
                            <p style={{ fontSize: "1.5rem" }}> | </p>
                            <span className="text-2" style={{ color: "#F1C40F" }}>Project Status</span>
                        </div>
                        {projects.length > 0 ? (
                            projects.map((project) => (
                                <div
                                    key={project.projectId}
                                    className="project-list-item clickable"
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div style={{ display: "flex", alignItems: "center" }} onClick={() => handleProjectClick(project.projectId)}>
                                            <span className="text-1">{project.projectName}</span>
                                            <p style={{ fontSize: "1.5rem", margin: "0 0.5rem" }}> | </p>
                                            <span className="text-2">{project.projectPhase}</span>
                                        </div>
                                        <FontAwesomeIcon 
                                            icon={faTrashAlt} 
                                            style={{ color: "red" }} 
                                            className="delete-icon" 
                                            onClick={() => handleDeleteProject(project.projectId)} 
                                        />
                                    </div>
                                    <p className="company-name">{project.companyName}</p>
                                </div>
                            ))
                        ) : (
                            <p>No projects available</p>
                        )}

                    </div>
                </div>
                {/* <div className="secondary-dashboard-container-2">
                    <h2 className="header-with-icon">
                        manhours used
                        <FontAwesomeIcon icon={faThumbtack} className="angled-icon" />
                    </h2>
                    <span>57</span>
                    <p>@ $87/hour</p>
                </div> */}
                {/* <div className="secondary-dashboard-container-3">
                    <h2 className="header-with-icon">
                        layouts
                        <FontAwesomeIcon icon={faThumbtack} className="angled-icon" />
                    </h2>
                    <div className="project-list-container">
                        <div className="project-list-container-header">
                            <span className="text-1" style={{ color: "#1F8EB7" }}>Project Name </span>
                            <p style={{ fontSize: "1.5rem" }}> | </p>
                            <span className="text-2" style={{ color: "#4CB944" }}>Layout Type</span>
                            <p style={{ fontSize: "1.5rem" }}> | </p>
                            <span className="text-1" style={{ color: "#F1C40F" }}>Layout Name</span>
                        </div>
                        {Object.keys(layouts).length > 0 ? (
                            Object.keys(layouts).map((layoutType) => (
                                layouts[layoutType].map((layout, index) => (
                                    <div key={`${layoutType}-${index}`} className="project-list-item">
                                        <div className="project-details">
                                            <span className="text-1">{selectedProjectName}</span>
                                            <p style={{ fontSize: "1.5rem" }}> | </p>
                                            <span className="text-2">{layoutType}</span>
                                            <p style={{ fontSize: "1.5rem" }}> | </p>
                                            <span className="text-1">{layout.layoutName}</span>
                                            <FontAwesomeIcon icon={faTrashAlt} style={{ color: "red", marginLeft: "1vw" }} className="delete-icon" onClick={() => handleDeleteLayout(layout.layoutId, layoutType)} />
                                        </div>
                                    </div>
                                ))
                            ))
                        ) : (
                            <p>No layouts available</p>
                        )}
                    </div>
                </div> */}
            </div>
            {/* <div className="tertiary-dashboard-home">
                <div className="tertiary-dashboard-container-2">
                    <h2 className="header-with-icon">team</h2>
                    <div className="team-list-container">
                        {teamMembers.length > 0 ? (
                            teamMembers.map((member) => (
                                <div key={member.userId} className="team-member">
                                    <img
                                        src={member.profilePhoto}
                                        alt="Team Member"
                                        className="team-member-image"
                                    />
                                    <div className="team-member-info">
                                        <span className="team-member-name">{member.username}</span>
                                        <span className="team-member-role">{member.role}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No team members available</p>
                        )}
                    </div>
                </div>
            </div> */}
        </div>
    );
}
