import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faProjectDiagram, faUser, faEdit, faTrash, faCut } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, Button, FormGroup, Tooltip } from "@blueprintjs/core";
import axios from "axios";
import { secondServerAPI } from "../../utils/agent";
import "./team.css";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";
import { A } from "@svgdotjs/svg.js";
import { icon } from "@fortawesome/fontawesome-svg-core";

export const jsonOption = {
    headers: {  "Access-Control-Allow-Origin": "https://idsuperprod.asets.io" },
};

export default function Team() {
    // State variables
    const [memberSearchInput, setMemberSearchInput] = useState("");
    const [projectSearchInput, setProjectSearchInput] = useState("");
    const [isProjectFormOpen, setIsProjectFormOpen] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [projectPhase, setProjectPhase] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
    const [members, setMembers] = useState([]);
    const [allMembers, setAllMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedRole, setSelectedRole] = useState("");
    const [projects, setProjects] = useState([]);
    const [totalMembers, setTotalMembers] = useState(0);
    const [totalAllMembers, setTotalAllMembers] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [selectedProjectForAssignment, setSelectedProjectForAssignment] = useState<number | null>(null);
    const [selectedMemberForAssignment, setSelectedMemberForAssignment] = useState<number | null>(null);
    const [userProjects, setUserProjects] = useState({});
    const [clickedProject, setClickedProjects] = useState("");
    const [selectedProjectIdMember, setSelectedProjectIdMember] = useState();
    const [deleteButton, setDeleteButton] = useState<Boolean>(false);
    const [clickedMember, setClickedMember] = useState("");

    const topRef = useRef<HTMLDivElement>(null);

    const auth = useSelector((state: ApplicationState) => state.auth);

    // Fetch data functions
    async function fetchMembers() {
        try {
            const response = await axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/userroles`, {
                headers: {
                    "user-id": auth.User_id,
                    // "user-id": 1,
                }
            });
            if (response.status === 200) {
                setMembers(response.data);
                setTotalMembers(response.data.length);
                fetchProjectsForMembers(response.data);
            }
        } catch (error) {
            console.error("Error fetching members:", error);
        }
    }

    async function fetchAllMembers() {
        try {
            const response = await axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/samecompanyusers`, {
                headers: {
                    "user-id": auth.User_id
                    // "user-id": 1,
                }
            });
            if (response.status === 200) {
                setAllMembers(response.data);
                setTotalAllMembers(response.data.length);
            }
        } catch (error) {
            console.log("Error fetching all the members", error);
        }
    }

    // async function fetchProjectsForMembers(members: any[]) {
    //     const userProjectsMap = {};
    
    //     try {
    //         const projectPromises = members.map(async (member) => {
    //             try {
    //                 const response = await axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/userandprojects`, {
    //                     headers: {
    //                         "user-id": member.userId,
    //                     }
    //                 });
    //                 if (response.status === 200) {
    //                     userProjectsMap[member.userId] = response.data.projectNames;
    //                 } else {
    //                     userProjectsMap[member.userId] = [];
    //                 }
    //             } catch (error) {
    //                 if (error.response && error.response.status === 404) {
    //                     userProjectsMap[member.userId] = [];
    //                 } else {
    //                     throw error;
    //                 }
    //             }
    //         });
    
    //         await Promise.all(projectPromises);
    //         setUserProjects(userProjectsMap);
    //     } catch (error) {
    //         console.error("An error occurred:", error);
    //     }
    // }
    
    async function fetchProjectsForMembers(members) {
        const userProjectsMap = {};
    
        try {
            const projectPromises = members.map(async (member) => {
                try {
                    const response = await axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/userandprojects`, {
                        headers: {
                            "user-id": member.userId,
                        }
                    });
                    if (response.status === 200) {
                        userProjectsMap[member.userId] = response.data.projectResponses.map(project => project.projectName);
                    } else {
                        userProjectsMap[member.userId] = [];
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        userProjectsMap[member.userId] = [];
                    } else {
                        throw error;
                    }
                }
            });
    
            await Promise.all(projectPromises);
            setUserProjects(userProjectsMap);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    

    async function fetchProjects() {
        try {
            const response = await axios.get(`${secondServerAPI}/rest/api/v1/dashboard/get/projects`, {
                headers: {
                    "user-id": auth.User_id,
                    // "user-id": 1,
                }
            });
            if (response.status === 200) {
                setProjects(response.data);
                setTotalProjects(response.data.length);
            }
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    }

    async function fetchMembersAndProjects() {
        await fetchMembers();
        await fetchProjects();
    }
    

    const scrollToTop = () => {
        if (topRef.current) {
            window.scrollTo({ top: topRef.current.offsetTop, behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (isProjectFormOpen) {
            scrollToTop();
        }
    }, [isProjectFormOpen]);

    useEffect(() => {
        fetchMembersAndProjects();
        fetchAllMembers();
    }, []);

    useEffect(() => {
        if (isProjectFormOpen && topRef.current) {
            window.scrollTo({ top: topRef.current.offsetTop, behavior: "smooth" });
        }
    }, [isProjectFormOpen]);

    const combinedMembersData = members.map(member => {
        return {
            ...member,
            projects: userProjects[member.userId] || ["No projects assigned"]
        };
    });

    // Event handlers
    const handleMemberSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMemberSearchInput(e.target.value);
    };

    const handleProjectSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectSearchInput(e.target.value);
    };

    const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectName(e.target.value);
    };

    const handleProjectPhaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectPhase(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(
                `${secondServerAPI}/rest/api/v1/dashboard/save/projects`,
                {
                    projectName,
                    projectPhase
                },
                {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    }
                }
            );
            if (response.status === 200) {
                alert("Project added successfully");
                setIsProjectFormOpen(false);
                setProjectName("");
                setProjectPhase("");
                fetchProjects();
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                alert("User ID not found");
            } else {
                alert("An error occurred");
            }
        }
    };

    const handleUpdate = async () => {
        try {
            const response = await axios.post(
                `${secondServerAPI}/rest/api/v1/dashboard/update/projects`,
                {
                    projectName,
                    projectPhase
                },
                {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                        "project-id": selectedProjectId,
                    }
                }
            );
            if (response.status === 200) {
                alert("Project updated successfully");
                setIsProjectFormOpen(false);
                setProjectName("");
                setProjectPhase("");
                setSelectedProjectId(null);
                fetchProjects();
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                alert("User ID not found");
            } else {
                alert("An error occurred");
            }
        }
    };

    const handleEditProject = (project: any) => {
        setProjectName(project.projectName);
        setProjectPhase(project.projectPhase);
        setSelectedProjectId(project.projectId);
        setIsProjectFormOpen(true);
    };

    const handleDeleteUserFromCompany = async (userId: any) => {
        try {
            const response = await axios.get(
                `${secondServerAPI}rest/api/v1/dashboard/delete/user/company_and_project`,
                {
                    ...jsonOption,
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                        "user-to-delete": userId,
                    }
                }
            );
            if (response.status === 200) {
                alert("User deleted from company successfully");
                fetchMembers();
            }
        } catch (error:any) {
            if (error.response && error.response.status === 400) {
                alert("User ID not found");
            } else {
                alert("An error occurred");
            }
        }
    };

    const handleDeleteUserFromProject = async (userId : number, projectId: number) => {
        try {
            const response = await axios.get(
                `${secondServerAPI}rest/api/v1/dashboard/delete/user/project`,
                {
                    ...jsonOption,
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                        "user-to-delete": userId,
                        "project-id": selectedProjectIdMember,
                    }
                }
            );
            if (response.status === 200) {
                alert("User deleted from project successfully");
                fetchMembers();
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                alert("User ID not found");
            } else {
                alert("An error occurred");
            }
        }
    };

    const handleDeleteProject = async (projectId: any) => {
        try {
            const response = await axios.get(
                `${secondServerAPI}rest/api/v1/dashboard/delete/project`,
                {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                        "project-to-delete": projectId,
                    }
                }
            );
            if (response.status === 200) {
                alert("Project deleted successfully");
                fetchProjects();
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                alert("User ID not found");
            } else {
                alert("An error occurred");
            }
        }
    };

    const handleAssignRole = async () => {
        if (!selectedMember || !selectedRole) {
            alert("Please select a member and a role");
            return;
        }
        try {
            const response = await axios.post(
                `${secondServerAPI}/rest/api/v1/dashboard/save/userrole`,
                {
                    userId: selectedMember.userId,
                    role: selectedRole
                },
                {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    }
                }
            );
            if (response.status === 200) {
                alert("Role assigned successfully");
                setSelectedMember(null);
                setSelectedRole("");
                fetchMembers();
            }
        } catch (error) {
            console.error("Error assigning role:", error);
            alert("An error occurred while assigning the role");
        }
    };

    const handleAddTeamMember = async () => {
        if (!selectedProjectForAssignment || !selectedMemberForAssignment) {
            alert("Please select both a project and a member to assign.");
            return;
        }

        try {
            const response = await axios.post(
                `${secondServerAPI}/rest/api/v1/dashboard/save/project/team_member`,
                {
                    projectId: selectedProjectForAssignment,
                    userId: selectedMemberForAssignment
                },
                {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    }
                }
            );

            if (response.status === 200) {
                alert("Member assigned to project successfully");
                fetchMembers();
            }
        } catch (error:any) {
            if (error.response && error.response.status === 400) {
                alert(error.response.data);
            } else {
                alert("An error occurred");
            }
        }
    };

    function handleProjectClick(projectName: string, member: string) {
        setClickedProjects(projectName);
        setClickedMember(member);
        const selectedProject = projects.find(proj => proj.projectName === projectName);    
        if (selectedProject) {
            setSelectedProjectIdMember(selectedProject.projectId);
        } else {
            console.log("Project not found");
        }
    }
    

    // Filtering and rendering functions
    const filteredMembers = combinedMembersData.filter(member =>
        member.userEmail.toLowerCase().includes(memberSearchInput.toLowerCase()) ||
        member.username.toLowerCase().includes(memberSearchInput.toLowerCase())
    );

    const filteredProjects = projects.filter(project =>
        project.projectName.toLowerCase().includes(projectSearchInput.toLowerCase()) ||
        project.projectPhase.toLowerCase().includes(projectSearchInput.toLowerCase())
    );

    const toggleProjectForm = (event) => {
        setIsProjectFormOpen(!isProjectFormOpen);
        setSelectedProjectId(null);
        setProjectName("");
        setProjectPhase("");
    };

    // JSX for rendering the component
    return (
        <div className="teams-primary-container" ref={topRef}>
            <h1>Teams</h1>
            <div className="teams-stats-container">
                <div className="teams-stats-item">
                    <div className="teams-icon-container">
                        <FontAwesomeIcon icon={faUser} className="teams-icon user-icon" />
                    </div>
                    <div className="teams-stats-text">
                        <span className="teams-number">{totalMembers}</span>
                        <span className="teams-label">Total Members</span>
                    </div>
                </div>
                <div className="teams-stats-item">
                    <div className="teams-icon-container">
                        <FontAwesomeIcon icon={faProjectDiagram} className="teams-icon project-icon" />
                    </div>
                    <div className="teams-stats-text">
                        <span className="teams-number">{totalProjects}</span>
                        <span className="teams-label">Total Projects</span>
                    </div>
                </div>
                <div className={`teams-stats-item ${isProjectFormOpen ? "expanded" : ""}`} onClick={toggleProjectForm}>
                    <div className="teams-icon-container">
                        <FontAwesomeIcon icon={faPlus} className="teams-icon add-project-icon" />
                    </div>
                    <div className="teams-stats-text">
                        <span className="teams-label">{selectedProjectId ? "Edit Project" : "Add Project"}</span>
                    </div>
                    {isProjectFormOpen && (
                        <div className="add-project-form" onClick={(e) => e.stopPropagation()}>
                            <FormGroup label="Project Name" labelFor="project-name-input" style={{ color: "#1F8EB7" }}>
                                <InputGroup
                                    id="project-name-input"
                                    placeholder="Enter project name"
                                    value={projectName}
                                    onChange={handleProjectNameChange}
                                    className="form-input"
                                />
                            </FormGroup>
                            <FormGroup label="Project Phase" labelFor="project-phase-input" style={{ color: "#1F8EB7" }}>
                                <InputGroup
                                    id="project-phase-input"
                                    placeholder="Enter project phase"
                                    value={projectPhase}
                                    onChange={handleProjectPhaseChange}
                                    className="form-input"
                                />
                            </FormGroup>
                            <Button onClick={selectedProjectId ? handleUpdate : handleSubmit} className="mto-export-button">
                                {selectedProjectId ? "Update" : "Submit"}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="teams-stats-container">
            <div className="role-assignment-container">
                <h2>Assign Role</h2>
                <FormGroup label="Select Member" labelFor="select-member" style={{ color: "#1F8EB7" }}>
                    <div className="bp3-html-select" style={{width:"200px"}}>
                        <select
                            id="select-member"
                            onChange={(e) => {
                                const member = allMembers.find(m => m.userId === parseInt(e.target.value));
                                setSelectedMember(member);
                            }}
                            value={selectedMember ? selectedMember.userId : ""}
                        >
                            <option value="" disabled>Select a member</option>
                            {allMembers.map(member => (
                                <option key={member.userId} value={member.userId}>
                                    {member.userName}
                                </option>
                            ))}
                        </select>
                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                    </div>
                </FormGroup>
                <FormGroup label="Select Role" labelFor="select-role" style={{ color: "#1F8EB7" }}>
                    <div className="bp3-html-select" style={{width:"200px"}}>
                        <select
                            id="select-role"
                            onChange={(e) => setSelectedRole(e.target.value)}
                            value={selectedRole}
                        >
                            <option value="" disabled>Select a role</option>
                            <option value="Team Member">Team Member</option>
                            <option value="Team Lead">Team Lead</option>
                        </select>
                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                    </div>
                </FormGroup>
                <Button onClick={handleAssignRole} className="mto-export-button" style={{width:"150px"}}>
                    Assign Role
                </Button>
            </div>
            <div className="role-assignment-container">
                <h2>Assign Member to Project</h2>
                <FormGroup label="Select Project" labelFor="select-project-assignment" style={{ color: "#1F8EB7" }}>
                    <div className="bp3-html-select" style={{width:"200px"}}>
                        <select
                            id="select-project-assignment"
                            onChange={(e) => setSelectedProjectForAssignment(parseInt(e.target.value))}
                            value={selectedProjectForAssignment || ""}
                        >
                            <option value="" disabled>Select a project</option>
                            {projects.map(project => (
                                <option key={project.projectId} value={project.projectId}>
                                    {project.projectName}
                                </option>
                            ))}
                        </select>
                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                    </div>
                </FormGroup>
                <FormGroup label="Select Member" labelFor="select-member-assignment" style={{ color: "#1F8EB7" }}>
                    <div className="bp3-html-select" style={{width:"200px"}}>
                        <select
                            id="select-member-assignment"
                            onChange={(e) => setSelectedMemberForAssignment(parseInt(e.target.value))}
                            value={selectedMemberForAssignment || ""}
                        >
                            <option value="" disabled>Select a member</option>
                            {allMembers.map(member => (
                                <option key={member.userId} value={member.userId}>
                                    {member.userName}
                                </option>
                            ))}
                        </select>
                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                    </div>
                </FormGroup>
                <Button onClick={handleAddTeamMember} className="mto-export-button" style={{width:"150px"}}>
                    Assign Member
                </Button>
            </div>
            </div>
            <div className="teams-search-container">
                <div className="teams-search-container-heading">
                    <h2>Member List</h2>
                    <InputGroup
                        large
                        type="text"
                        leftIcon="search"
                        className="search-input-group"
                        value={memberSearchInput}
                        onChange={handleMemberSearchInputChange}
                        placeholder="Search members..."
                        style={{
                            borderRadius: "12px",
                        }}
                    />
                </div>
                <table className="member-table">
                    <thead>
                        <tr>
                            <th>Employee Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Projects</th>
                            <th colSpan="2" >Delete User&apos;s Role</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMembers.map(member => (
                            
                            <tr key={member.userId}>
                                <td>{member.username}</td>
                                <td>{member.userEmail}</td>
                                <td>{member.role}</td>
                                <td>
                                    {member.projects.map(project => (
                                    <>
                                        <Button key={project} onClick={() => { handleProjectClick(project, member.username); setDeleteButton(true); }}>
                                            {project}
                                        </Button>
                                        {deleteButton === true && project === clickedProject && member.username === clickedMember  && (
                                            <Button intent="danger" onClick={() => handleDeleteUserFromProject(member.userId, selectedProjectId)}>
                                                <FontAwesomeIcon icon={faCut} style={{borderRadius:"12px"}} />
                                            </Button>
                                        )}
                                    
                                    </>
                                    ))}
                                </td>
                                <td>
                                    <Tooltip content="Delete user's role">
                                        <Button intent="danger" onClick={() => handleDeleteUserFromCompany(member.userId)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </Tooltip>
                                </td>
                                {/* <td>
                                    <Tooltip content="Delete user from project">
                                        <Button intent="danger" onClick={() => handleDeleteUserFromProject(member.userId, selectedProjectId)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </Tooltip>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>


            </div>
            <div className="teams-search-container">
                <div className="teams-search-container-heading">
                    <h2>Project List</h2>
                    <InputGroup
                        large
                        type="text"
                        leftIcon="search"
                        className="search-input-group"
                        value={projectSearchInput}
                        onChange={handleProjectSearchInputChange}
                        placeholder="Search projects..."
                        style={{
                            borderRadius: "12px",
                        }}
                    />
                </div>
                <table className="project-table">
                    <thead>
                        <tr>
                            <th>Project Name</th>
                            <th>Project Phase</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProjects.map(project => (
                            <tr key={project.projectId}>
                                <td>{project.projectName}</td>
                                <td>{project.projectPhase}</td>
                                <td>
                                    <Button intent="primary" onClick={() => handleEditProject(project)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    <Tooltip content="Delete project">
                                        <Button intent="danger" onClick={() => {handleDeleteProject(project.projectId);scrollToTop()}}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </Tooltip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
