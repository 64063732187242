import { action } from "typesafe-actions";
import { EPSSActionTypes, TPSS, TPSSConveyor, TPSSFactoryPath, TPSSHuman, TPSSRoute, TPSSTask, TPSSWaypoint } from "./types";
import { path } from "makerjs";

function setPSSAction(name: string, pss: TPSS) {
  return action(EPSSActionTypes.SET_PSS, { name, pss });
}

function loadPSSAction(name: string, pss: TPSS) {
  return action(EPSSActionTypes.LOAD_PSS, { name, pss });
}

function createPSSAction(name: string) {
  return action(EPSSActionTypes.CREATE_PSS, { name });
}

function changePSSAction(name: string, field: string, value: any) {
  return action(EPSSActionTypes.CHANGE_PSS, { name, field, value });
}

function removePSSAction(name: string) {
  return action(EPSSActionTypes.REMOVE_PSS, { name });
}

function renamePSSAction(oldName: string, newName: string) {
  return action(EPSSActionTypes.RENAME_PSS, { oldName, newName });
}

function createPSSConveyor(name: string, item: TPSSConveyor) {
  return action(EPSSActionTypes.CREATE_ELEMENT, { name, item });
}

function changePSSConveyor(name: string, item: TPSSConveyor) {
  return action(EPSSActionTypes.CHANGE_ELEMENT, { name, item });
}
function removePSSConveyor(name: string, item: TPSSConveyor) {
  return action(EPSSActionTypes.REMOVE_ELEMENT, { name, item });
}

function createPSSHuman(name: string, item: TPSSHuman) {
  return action(EPSSActionTypes.CREATE_HUMAN, { name, item });
}

function changePSSHuman(name: string, item: TPSSHuman) {
  return action(EPSSActionTypes.CHANGE_HUMAN, { name, item });
}
function removePSSHuman(name: string, item: TPSSHuman) {
  return action(EPSSActionTypes.REMOVE_HUMAN, { name, item });
}

function switchPSSAnimationAction(animate: boolean) {
  return action(EPSSActionTypes.SWITCH_ANIMATION, { animate });
}

function switchPauseResumeAction(paused: boolean) {
  return action(EPSSActionTypes.SWITCH_PAUSE_RESUME, { paused });
}

function switchDrawingAction(drawing: boolean) {
  return action(EPSSActionTypes.SWITCH_DRAWING, { drawing });
}

function changePathColorAction(pathColor: string) {
  return action(EPSSActionTypes.SET_PATH_COLOR, { pathColor });
}

function createPSSTask(name: string, task: TPSSTask) {
  return action(EPSSActionTypes.CREATE_TASK, { name, task });
}

function createPSSTaskMultiple(name: string, tasks: TPSSTask[]) {
  return action(EPSSActionTypes.CREATE_TASK_2, { name, tasks }); 
}


function changePSSTask(name: string, task: TPSSTask) {
  return action(EPSSActionTypes.CHANGE_TASK, { name, task });
}

function removePSSTask(name: string, task: TPSSTask) {
  return action(EPSSActionTypes.REMOVE_TASK, { name, task });
}

function changePathWidth(pathWidth: number) {
  return action(EPSSActionTypes.SET_PATH_WIDTH, { pathWidth });
}

function changePathThickness(pathThickness: number) {
  return action(EPSSActionTypes.SET_PATH_THICKNESS, { pathThickness });
}

function setSpeedMultiplier(speedMultiplier: number) {
  return action(EPSSActionTypes.SET_SPEED_MULTIPLIER, { speedMultiplier });
}

export const createPSSRoute = (project: string, route: TPSSRoute) =>
  action(EPSSActionTypes.CREATE_ROUTE, { project, route });

export const changePSSRoute = (project: string, route: TPSSRoute) =>
  action(EPSSActionTypes.CHANGE_ROUTE, { project, route });

export const removePSSRoute = (project: string, routeId: number) =>
  action(EPSSActionTypes.REMOVE_ROUTE, { project, routeId });

// Actions for Waypoints
export const addPSSWaypoint = (project: string, routeId: number, waypoint: TPSSWaypoint) =>
  action(EPSSActionTypes.ADD_WAYPOINT, { project, routeId, waypoint });

export const removePSSWaypoint = (project: string, routeId: number, waypointId: number) =>
  action(EPSSActionTypes.REMOVE_WAYPOINT, { project, routeId, waypointId });

export const createFactoryPath = (project: string, factoryPath: TPSSFactoryPath) =>
  action(EPSSActionTypes.CREATE_FACTORY_PATH, { project, factoryPath });

export const changeFactoryPath = (project: string, factoryPath: TPSSFactoryPath) =>
  action(EPSSActionTypes.CHANGE_FACTORY_PATH, { project, factoryPath });

export const removeFactoryPath = (project: string, id: number) =>
  action(EPSSActionTypes.REMOVE_FACTORY_PATH, { project, id });


export {
  setPSSAction,
  loadPSSAction,
  createPSSAction,
  changePSSAction,
  removePSSAction,
  renamePSSAction,
  createPSSConveyor,
  changePSSConveyor,
  removePSSConveyor,
  createPSSHuman,
  changePSSHuman,
  removePSSHuman,
  switchPSSAnimationAction,
  switchPauseResumeAction,
  switchDrawingAction,
  createPSSTask,
  changePSSTask,
  removePSSTask,
  changePathThickness,
  changePathWidth,
  changePathColorAction,
  createPSSTaskMultiple,
  setSpeedMultiplier
};
