import React, { useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, FormGroup, InputGroup, Checkbox, Spinner } from "@blueprintjs/core";
import "./PricingPlans.css";
import { theme } from 'antd';
import { secondServerAPI } from '../utils/agent';

const stripePromise = loadStripe('pk_test_51O5w67GFmWd66UgV0ZrcRLdQic88kCCCVb27OWcLLKKpZFRI8Y1RdGExl4pOMfugq4CO0mjj9HMomMHVZwmI90aQ007i9jH6Sw');

const plans = [
  { name: 'silver', planPrimary: true, price: 525, features: ['3 Seats','100 UFC Credits','Integrator & Viewer Modules', 'Smart Material TakeOff Projector','Model Export to Revit, AutoCad, E3D'], products: ['integrator', 'viewer'] },
  { name: 'gold', planSecondary: true, price: 925, features: ['3 Seats','250 UFC Credits','Integrator & Viewer Modules','Structure / Piping Modules', 'Smart Material TakeOff Projector','Model Export to Revit, AutoCad, E3D, Caesar, and StaadPro'], products: ['integrator','viewer','structural','piping'] },
  { name: 'platinum', planTertiary: true, price: 1370, features: ['6 Seats','500 UFC Credits','Integrator & Viewer Modules','Structure & Piping Modules', 'Smart Material TakeOff Projector','Model Export to Revit, AutoCad, E3D, Caesar, and StaadPro'], products: ['integrator', 'viewer', 'structural', 'piping'] },
  { name: 'enterprise', contactUs: true, features: ['Custom UFC amounts','Expanded Integrator access','Priority analysis servers', 'Expanded Project Management Suite','Generative Layout Tool(L-Gen) for rapid prototyping'] },
];

const PricingPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [users, setUsers] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [goldSelect, setGoldSelect] = useState("structural");
  const [daysCompletion, setDaysCompletion] = useState<number>();
  const [resourcesAllocated, setResourcesAllocated] = useState<number>(1);
  const [maxPipes, setMaxPipes] = useState<number>();
  const [maxStructures, setMaxStructures] = useState<number>();
  const [highlightedPlan, setHighlightedPlan] = useState("");
  const [licenseneeded, setlicenseneeded] = useState<number>();
  const [daysRequired,setDayRequired] = useState<number>();
  const bottomRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    theme: 'night',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#1F8EB7',
        fontWeight: '500',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#1F8EB7',
        },
        '::placeholder': {
          color: '#1F8EB7',
        },
      },
      invalid: {
        iconColor: 'red',
        color: 'red',
      },
    },
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    const seats = parseInt(plan.features[0].match(/\d+/)[0], 10);
    setUsers(Array(seats).fill({ email: "", products: [], isPrimary: false, subscriptionActivated: false }));
    setShowPaymentModal(true);
  };

  const handleEmailChange = (index, value) => {
    setUsers((prevUsers) => {
      const newUsers = [...prevUsers];
      newUsers[index] = { ...newUsers[index], email: value };
      return newUsers;
    });
  };
  
  const handleProductChange = (index:any, product) => {
    setUsers((prevUsers) => {
      const newUsers = [...prevUsers];
  
      if (!newUsers[index]) {
        newUsers[index] = { products: [] };
      }
  
      if (!newUsers[index].products) {
        newUsers[index].products = [];
      }
  
      if (newUsers[index].products.includes(product)) {
        newUsers[index].products = newUsers[index].products.filter(p => p !== product);
      } else {
        newUsers[index].products.push(product);
      }
  
      return newUsers;
    });
  };
  

  const handlePrimaryChange = (index) => {
    setUsers((prevUsers) => {
      return prevUsers.map((user, i) => ({
        ...user,
        isPrimary: i === index
      }));
    });
  };

  const handleCloseModal = () => {
    setShowPaymentModal(false);
    setIsSubmitting(false);
    setMessage('');
    setIsSuccess(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    if (!stripe || !elements) {
      setMessage('Stripe has not loaded yet. Please try again.');
      setIsSubmitting(false);
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        setMessage(error.message);
        setIsSuccess(false);
        setIsSubmitting(false);
        return;
      }

      if (selectedPlan) {
        const amount = selectedPlan.price;
        const credits = parseInt(selectedPlan.features[1].match(/\d+/)[0], 10);

        const response = await fetch(`${secondServerAPI}/rest/api/v1/accessUpdate/makePayment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            planId: selectedPlan.name,
            users,
            amount,
            credits,
            stripeToken: token.id,
            additionalInfo: {
              ID_TAG: Math.random().toString(36).substr(2, 9)
            }
          }),
        });

        const session = await response.json();

        if (session.error) {
          setMessage(session.error.message);
          setIsSuccess(false);
        } else {
          setIsSuccess(true);
          setMessage(`Payment for the ${selectedPlan.name} plan was successful!`);
        }
      }
    } catch (error) {
      setIsSuccess(false);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelectChange = (event) => {
    setGoldSelect(event.target.value);
  };

  const boldText = (text) => {
    const boldWords = ['Integrator & Viewer Modules', 'Structure / Piping Modules','Structure & Piping Modules'];
    boldWords.forEach(word => {
      text = text.replace(word, `<strong style="color: #5a5b5c;">${word}</strong>`);
    });
    return text;
  };
  
  // const handleSubscriptionSubmit = () => {
  //   if (daysCompletion === undefined || maxPipes === undefined || maxStructures === undefined) {
  //     console.log("Missing values for calculation");
  //   }
  //   const days = daysCompletion || 0;
  //   const pipes = maxPipes || 0;
  //   const structures = maxStructures || 0;
  
  //   const pipesPerDay = days > 0 ? pipes / days : 0;
  //   const structuresPerDay = days > 0 ? structures / days : 0;

  //   let result = "";
  //   if (pipesPerDay === 0 && structuresPerDay === 0) {
  //     result = "silver";
  //     console.log("Highlighted plan: silver");
  //   } else if (pipesPerDay > 100 || structuresPerDay > 50) {
  //     result = "enterprise";
  //     console.log("Highlighted plan: enterprise");
  //   } else if (pipesPerDay + structuresPerDay > 100) {
  //     result = "enterprise";
  //     console.log("Highlighted plan: enterprise based on combined daily needs");
  //   } else if (pipesPerDay > 75 || structuresPerDay > 50) {
  //     result = "platinum";
  //     console.log("Highlighted plan: platinum");
  //   } else if(pipesPerDay && structuresPerDay){
  //     result = "platinum";
  //     console.log("Highlighted Platinum")
  //   } else {
  //     result = "gold";
  //     console.log("Highlighted plan: gold");
  //   }

  //   setHighlightedPlan(result);
  //     if (highlightedPlan === "silver" || highlightedPlan === "gold") {
  //       setlicenseneeded(Math.ceil(resourcesAllocated / 3));
  //       console.log(licenseneeded)
  //     } else {
  //       setlicenseneeded(Math.ceil(resourcesAllocated / 6));
  //       console.log(licenseneeded)
  //     }
  //   setTimeout(() => {
  //     if (bottomRef.current) {
  //       bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 200); 
  // };

  // const handleSubscriptionSubmit = () => {
  //   if (daysCompletion === undefined || maxPipes === undefined || maxStructures === undefined) {
  //     console.log("Missing values for calculation");
  //     return;
  //   }
    
  //   const expectedDays = daysCompletion || 0;
  //   const pipes = maxPipes || 0;
  //   const openframes = maxStructures || 0;
  
  //   const pipesPerDayPerResource = 7;
  //   const openframesPerDayPerResource = 1;
  
  //   const plan = pipes > 0 && openframes > 0 ? "platinum" : "gold";
  
  //   let tempResources = 1;
  //   let licenses = 1;
  
  //   let daysToCompletePipes = pipes / pipesPerDayPerResource;
  //   let daysToCompleteOpenframes = openframes / openframesPerDayPerResource;

  //   while ((daysToCompletePipes + daysToCompleteOpenframes) > expectedDays) {
  //     tempResources += 1;
  
  //     if ((plan === "gold" && tempResources > (3 * licenses)) || 
  //         (plan === "platinum" && tempResources > (6 * licenses))) {
  //       licenses += 1;
  //     }
  
  //     daysToCompletePipes = pipes / (pipesPerDayPerResource * tempResources);
  //     daysToCompleteOpenframes = openframes / (openframesPerDayPerResource * tempResources);
  //   }
  
  //   const resourcesRequired = tempResources;
  //   const daysRequired2 = daysToCompletePipes + daysToCompleteOpenframes;
  
  //   licenses = daysRequired2 > 30 ? licenses * Math.ceil(daysRequired2 / 30) : licenses;
  
  //   setHighlightedPlan(plan.toLowerCase());
  //   setlicenseneeded(licenses);
  //   setResourcesAllocated(resourcesRequired);
  //   setDayRequired(daysRequired2)
  
  //   console.log("Resources required:", resourcesRequired);
  //   console.log("Days required:", daysRequired2);
  //   console.log("Plan recommended:", plan);
  //   console.log("Number of subscriptions required:", licenses);
  
  //   setTimeout(() => {
  //     if (bottomRef.current) {
  //       bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 200);
  // };

  const handleSubscriptionSubmit = () => {
    if (daysCompletion === undefined || maxPipes === undefined || maxStructures === undefined) {
        console.log("Missing values for calculation");
        return;
    }

    const expectedDays = daysCompletion || 0;
    const pipes = maxPipes || 0;
    const openframes = maxStructures || 0;

    const pipesPerDayPerResource = 7;
    const openframesPerDayPerResource = 1;

    let plan = pipes > 0 && openframes > 0 ? "platinum" : "gold";

    let tempResources = 1;
    let licenses = 1;

    let daysToCompletePipes = pipes / pipesPerDayPerResource;
    let daysToCompleteOpenframes = openframes / openframesPerDayPerResource;

    while ((daysToCompletePipes + daysToCompleteOpenframes) > expectedDays) {
        tempResources += 1;

        if (tempResources > 6) {
            plan = "platinum";
        }

        if ((plan === "gold" && tempResources > (3 * licenses)) || 
            (plan === "platinum" && tempResources > (6 * licenses))) {
            licenses += 1;
        }

        daysToCompletePipes = pipes / (pipesPerDayPerResource * tempResources);
        daysToCompleteOpenframes = openframes / (openframesPerDayPerResource * tempResources);
    }

    const resourcesRequired = tempResources;
    const daysRequired2 = daysToCompletePipes + daysToCompleteOpenframes;

    licenses = daysRequired2 > 30 ? licenses * Math.ceil(daysRequired2 / 30) : licenses;

    setHighlightedPlan(plan.toLowerCase());
    setlicenseneeded(licenses);
    setResourcesAllocated(resourcesRequired);
    setDayRequired(daysRequired2);

    console.log("Resources required:", resourcesRequired);
    console.log("Days required:", daysRequired2);
    console.log("Plan recommended:", plan);
    console.log("Number of subscriptions required:", licenses);

    setTimeout(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, 200);
};

  


  return (
    <div className="pricing-page">

    <div className="pricing-recommendation">
      <h2>Subscription Recommendation</h2>

      <div className="row">
        <p>Expected Days for Completion</p>
        <InputGroup 
          large
          type="number"
          required
          onChange={(event) => {
            const value = Number(event.target.value);
            if (value < 0) {
              alert("Please enter a positive number for expected days for completion.");
              setDaysCompletion('');
            } else {
              setDaysCompletion(value);
            }
          }}
          className="input-group"
        />
      </div>

      <div className="row">
        <p>Number of pipelines</p>
        <InputGroup 
          large
          type="number"
          required
          onChange={(event) => {
            const value = Number(event.target.value);
            if (value < 0) {
              alert("Please enter a positive number for pipelines.");
              setMaxPipes('');
            } else {
              setMaxPipes(value);
            }
          }}
          className="input-group"
        />
      </div>

      <div className="row">
        <p>Number of structures</p>
        <InputGroup 
          large
          type="number"
          required
          onChange={(event) => {
            const value = Number(event.target.value);
            if (value < 0) {
              alert("Please enter a positive number for structures.");
              setMaxStructures('');
            } else {
              setMaxStructures(value);
            }
          }}
          className="input-group"
        />
      </div>

      <Button className="select" onClick={handleSubscriptionSubmit}>Estimate</Button>
      {licenseneeded && (
        <>
          <div className="row">
            <p>Recommended Subscriptions</p>
            <InputGroup
              large
              type="number"
              className="input-group"
              readOnly
              value={licenseneeded}
              style={{backgroundColor:"#7CCCE9"}}
            />
          </div>
          <div className="row">
            <p>Manpower Estimation</p>
            <InputGroup
              large
              type="number"
              className="input-group"
              readOnly
              value={resourcesAllocated}
              style={{backgroundColor:"#7CCCE9"}}
            />
          </div>
          <div className="row">
            <p>Estimated Completion Time (days) </p>
            <InputGroup
              large
              type="number"
              className="input-group"
              readOnly
              value={Math.ceil(daysRequired)}
              style={{backgroundColor:"#7CCCE9"}}
            />
          </div>
        </>
      )}

    </div>


      <header className="pricing-header">
        <h1>Choose Your Monthly Plan</h1>
      </header>
      <div ref={bottomRef}>
      <div className="pricing-container">
        {plans.map((plan, index) => (
          <div key={plan.name} className={`plan ${highlightedPlan === plan.name ? 'highlight' : ''}`}>
            <div className='plan-list'>
              <h2>{plan.name}</h2>
              {plan.planPrimary && <h3>Basic integrator & viewer access for your mid-sized team</h3>}
              {plan.planSecondary && <h3>Expanded integrator & viewer with built-in engineering analysis for your mid-size team</h3>}
              {plan.planTertiary && <h3>Premium integrator, viewer, and built-in engineering analysis for your large team</h3>}
              {plan.contactUs && <h3>Customize your plan to fit your team&apos;s needs</h3>}
              <p>{plan.contactUs ? "Chat with our team today" : `CA$ ${plan.price}/month`}</p>
            </div>
            {plan.contactUs ? (
              <a href="https://www.asets.io/contact" className="contact-us">Contact Us</a>
            ) : (
              <Button className={`buy-now ${index === 0 || index === 2 ? 'blue-button' : index === 1 ? 'yellow-button' : ''}`} onClick={() => handlePlanSelect(plan)}>Buy Now</Button>
            )}

            <div className='plan-feature-base'>
              <div className='plan-features'>
                {plan.planPrimary && <h3>Core features include:</h3>}
                {plan.planSecondary && <h3>All individual plan features plus:</h3>}
                {plan.planTertiary && <h3>All standard plan features plus:</h3>}
                {plan.contactUs && <h3>Other benefits include:</h3>}
              </div>
              <ul>
                {plan.features.map((feature, index) => (
                  <li
                  style={{
                    color: "grey",
                    textAlign: "left",
                    listStyleType: "none",
                    paddingLeft: "1.5em",
                    position: "relative",
                    fontSize: window.innerWidth <= 768 ? "14px" : "20px"
                  }}
                  key={index}
                >
                  <span style={{ position: "absolute", textAlign: "left", left: 0 }}>✔</span>
                  <span dangerouslySetInnerHTML={{ __html: boldText(feature) }} />
                </li>
                
                ))}
              </ul>
              <div style={{ textAlign: "right" }}>
                <a href="https://www.asets.io/contact" className="learn-more" style={{ color: "#ED7D31", textDecoration: "underline", textDecorationColor: "#ED7D31" }}>
                  Learn more
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>

      {showPaymentModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2 style={{color: 'black'}}>Enter Payment Details</h2>
            {selectedPlan?.name === "gold" && (
              <>
                <p style={{color:"black", fontSize:"20px", fontWeight:"bold"}}>trial</p>
                <select style={{fontSize:"20px", marginBottom:"10px", fontWeight:"normal"}} value={goldSelect} onChange={handleSelectChange}>
                  <option value="structural">Structural</option>
                  <option value="piping">Piping</option>
                </select>
              </>
            )}
            <form onSubmit={handleSubmit}>
              {users.map((user, index) => (
                <div key={index} style={{ marginBottom: '20px', display: "flex" }}>
                  <FormGroup>
                    <label style={{ color: "black", fontSize: "medium" }}>
                      Email {index + 1}<span style={{ color: "red" }}>*</span>
                    </label>
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please use the email associated with your account.
                    </span>
                    <InputGroup
                      large
                      type="email"
                      value={user.email}
                      onChange={(e) => handleEmailChange(index, e.target.value)}
                      required
                    />
                  </FormGroup>
                  {selectedPlan?.name !== "gold" && (
                    <FormGroup style={{ display: "flex", flexDirection: "column" }}>
                      <label style={{ color: "black", fontSize: "medium" }}>Select Products:</label>
                      {selectedPlan?.products?.map((product) => (
                        <Checkbox
                          key={product}
                          label={product}
                          style={{ color: "black", fontSize: "medium" }}
                          checked={user.products.includes(product)}
                          onChange={() => handleProductChange(index, product)}
                        />
                      ))}
                    </FormGroup>
                  )}
                  {selectedPlan?.name === "gold" && goldSelect === "structural" && (
                    <FormGroup style={{ display: "flex", flexDirection: "column" }}>
                      <label style={{ color: "black", fontSize: "medium" }}>Select Products:</label>
                      {selectedPlan?.products?.slice(0, 3).map((product) => (
                        <Checkbox
                          key={product}
                          label={product}
                          style={{ color: "black", fontSize: "medium" }}
                          checked={user.products.includes(product)}
                          onChange={() => handleProductChange(index, product)}
                        />
                      ))}
                    </FormGroup>
                  )}
                  {selectedPlan?.name === "gold" && goldSelect === "piping" && (
                    <FormGroup style={{ display: "flex", flexDirection: "column" }}>
                      <label style={{ color: "black", fontSize: "medium" }}>Select Products:</label>
                      {selectedPlan?.products?.slice(0, 2).concat(selectedPlan?.products?.slice(3, 4)).map((product) => (
                        <Checkbox
                          key={product}
                          label={product}
                          style={{ color: "black", fontSize: "medium" }}
                          checked={user.products.includes(product)}
                          onChange={() => handleProductChange(index, product)}
                        />
                      ))}
                    </FormGroup>
                  )}
                  <FormGroup>
                    <label style={{ color: "black", fontSize: "medium" }}>Primary:</label>
                    <input
                      type="radio"
                      name="isPrimary"
                      checked={user.isPrimary}
                      onChange={() => handlePrimaryChange(index)}
                    />
                  </FormGroup>
                </div>
              ))}
              <FormGroup>
                <label style={{ color: "black", fontSize: "medium", marginBottom: "30px" }}>Credit or Debit Card</label>
                <CardElement options={cardStyle} />
              </FormGroup>
              <FormGroup className="t-center">
                <Button
                  large
                  type="submit"
                  className="form-group-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner size={20} /> : "Submit Payment"}
                </Button>
              </FormGroup>
            </form>
            {message && <div className={`message ${isSuccess ? "success" : "error"}`}>{message}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

const PricingPlansWithStripe = () => (
  <Elements stripe={stripePromise}>
    <PricingPlans />
  </Elements>
);

export default PricingPlansWithStripe;
