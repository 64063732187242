import { TPSS,TPSSTask, TPSSConveyor, ERackColor, TPSSState, TPSSHuman, TPSSRoute, TPSSFactoryPath } from "./types";

const rackColors: ERackColor[] = [
  ERackColor.RED,
  ERackColor.GREEN,
  ERackColor.BLUE,
  ERackColor.YELLOW,
];

const initialPSSState: TPSSState = {
  pathWidth: 10,
  pathThickness: 2,
  simulations: [],
};

const initialConveyor: TPSSConveyor = {
  id: 0,
  x: 0,
  y: 0,
  z: 0,
  direction: "+X",
  length: 0,
  height: 0,
  speed: 0,
  EGVPath: [{ id: 0, x: -5, y: 0, z: 0, waitTime: 0}],
  EGVRacksPerTrip: 4,
  EGVSpeed: 0,
  peopleCountL: 0,
  peopleCountR: 0,
  peopleRackAssignmentL: [],
  peopleRackAssignmentR: [],
  peopleSpacingL: 0,
  peopleSpacingR: 0,
  peopleStartPositionL: 0,
  peopleStartPositionR: 0,
};

const initialHuman: TPSSHuman = {
  id: 0,
  speed: 0,
  path: [{id: 0, x: 0, y: 0, z: -5, waitTime: 1}],
};

const initialRoute: TPSSRoute = {
  id: 0,
  description: "",
  waypoints: [],
};

// const initialFactoryPath: TPSSFactoryPath = {
//   id: 0,
//   description: "",
//   x: 0,
//   y: 0,
//   z: 0,
// };

const initialFactoryPath: TPSSFactoryPath = {
  id: 0,
  description: "",
  startPoint: { x: 0, y: 0, z: 0 },
  endPoint: { x: 10, y: 0, z: 0 },
};



const initialPSS: TPSS = {
  project: "",
  racks: 4,
  conveyors: [],
  humans: [],
  tasks: [],
  routes: [], 
  factoryPaths: [],
};

const initialTask: TPSSTask = {
  id: 0,
  description: "",
  duration: 0,
  x: 0, 
  y: 0, 
  z: 0, 
};

export { rackColors, initialPSSState, initialPSS, initialConveyor, initialHuman, initialTask, initialRoute, initialFactoryPath};

