import React from "react";
import { FunctionComponent } from "react";
import { Conveyor } from "./conveyor/Conveyor";
import { Workers } from "./workers/Workers";
import { Racks } from "./racks/Racks";
import { EGV } from "./egv/EGV";
import { Human } from "./human/Human";
import { TaskManager } from "./Task/tasks";
import { UserRoutesManager } from "./operatorRoutes/operatorRoutes";
import { FactoryPathManager } from "./Factory/FactoryPath";
import { SimulationResultManager } from "./simulationResult/SimulationResult";

type Props = {
  selected: number;
};

const ProductSortingSimulationContent: FunctionComponent<Props> = (props) => {
  function getComponent() {
    switch (props.selected) {
      case 0:
        return <Conveyor />;
      case 1:
        return <Workers />;
      case 2:
        return <Racks />;
      case 3:
        return <EGV />;
      case 4:
        return <Human />;
      case 5:
        return <TaskManager />
      case 6:
        return <UserRoutesManager />
      case 7:
        return <FactoryPathManager />
      case 8: 
        return <SimulationResultManager />
      default:
        return null;
    }
  }

  return getComponent();
};

export default ProductSortingSimulationContent;
