import React, { useState, useRef, useEffect, useMemo } from "react";
import {
	getTopOffset,
	getNextId,
	getCurrentPSS,
} from "../../../../3d-models/utils";
import { Button } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { CustomDlg } from "../../../../common/CustomDlg";
import { TPSSHuman, TPSSEGVPath } from "../../../../../store/pss/types";
import { changePSSHuman } from "../../../../../store/pss/actions";
import { NumericCell } from "../../../../common/NumericCell";
import { CheckBoxCell } from "../../../../common/CheckBoxCell";
import { ApplicationState } from "../../../../../store";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type Props = {
	name: string;
	item: TPSSHuman;
	onClose: () => any;
};

type DraggableRowProps = {
	el: TPSSEGVPath;
	index: number;
	moveRow: (dragIndex: number, hoverIndex: number) => void;
	handleSelect: (el: TPSSEGVPath) => void;
	selected: number[];
	handleChange: (el: TPSSEGVPath, field: string, val: any) => void;
};

const DraggableRow: React.FC<DraggableRowProps> = ({
	el,
	index,
	moveRow,
	handleSelect,
	selected,
	handleChange,
}) => {
	const ref = useRef<HTMLTableRowElement>(null);

	const [, drop] = useDrop({
		accept: "row",
		hover(item: { index: number }, monitor) {
			if (!ref.current) return;

			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) return;

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

			// Perform the move
			moveRow(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally, it's better to avoid mutations,
			// but it's good here for the sake of performance
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: "row",
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(drop(ref));

	return (
		<tr
			ref={ref}
			style={{ opacity: isDragging ? 0.5 : 1, cursor: "move" }}
			key={el.id}
		>
			<CheckBoxCell
				key={el.id}
				value={selected.includes(el.id)}
				onChange={() => handleSelect(el)}
			/>
			<td>{index || "Start Point"}</td>
			<NumericCell
				isDecimal={true}
				value={el.x}
				onChange={(val) => handleChange(el, "x", val)}
				className={"w-100"}
			/>
			<NumericCell
				isDecimal={true}
				value={el.y}
				onChange={(val) => handleChange(el, "y", val)}
				className={"w-100"}
			/>
			<NumericCell
				isDecimal={true}
				value={el.z}
				onChange={(val) => handleChange(el, "z", val)}
				className={"w-100"}
			/>
			<NumericCell
				isDecimal={true}
				value={el.waitTime}
				onChange={(val) => handleChange(el, "waitTime", val)}
				className={"w-100"}
			/>
		</tr>
	);
};

export function HumanPathDlg({ name, item, onClose }: Props) {
	const [selected, setSelected] = useState<number[]>([]);
	const [offsetTop, setOffsetTop] = useState<number>(0);

	const tableRef = useRef<HTMLTableElement>(null);

	const pss = useSelector((state: ApplicationState) => getCurrentPSS(state));

	const dispatch = useDispatch();

	const current = useMemo(() => {
		return pss?.humans.find((c) => c.id === item.id);
	}, [pss, item]);

	const data = useMemo(() => {
		return current?.path ?? [];
	}, [current]);
	
	useEffect(() => {
		setOffsetTop(getTopOffset(tableRef.current, 1));
	}, []);

	function handleChangeData(path: TPSSEGVPath[]) {
		dispatch(changePSSHuman(name, { ...item, path }));
	}

	function handleAdd() {
		handleChangeData([
			...data,
			{ id: getNextId(data), x: 0, y: 0, z: 0, waitTime: 1 },
		]);
	}

	function handleChange(el: TPSSEGVPath, field: string, val: any) {
		handleChangeData(
			data.map((d) => (d.id === el.id ? { ...el, [field]: val } : d))
		);
	}

	function handleDelete() {
		handleChangeData(data.filter((d) => !selected.includes(d.id)));
		setSelected([]);
	}

	function handleSelect(el: TPSSEGVPath) {
		if (selected.includes(el.id)) {
			setSelected(selected.filter((s) => s !== el.id));
		} else {
			setSelected([...selected, el.id]);
		}
	}

	function moveRow(dragIndex: number, hoverIndex: number) {
		const updatedData = [...data];
		const [removed] = updatedData.splice(dragIndex, 1);
		updatedData.splice(hoverIndex, 0, removed);
		handleChangeData(updatedData);
	}

	return (
		<DndProvider backend={HTML5Backend}>
			<CustomDlg
				title={"Operator Path Detail"}
				isMinimize={true}
				body={
					<div className="d-flex f-grow f-column">
						<div className="label-light bg-dark">
							<Button
								small
								icon="trash"
								text="Delete"
								disabled={!selected.length}
								intent="warning"
								onClick={handleDelete}
							/>
							<Button
								small
								icon="plus"
								text="Add Row"
								intent="primary"
								onClick={handleAdd}
							/>
						</div>
						<div className="hr" />
						<div className={"bg-dark p-5"}>
							<div className={"small-table-container"}>
								<table ref={tableRef} className="table bg-gray">
									<thead>
										<tr>
											<th rowSpan={2}></th>
											<th rowSpan={2}>Point No.</th>
											<th colSpan={3}>Point Position, m</th>
											<th rowSpan={2}>Wait Time at Each Point</th>
										</tr>
										<tr>
											<th style={{ top: offsetTop }}>X</th>
											<th style={{ top: offsetTop }}>Y</th>
											<th style={{ top: offsetTop }}>Z</th>
										</tr>
									</thead>
									<tbody>
										{data.map((el, index) => (
											<DraggableRow
												key={el.id}
												el={el}
												index={index}
												moveRow={moveRow}
												handleSelect={handleSelect}
												selected={selected}
												handleChange={handleChange}
											/>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				}
				onClose={onClose}
			/>
		</DndProvider>
	);
}
