import * as THREE from "three";
import { TPSSFactoryPath } from "../../../../../store/pss/types";

export function drawPathAsPlane(
  scene: THREE.Scene,
  points: THREE.Vector3[],
  pathWidth: number,
  pathColor: THREE.Color | string | number
) {
  const pathGroup = new THREE.Group();
  pathGroup.name = "FactoryPaths";

  if (points.length < 2) {
    console.warn("At least two points are required to draw a path.");
    return;
  }

  const material = new THREE.MeshPhongMaterial({
    color: pathColor,
    side: THREE.DoubleSide,
  });

  for (let i = 0; i < points.length - 1; i++) {
    const start = points[i];
    const end = points[i + 1];

    const direction = end.clone().sub(start).normalize();

    const perpendicular = new THREE.Vector3(0, 1, 0)
      .cross(direction)
      .normalize()
      .multiplyScalar(pathWidth / 2);

    const corners = [
      start.clone().add(perpendicular), // Top-left
      start.clone().sub(perpendicular), // Bottom-left
      end.clone().sub(perpendicular),   // Bottom-right
      end.clone().add(perpendicular),   // Top-right
    ];

    const geometry = new THREE.BufferGeometry();
    const vertices = [
      corners[0], corners[1], corners[3], // First triangle
      corners[3], corners[1], corners[2], // Second triangle
    ];

    const positions = new Float32Array(
      vertices.flatMap((v) => [v.x, v.y, v.z])
    );

    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

    // Create the mesh and add it to the group
    const mesh = new THREE.Mesh(geometry, material);
    pathGroup.add(mesh);
  }

  scene.add(pathGroup);
}

export function convertToVector3Array(paths: TPSSFactoryPath[]): THREE.Vector3[] {
  return paths.map((path) => new THREE.Vector3(path.x, path.y, path.z));
}
